import { atom } from "recoil"
import moment from "moment"
import { recoilPersist } from "recoil-persist"

export type IotDataProps = {
  device_id: string
  value: number
}

export type MapRefProps = {
  mapRef: React.MutableRefObject<L.Map | undefined>
}

export type WindDataProps = {
  device_id: string
  lat: number
  lon: number
  name: string
  source: string
  time: string
  wind_direct: number
  wind_speed: number
}

export type IotDeviceDataProps = {
  county: string
  device_id: number
  lat: number
  lon: number
  value: number
  time: string
  timestamp: number
}

export type IotDeviceProps = {
  desc: string
  device_id: string
  id: string
  lat: number
  lon: number
  display: boolean
  name: string
}

export type DeviceSelectorProps = {
  status: string
  device_id: string
  display: string
}

export type ProjectProps = {
  county: string
  devices: Array<DeviceProps>
  keywords: Array<string>
  projectId: string
  projectName: string
}

// export type ProjectDeviceProps = {
//   contact: string
//   contactPhone: string
//   deviceId: string
//   sex: string
// }

export type SelectedDeviceProps = {
  device_id: string
  status: string
}

export type countyInfoProps = {
  code: string
  lat: number
  lon: number
  name: string
  nameEng: string
}

export type DeviceProps = {
  deviceId: string
  contact: string
  gender: string
  status: string
  createDate: string
  latestDataTime: string
  contactPhone: string
}

export type DeviceBlockProps = {
  setOpenDialog: Function
  setSelectedDeviceId: Function
}

export type ProjectCellProps = {
  projectId?: string | null
  projectName: string
  endDate: string | null
  beginDate?: string
  contactPhone?: string | null
  county: string
  description?: string | null
  fleetContact?: string | null
  gender?: string | null
  keywords?: Array<string>
  status?: string | null
  ownerAccount?: string
}

export type ScaleProps = {
  color: string
  value: number
}

type absoluteColorScaleProps = {
  [index: string]: Array<ScaleProps>
}

type SensorBoardProps = {
  displayType: string
  loading: boolean
  devices: Array<string>
  switches: { [index: string]: boolean }
  status: { [index: string]: string }
}

type DateProps = {
  startDate: string
  endDate: string
}

type JsonlikeProps = { [index: string]: string }

type DatetimePickerProps = {
  start?: Date
  end?: Date
}

export type UserStateProps = {
  name?: string
  token: string
  tokenType: string
}

export type PolylineProps = {
  avg: number
  county: string
  lat_0: number
  lat_1: number
  lon_0: number
  lon_1: number
  road_idx: number
  color?: string
  ranking: number
}

export type MapMarkerProps = {
  center: [number, number]
  id: string
  value: number
  color: string
  timestamp: number
}

export type TextItemProps = {
  name: string
  queryName: string
  unit: string
}

export const selectedTextItemState = atom<TextItemProps>({
  key: "selectedTextItemState",
  default: {
    name: "PM2.5",
    queryName: "pm2_5_uart",
    unit: "μg",
  },
})

export const filterTextItems = atom<Array<TextItemProps>>({
  key: "filterTextItems",
  default: [],
})

export const apiBaseUrl = atom({
  key: "apiBaseUrl",
  default: "http://35.206.242.131:8802/",
})

export const sensorBoardState = atom<SensorBoardProps>({
  key: "sensorBoardState",
  default: {
    displayType: "即時",
    loading: false,
    devices: [],
    switches: {},
    status: {},
  },
})

export const gridSettingsState = atom<JsonlikeProps>({
  key: "gridSettingsState",
  default: {},
})

export const deviceDataStates = atom<{ [index: string]: Array<MapMarkerProps> }>({
  key: "deviceDataStates",
  default: {},
})

export const filterDateState = atom<DateProps>({
  key: "filterDateState",
  default: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
})

export const openFilterState = atom({
  key: "openFilterState",
  default: false,
})

export const datetimePickerState = atom<DatetimePickerProps>({
  key: "datetimePickerState",
  default: {},
})

export const progressDisplayTimeState = atom({
  key: "progressDisplayTimeState",
  default: 999999999999999,
})

export const motBufferState = atom<Array<{ [index: string]: string }>>({
  key: "motBufferState",
  default: [],
})

export const motJsonDataState = atom<Array<JsonlikeProps>>({
  key: "motJsonDataState",
  default: [],
})

const { persistAtom } = recoilPersist()
export const userAuthSate = atom<UserStateProps | null>({
  key: "userAuthSate",
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const countyMappingState = atom<Array<countyInfoProps>>({
  key: "countyMappingState",
  default: [],
})

export const openNewProjectState = atom({
  key: "openNewProjectState",
  default: false,
})

export const displayBarState = atom({
  key: "displayBarState",
  default: {
    status: "all",
    order: "descending",
    keyword: "",
    type: "grid",
  },
})

export const deviceListState = atom<Array<DeviceProps>>({
  key: "deviceListState",
  default: [],
})

export const selectedProjectState = atom<ProjectCellProps | null>({
  key: "selectedProjectState",
  default: null,
})

export const polylineState = atom<Array<PolylineProps>>({
  key: "polylineState",
  default: [],
})

export const weeklyChartState = atom<{ [index: string]: number | null }>({
  key: "weeklyChartState",
  default: {},
})

export const hourlyChartState = atom<{ [index: string]: number | null }>({
  key: "hourlyChartState",
  default: {},
})

export const colorScaleColorsState = atom<Array<string>>({
  key: "colorScaleColorsState",
  default: [],
})

export const colorScaleState = atom<Array<ScaleProps>>({
  key: "colorScaleState",
  default: [],
})

export const colorScaleBoundsState = atom({
  key: "colorScaleBoundsState",
  default: {
    max: 0,
    min: 0,
  },
})

export const filterProjectState = atom<Array<string>>({
  key: "filterProjectState",
  default: [],
})

export const filterCountyState = atom<Array<string>>({
  key: "filterCountyState",
  default: [],
})

export const deviceStatusState = atom({
  key: "deviceStatusState",
  default: "",
})

export const streamingState = atom({
  key: "streamingState",
  default: false,
})

export const colorScaleSwitchState = atom({
  key: "colorScaleSwitchState",
  default: "相對",
})

export const absoluteColorScaleState = atom<absoluteColorScaleProps>({
  key: "absoluteColorScaleState",
  default: {},
})

export const filterGroupButtonState = atom({
  key: "filterGroupButtonState",
  default: "即時",
})

export const projectDetailState = atom<Array<ProjectProps>>({
  key: "projectDetailState",
  default: [],
})

export const deviceSelectorState = atom({
  key: "deviceSelectorState",
  default: [],
})

export const selectedDeviceState = atom<{ [index: string]: SelectedDeviceProps }>({
  key: "selectedDeviceState",
  default: {},
})

export const allIotDeviceState = atom<Array<IotDeviceProps>>({
  key: "allIotDeviceState",
  default: [],
})

export const trackingIotDeviceState = atom<Array<IotDeviceProps>>({
  key: "trackingIotDeviceState",
  default: [],
})

export const trackingIotDeviceDataState = atom<{ [index: string]: Array<IotDeviceDataProps> }>({
  key: "trackingIotDeviceDataState",
  default: {},
})

export const latestTrackingIotDeviceState = atom<Array<IotDeviceProps>>({
  key: "latestTrackingIotDeviceState",
  default: [],
})

export const windDataSortByTimeState = atom<{ [index: string]: Array<WindDataProps> }>({
  key: "windDataSortByTimeState",
  default: {},
})

export const showLoadingScreenState = atom<boolean>({
  key: "showLoadingScreenState",
  default: false,
})

export const projectListState = atom<Array<ProjectCellProps>>({
  key: "projectListState",
  default: [],
})
