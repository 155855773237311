import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Box, FormControl, InputLabel, MenuItem, TextField } from "@mui/material"
import { useRecoilState, useRecoilValue } from "recoil"
import { filterTextItems, selectedTextItemState } from "@core/atoms"

export default function TextItemSelector() {
  const textItems = useRecoilValue(filterTextItems)
  const [selectedTextItem, setSelectedTextItem] = useRecoilState(selectedTextItemState)

  const handleChange = (event: SelectChangeEvent) => {
    let current = textItems.filter(item => item.name === event.target.value)
    setSelectedTextItem(current[0])
  }
  return (
    <Box sx={{ display: "flex" }} className="display-filter-item">
      <FormControl className="display-filter-text">
        <InputLabel>測項</InputLabel>
        <Select
          value={selectedTextItem.name ? selectedTextItem.name : ""}
          label="測項"
          onChange={handleChange}
        >
          {textItems.map(item => (
            <MenuItem value={item.name} key={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className="display-filter-unit"
        disabled
        label="單位"
        variant="outlined"
        value={selectedTextItem.unit}
      />
    </Box>
  )
}
