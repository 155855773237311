import React from "react"
import { Box, Button, TextField } from "@mui/material"
import { userAuthSate } from "@core/atoms"
import { loginAPI } from "@core/API"
import { useRecoilState } from "recoil"
import { useState } from "react"
import { useQuery } from "react-query"

export const Login = () => {
  const [, setUser] = useRecoilState(userAuthSate)
  const [account, setAccount] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<{ [index: string]: null | string }>({
    account: null,
    password: null,
  })

  const handleAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccount(event.target.value)
  }
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }
  const validationCheck = () => {
    const accountError = account === "" ? "帳號欄位為必填" : null
    const passwordError = password === "" ? "密碼欄位為必填" : null

    setError({
      account: accountError,
      password: passwordError,
    })
    return account !== "" && password !== ""
  }

  let bodyFormData: FormData
  const { refetch } = useQuery("login-api", {
    queryFn: () => loginAPI(bodyFormData),
    onSuccess: (res: any) => {
      setUser({
        name: account,
        token: res.data.access_token,
        tokenType: res.data.token_type,
      })
    },
    onError: (error: any) => {
      setError({
        account: "帳號或密碼錯誤",
        password: null,
      })
      console.log(error)
    },
    enabled: false,
  })

  const handleLogin = () => {
    if (!validationCheck()) return

    bodyFormData = new FormData()
    bodyFormData.append("username", account)
    bodyFormData.append("password", password)

    refetch()
  }
  return (
    <Box
      className="login-container"
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Box
        className="login-wrapper"
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "aliceblue",
          height: "40vh",
          width: "50vh",
          minHeight: "400px",
          minWidth: "500px",
          alignItems: "center",
          borderRadius: 2,
          justifyContent: "space-evenly",
        }}
      >
        <Box
          className="login-info"
          sx={{
            alignItems: "center",
            width: "60%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            className="login-account"
            label="帳號"
            variant="outlined"
            value={account}
            onChange={handleAccount}
            sx={{ width: "100%" }}
            error={error.account !== null}
            helperText={error.account !== null ? error.account : " "}
          />
          <TextField
            className="login-password"
            label="密碼"
            type="password"
            variant="outlined"
            value={password}
            onChange={handlePassword}
            sx={{ width: "100%", mt: 3 }}
            error={error.password !== null}
            helperText={error.password !== null ? error.password : " "}
          />
        </Box>
        <Button
          sx={{ width: "60%" }}
          className="login-button"
          variant="contained"
          onClick={handleLogin}
        >
          登入
        </Button>
      </Box>
    </Box>
  )
}
