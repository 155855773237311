import { useSearchParams } from "react-router-dom"
import { useState, useEffect, useRef } from "react"
import { SxProps } from "@mui/system"
import { useRecoilState, useResetRecoilState } from "recoil"
import {
  gridSettingsState,
  polylineState,
  weeklyChartState,
  hourlyChartState,
  colorScaleColorsState,
  selectedTextItemState,
  colorScaleSwitchState,
  showLoadingScreenState,
} from "@core/atoms"
import {
  noAuthGridSettingsAPI,
  noAuthPolylinesAPI,
  noAuthWeeklyChartAPI,
  noAuthHourlyChartAPI,
  fetchIotAvgAPI,
} from "@core/API"
import { csv2KeyValue, extractColorScales } from "@core/gridSettings"
import { Button } from "@mui/material"
import { useQuery } from "react-query"
import "@css/analysis.css"

type FilterProps = {
  sx?: SxProps
  className?: string
  setShowPanel: Function
  setShowCharts: Function
  setChangeView: Function
  setIotData: Function
}

export const NoAuth = (props: FilterProps) => {
  const [searchParams] = useSearchParams()
  const { setShowPanel, setShowCharts, setChangeView, setIotData } = props

  const [, setPolylines] = useRecoilState(polylineState)
  const [weeklyChart, setWeeklyChart] = useRecoilState(weeklyChartState)
  const [, setHourlyChart] = useRecoilState(hourlyChartState)
  const [, setGridSettings] = useRecoilState(gridSettingsState)
  const [, setColorScaleColors] = useRecoilState(colorScaleColorsState)
  const [, setSelectedTextItem] = useRecoilState(selectedTextItemState)
  const [, setColorScaleSwitch] = useRecoilState(colorScaleSwitchState)
  const [, setLoading] = useRecoilState(showLoadingScreenState)

  const resetPolyline = useResetRecoilState(polylineState)
  const resetWeeklyChartState = useResetRecoilState(weeklyChartState)
  const resetHourlyChartState = useResetRecoilState(hourlyChartState)

  const [polylineInterval, setPolylineInterval] = useState<number | false>(false)
  const [hourlyInterval, setHourlyInterval] = useState<number | false>(false)
  const [weeklyInterval, setWeeklyInterval] = useState<number | false>(false)
  const paramsRef = useRef({})

  const resetRecoilStates = () => {
    resetPolyline()
    resetWeeklyChartState()
    resetHourlyChartState()
  }
  useEffect(() => {
    resetRecoilStates()
  }, [])

  const redirectToLogin = (error: any) => {
    console.log("輸入的參數錯誤！")
  }

  const { isFetched } = useQuery("no-auth-grid-settings-api", {
    queryFn: () => noAuthGridSettingsAPI(),
    onSuccess: res => {
      const gridSettings = csv2KeyValue(res.data)
      setGridSettings(gridSettings)
      setColorScaleColors(extractColorScales(gridSettings))
    },
    onError: (error: any) => {
      console.log(error)
      redirectToLogin(error)
    },
  })

  useQuery("no-auth-polylines-api", {
    queryFn: () => noAuthPolylinesAPI(paramsRef.current),
    onSuccess: res => {
      refetchIot()
      if (res.data.isSuccess) {
        setPolylineInterval(false)
        setChangeView(true)
        if (res.data.result.length) {
          setPolylines(res.data.result)
          setShowPanel(false)
        } else alert("該時間區間沒有資料。")
        setLoading(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      setPolylineInterval(false)
      redirectToLogin(error)
      setLoading(false)
    },
    refetchInterval: polylineInterval,
    refetchIntervalInBackground: true,
    enabled: polylineInterval !== false && isFetched,
  })

  useQuery("no-auth-weekly-chart-api", {
    queryFn: () => noAuthWeeklyChartAPI(paramsRef.current),
    onSuccess: res => {
      if (res.data.isSuccess) {
        setWeeklyChart(res.data.result)
        setWeeklyInterval(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      setWeeklyInterval(false)
      redirectToLogin(error)
    },
    refetchInterval: weeklyInterval,
    refetchIntervalInBackground: true,
    enabled: weeklyInterval !== false,
  })

  useQuery("no-auth-hourly-chart-api", {
    queryFn: () => noAuthHourlyChartAPI(paramsRef.current),
    onSuccess: res => {
      if (res.data.isSuccess) {
        setHourlyChart(res.data.result)
        setHourlyInterval(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      setHourlyInterval(false)
      redirectToLogin(error)
    },
    refetchInterval: hourlyInterval,
    refetchIntervalInBackground: true,
    enabled: hourlyInterval !== false,
  })

  const { refetch: refetchIot } = useQuery("no-auth-iot-avg-api", {
    queryFn: () => fetchIotAvgAPI(null, paramsRef.current),
    onSuccess: res => {
      if (res.data.isSuccess) {
        setIotData(res.data.result)
      }
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  const areParamsSet = () => {
    paramsRef.current = {
      projectId: searchParams.get("projectId"),
      sensorId: searchParams.get("sensorId"),
      type: searchParams.get("type"),
      countThreshold: searchParams.get("countThreshold"),
      dates: searchParams.get("dates"),
      startHour: searchParams.get("startHour"),
      endHour: searchParams.get("endHour"),
      startMinute: searchParams.get("startMinute"),
      endMinute: searchParams.get("endMinute"),
    }

    const allParamsExist = Object.values(paramsRef.current).every(value => {
      return value !== null && value !== undefined
    })

    if (!allParamsExist) {
      console.log("Some parameters are missing or not set properly.")
      return false
    }
    return true
  }

  useEffect(() => {
    resetRecoilStates()

    if (!areParamsSet()) return

    setLoading(true)
    setPolylineInterval(3000)
    setHourlyInterval(3000)
    setWeeklyInterval(3000)
    setColorScaleSwitch("相對")
  }, [])

  return (
    <Button
      variant="outlined"
      onClick={() => setShowCharts(true)}
      className={`btn-toggler btn-weekanalysis ${
        Object.keys(weeklyChart).length === 0 ? "disabled" : ""
      }`}
      disabled={Object.keys(weeklyChart).length === 0}
    >
      週間分析
    </Button>
  )
}
