import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

type AlertDialogProps = {
  openDialog: boolean
  setOpenDialog: Function
  title: string
  content: string
  onConfirm: Function
}

export default function AlertDialog(props: AlertDialogProps) {
  const { openDialog, setOpenDialog, title, content, onConfirm } = props

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>取消</Button>
        <Button
          onClick={() => {
            handleClose()
            onConfirm()
          }}
          autoFocus
        >
          確定
        </Button>
      </DialogActions>
    </Dialog>
  )
}
