import { NavBar } from "@components/NavBar"
import { MapRef } from "@trace/MapRef"
import { Filter } from "@trace/Filter"
import BoardFrame from "@static/trace/BoardFrame"
import { Timeline } from "@trace/Timeline"
import Grid from "@mui/material/Grid"
import { useRecoilValue, useResetRecoilState } from "recoil"
import {
  openFilterState,
  deviceDataStates,
  filterProjectState,
  filterCountyState,
  deviceStatusState,
  filterDateState,
  sensorBoardState,
  motJsonDataState,
} from "@core/atoms"
import { LevelBar } from "@components/LevelBar"
import { useEffect, useRef, useState } from "react"
import { LoadIot } from "@trace/LoadIot"
import Loading from "@components/Loading"

export default function Trace() {
  const resetFilter = useResetRecoilState(openFilterState)
  const resetDeviceData = useResetRecoilState(deviceDataStates)
  const resetProjectStat = useResetRecoilState(filterProjectState)
  const resetCountyState = useResetRecoilState(filterCountyState)
  const resetStatus = useResetRecoilState(deviceStatusState)
  const resetDates = useResetRecoilState(filterDateState)
  const resetsensorBoard = useResetRecoilState(sensorBoardState)
  const resetMotJsonDataState = useResetRecoilState(motJsonDataState)

  const [showPanel, setShowPanel] = useState(true)

  const openFilter = useRecoilValue(openFilterState)
  const deviceDatas = useRecoilValue(deviceDataStates)

  const mapRef = useRef<L.Map>()

  const resetRecoilStates = () => {
    resetFilter()
    resetDeviceData()
    resetProjectStat()
    resetCountyState()
    resetStatus()
    resetDates()
    // resetsensorBoard()
    resetMotJsonDataState()
  }
  useEffect(() => {
    resetRecoilStates()
    return () => resetRecoilStates()
  }, [])

  return (
    <>
      <Loading />
      <div className={showPanel ? "" : "hide-panel"}>
        <NavBar />
        <MapRef mapRef={mapRef} />
        <Grid
          container
          sx={{
            position: "fixed",
            width: openFilter ? "100%" : "30%",
            // bgcolor: 'black'
          }}
          className={`main-container ${openFilter ? "show-search-container" : ""} `}
        >
          <Grid
            item
            xs={openFilter ? 3 : 11}
            sx={{
              bgcolor: "background.paper",
              padding: 2,
              borderRadius: "12px",
              fontWeight: "bold",
              ml: 2,
              mt: 2,
            }}
            className="left-panel"
          >
            <div className="panel-trigger-mobile" onClick={() => setShowPanel(prev => !prev)}>
              <div className="thumb"></div>
            </div>
            <BoardFrame />
          </Grid>
          <Grid
            item
            xs={openFilter ? 8 : 0}
            sx={{
              borderRadius: "15px",
              fontWeight: "bold",
              ml: 2,
              mt: 2,
            }}
            className="right-panel"
          >
            {openFilter ? <Filter open={openFilter} /> : null}
          </Grid>
        </Grid>
        {Object.keys(deviceDatas).length > 0 && (
          <>
            <LoadIot mapRef={mapRef} />
            <LevelBar className="level-bar" />
            <Timeline />
          </>
        )}
      </div>
    </>
  )
}
