import React, { useState, useEffect, useCallback, useRef } from "react"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from "react-router-dom"
import { PickersDay } from "@mui/x-date-pickers"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { SxProps } from "@mui/system"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/zh-tw"
import TuneIcon from "@mui/icons-material/Tune"
import { useRecoilState, useResetRecoilState } from "recoil"
import {
  gridSettingsState,
  polylineState,
  userAuthSate,
  ProjectCellProps,
  weeklyChartState,
  hourlyChartState,
  TextItemProps,
  colorScaleColorsState,
  selectedTextItemState,
  colorScaleSwitchState,
  showLoadingScreenState,
} from "@core/atoms"
import {
  fetchMyProjectsAPI,
  fetchGridSettingsAPI,
  fetchPolylinesAPI,
  fetchWeeklyChartAPI,
  fetchHourlyChartAPI,
  fetchIotAvgAPI,
} from "@core/API"
import { csv2KeyValue, extractAnalyseItems, extractColorScales } from "@core/gridSettings"
import moment from "moment"
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  Autocomplete,
  FormControlLabel,
  Switch,
} from "@mui/material"
import { useQuery } from "react-query"
import { v4 as uuidv4 } from "uuid"
import "@css/analysis.css"

type FilterProps = {
  sx?: SxProps
  className?: string
  setShowPanel: Function
  setShowCharts: Function
  setChangeView: Function
  setIotData: Function
}

type TimePickersProps = {
  label: string
  value: {
    start: Date
    end: Date
  }
  type: "start" | "end"
  setTime: Function
  className?: string
  minTime?: Date
}

type ErrorProps = {
  project: null | string
  item: null | string
  type: null | string
  time: null | string
  dates: null | string
  count: null | string
}

type DatePickersProps = {
  className?: string
  dates: Array<string>
  setDates: Function
  error: ErrorProps
  setError: Function
  rangeSelect: Boolean
}

const TimePickers = (props: TimePickersProps) => {
  return (
    <Box className={props.className} sx={{ width: "47.5%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} className="filter-time-range">
        <TimePicker
          openTo="hours"
          minutesStep={10}
          views={["hours", "minutes"]}
          value={props.value[props.type]}
          label={props.label}
          minTime={props.minTime}
          onChange={(v: Date | null) => props.setTime({ ...props.value, [props.type]: v })}
          renderInput={params => <TextField {...params} onKeyDown={e => e.preventDefault()} />}
        />
      </LocalizationProvider>
    </Box>
  )
}

const DatePickers = (props: DatePickersProps) => {
  const [value, setValue] = useState<string>("")
  const [ranges, setRanges] = useState<Array<string>>([])
  const [rangeStart, setRangeStart] = useState<string>("")

  const minYear = 2020
  const maxYear = dayjs().year()

  const addDatesToRange = (date: string) => {
    let dates = []
    var currentDate = rangeStart <= date ? moment(rangeStart) : moment(date)
    var stopDate = rangeStart <= date ? moment(date) : moment(rangeStart)
    while (currentDate <= stopDate) {
      dates.push(moment(currentDate).format("YYYY-MM-DD"))
      currentDate = moment(currentDate).add(1, "days")
    }

    setRanges(dates)
  }

  const addDates = (date: string) => {
    let dates = []
    var currentDate = rangeStart <= date ? moment(rangeStart) : moment(date)
    var stopDate = rangeStart <= date ? moment(date) : moment(rangeStart)
    while (currentDate <= stopDate) {
      const formatCurrent = moment(currentDate).format("YYYY-MM-DD")
      if (!props.dates.includes(formatCurrent)) dates.push(formatCurrent)
      currentDate = moment(currentDate).add(1, "days")
    }

    props.setDates([...props.dates, ...dates].sort())
  }

  const handleMouseOver = useCallback(
    (event: any, date: string) => {
      if (rangeStart !== "") addDatesToRange(date)
    },
    [props.rangeSelect, rangeStart]
  )

  const handleRangeSelection = (date: string) => {
    if (rangeStart === "") setRangeStart(date)
    else {
      if (rangeStart !== date) addDates(date)
      setRangeStart("")
      setRanges([])
    }
  }

  const handleSingleSelection = (date: string) => {
    if (props.dates.includes(date)) props.setDates(props.dates.filter(d => d !== date))
    else props.setDates([...props.dates, date])
  }

  return (
    <Box className={props.className} sx={{ width: "100%", mt: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-tw">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={value}
          minDate={dayjs().year(minYear).startOf("year")}
          maxDate={dayjs().year(maxYear).endOf("year")}
          onChange={(newValue: dayjs.Dayjs | null) => {
            setValue(uuidv4())
            const date = (newValue as Dayjs).format("YYYY-MM-DD")
            if (props.rangeSelect) handleRangeSelection(date)
            else handleSingleSelection(date)

            props.setError({ ...props.error, dates: null })
          }}
          renderInput={params => <TextField {...params} />}
          renderDay={(day, selectedDays, pickersDayProps) => {
            let selectedMuiClass = ""

            const date = (day as Dayjs).format("YYYY-MM-DD")
            if (ranges.includes(date) || date == rangeStart) {
              selectedMuiClass = "in-range"
            } else if (props.dates.includes(date)) {
              selectedMuiClass = "Mui-selected"
            }

            return (
              <PickersDay
                className={selectedMuiClass}
                {...pickersDayProps}
                onMouseOver={event => handleMouseOver(event, date)}
              />
            )
          }}
        />
      </LocalizationProvider>
    </Box>
  )
}

export const Filter = (props: FilterProps) => {
  const { sx, className, setShowPanel, setShowCharts, setChangeView, setIotData } = props
  const navigate = useNavigate()

  const [user, setUser] = useRecoilState(userAuthSate)
  const [, setPolylines] = useRecoilState(polylineState)
  const [weeklyChart, setWeeklyChart] = useRecoilState(weeklyChartState)
  const [, setHourlyChart] = useRecoilState(hourlyChartState)
  const [, setGridSettings] = useRecoilState(gridSettingsState)
  const [, setColorScaleColors] = useRecoilState(colorScaleColorsState)
  const [, setSelectedTextItem] = useRecoilState(selectedTextItemState)
  const [, setColorScaleSwitch] = useRecoilState(colorScaleSwitchState)
  const [, setLoading] = useRecoilState(showLoadingScreenState)

  const resetPolyline = useResetRecoilState(polylineState)
  const resetWeeklyChartState = useResetRecoilState(weeklyChartState)
  const resetHourlyChartState = useResetRecoilState(hourlyChartState)

  const [projects, setProjects] = useState<Array<ProjectCellProps>>([])
  const [analyseItems, setAnalyseItems] = useState<Array<TextItemProps>>([])
  const [selectedItem, setSelectedItem] = useState<TextItemProps>()
  const [polylineInterval, setPolylineInterval] = useState<number | false>(false)
  const [hourlyInterval, setHourlyInterval] = useState<number | false>(false)
  const [weeklyInterval, setWeeklyInterval] = useState<number | false>(false)
  const [timePicker, setTimePicker] = useState({
    start: moment().startOf("day").toDate(),
    end: moment().startOf("hour").toDate(),
  })
  const [selectedOps, setSelectedOps] = useState({
    project: "",
    item: "",
    type: "",
    count: 0,
  })
  const [dates, setDates] = useState<Array<string>>([])
  const [error, setError] = useState<ErrorProps>({
    project: null,
    item: null,
    type: null,
    time: null,
    dates: null,
    count: null,
  })
  const [rangeSelect, setRangeSelect] = useState(false)
  const paramsRef = useRef({})
  const analyseSubjects = [
    {
      name: "平均值",
      queryName: "avg",
    },
    {
      name: "平均值扣除背景值",
      queryName: "avg_remove_bg",
    },
  ]
  const countThresholds = [
    {
      name: "所有資料",
      value: 1,
    },
    {
      name: "5筆以上",
      value: 5,
    },
    {
      name: "10筆以上",
      value: 10,
    },
    {
      name: "15筆以上",
      value: 15,
    },
    {
      name: "20筆以上",
      value: 20,
    },
  ]

  const resetRecoilStates = () => {
    resetPolyline()
    resetWeeklyChartState()
    resetHourlyChartState()
  }
  useEffect(() => {
    resetRecoilStates()
  }, [])

  const redirectToLogin = (error: any) => {
    if (error.response.statusText === "Unauthorized") {
      setUser(null)
      navigate("/")
    }
  }

  const { refetch: refetchProjects } = useQuery("fetch-my-projects-api", {
    queryFn: () => fetchMyProjectsAPI(user),
    onSuccess: (res: any) => {
      setProjects(res.data.reverse())
    },
    onError: (error: any) => {
      redirectToLogin(error)
    },
    enabled: false,
  })

  const { refetch: refetchGridSettings } = useQuery("fetch-grid-settings-api", {
    queryFn: () => fetchGridSettingsAPI(user),
    onSuccess: res => {
      const gridSettings = csv2KeyValue(res.data)
      setGridSettings(gridSettings)
      setAnalyseItems(extractAnalyseItems(gridSettings))
      setColorScaleColors(extractColorScales(gridSettings))
    },
    onError: (error: any) => {
      console.log(error)
      redirectToLogin(error)
    },
    enabled: false,
    // cacheTime: 1800000,
    // staleTime: 1800000,
  })

  useEffect(() => {
    refetchProjects()
    refetchGridSettings()
  }, [])

  useQuery("fetch-polylines-api", {
    queryFn: () => fetchPolylinesAPI(user, paramsRef.current),
    onSuccess: res => {
      paramsRef.current = {
        projectId: selectedOps.project,
        sensorId: "pm2_5",
        type: selectedOps.type,
        countThreshold: selectedOps.count,
        dates: dates.toString(),
        startHour: moment(timePicker.start).format("HH"),
        endHour: moment(timePicker.end).format("HH"),
        startMinute: moment(timePicker.start).format("mm"),
        endMinute: moment(timePicker.end).format("mm"),
      }
      refetchIot()
      if (res.data.isSuccess) {
        setPolylineInterval(false)
        setChangeView(true)
        if (res.data.result.length) {
          setPolylines(res.data.result)
          setShowPanel(false)
        } else alert("該時間區間沒有資料。")
        setLoading(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      setPolylineInterval(false)
      redirectToLogin(error)
      setLoading(false)
    },
    refetchInterval: polylineInterval,
    refetchIntervalInBackground: true,
    enabled: polylineInterval !== false,
  })

  useQuery("fetch-weekly-chart-api", {
    queryFn: () => fetchWeeklyChartAPI(user, paramsRef.current),
    onSuccess: res => {
      if (res.data.isSuccess) {
        setWeeklyChart(res.data.result)
        setWeeklyInterval(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      setWeeklyInterval(false)
      redirectToLogin(error)
    },
    refetchInterval: weeklyInterval,
    refetchIntervalInBackground: true,
    enabled: weeklyInterval !== false,
  })

  useQuery("fetch-hourly-chart-api", {
    queryFn: () => fetchHourlyChartAPI(user, paramsRef.current),
    onSuccess: res => {
      if (res.data.isSuccess) {
        setHourlyChart(res.data.result)
        setHourlyInterval(false)
      }
    },
    onError: (error: any) => {
      console.log(error)
      setHourlyInterval(false)
      redirectToLogin(error)
    },
    refetchInterval: hourlyInterval,
    refetchIntervalInBackground: true,
    enabled: hourlyInterval !== false,
  })

  const { refetch: refetchIot } = useQuery("fetch-iot-avg-api", {
    queryFn: () => fetchIotAvgAPI(user, paramsRef.current),
    onSuccess: res => {
      if (res.data.isSuccess) {
        setIotData(res.data.result)
      }
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeSelect(event.target.checked)
  }

  const validationCheck = () => {
    const projectError = selectedOps.project === "" ? "專案為必選" : null
    const itemError = selectedOps.item === "" ? "測項為必選" : null
    const typeError = selectedOps.type === "" ? "類型為必選" : null
    const countError = selectedOps.count === 0 ? "資料筆數為必選" : null
    const datesError = dates.toString() === "" ? "至少選擇一個日期" : null
    const startTime = moment(timePicker.start).format("HH:mm")
    const endTime = moment(timePicker.end).format("HH:mm")
    const timeError = startTime > endTime ? "開始時間需早於結束時間" : null

    setError({
      project: projectError,
      item: itemError,
      type: typeError,
      time: timeError,
      dates: datesError,
      count: countError,
    })

    return (
      projectError === null &&
      itemError === null &&
      typeError === null &&
      datesError === null &&
      timeError === null &&
      countError === null
    )
  }

  const handleSubmit = () => {
    if (!validationCheck()) return

    resetRecoilStates()

    paramsRef.current = {
      projectId: selectedOps.project,
      sensorId: selectedOps.item,
      type: selectedOps.type,
      countThreshold: selectedOps.count,
      dates: dates.toString(),
      startHour: moment(timePicker.start).format("HH"),
      endHour: moment(timePicker.end).format("HH"),
      startMinute: moment(timePicker.start).format("mm"),
      endMinute: moment(timePicker.end).format("mm"),
    }

    setLoading(true)
    setPolylineInterval(3000)
    setHourlyInterval(3000)
    setWeeklyInterval(3000)
    setSelectedTextItem(selectedItem as TextItemProps)
    setColorScaleSwitch("相對")
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setShowCharts(true)}
        className={`btn-toggler btn-weekanalysis ${
          Object.keys(weeklyChart).length === 0 ? "disabled" : ""
        }`}
        disabled={Object.keys(weeklyChart).length === 0}
      >
        週間分析
      </Button>
      <Box sx={sx} className={className}>
        <div className="panel-trigger-mobile" onClick={() => setShowPanel((prev: any) => !prev)}>
          <div className="thumb"></div>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="top-banner"
        >
          <Typography variant="h5" component="span" className="func-title">
            空間分析
          </Typography>
        </Box>
        <Box
          className="analysis-filter-container"
          sx={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Autocomplete
            className="analysis-filter-cell"
            options={projects}
            isOptionEqualToValue={(option: ProjectCellProps, value: ProjectCellProps) =>
              option.projectId === value.projectId
            }
            getOptionLabel={(option: ProjectCellProps) => option.projectName}
            sx={{ width: "90%", mt: 3 }}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
              const v = value === null ? "" : (value.projectId as string)
              setSelectedOps({ ...selectedOps, project: v })
              setError({ ...error, project: null })
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.projectId}>
                  {option.projectName}
                </li>
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="選擇專案"
                error={error.project !== null}
                helperText={error.project !== null ? error.project : " "}
              />
            )}
          />
          <Autocomplete
            className="analysis-filter-cell"
            options={analyseItems}
            isOptionEqualToValue={(option: TextItemProps, value: TextItemProps) =>
              option.name === value.name
            }
            getOptionLabel={(option: TextItemProps) => option.name}
            sx={{ width: "90%", mt: 2 }}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
              const v = value === null ? "" : (value.queryName as string)
              setSelectedOps({ ...selectedOps, item: v })
              setSelectedItem(value)
              setError({ ...error, item: null })
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="選擇測項"
                error={error.item !== null}
                helperText={error.item !== null ? error.item : " "}
              />
            )}
          />
          <Autocomplete
            className="analysis-filter-cell"
            options={analyseSubjects}
            isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
            getOptionLabel={(option: any) => option.name}
            sx={{ width: "90%", mt: 2 }}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
              const v = value === null ? "" : (value.queryName as string)
              setSelectedOps({ ...selectedOps, type: v })
              setError({ ...error, type: null })
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="分析類型"
                error={error.type !== null}
                helperText={error.type !== null ? error.type : " "}
              />
            )}
          />
          <Autocomplete
            className="analysis-filter-cell"
            options={countThresholds}
            isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
            getOptionLabel={(option: any) => option.name}
            sx={{ width: "90%", mt: 2 }}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
              const v = value === null ? 0 : value.value
              setSelectedOps({ ...selectedOps, count: v })
              setError({ ...error, count: null })
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="資料筆數篩選"
                error={error.count !== null}
                helperText={error.count !== null ? error.count : " "}
              />
            )}
          />
          <Box
            className="analysis-filter-cell"
            sx={{ width: "90%", mt: 2, display: "flex", justifyContent: "space-between" }}
          >
            <FormControl>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <TimePickers
                  className="time-picker"
                  label="開始時間"
                  value={timePicker}
                  type="start"
                  setTime={setTimePicker}
                />
                <TimePickers
                  className="time-picker"
                  label="結束時間"
                  value={timePicker}
                  minTime={timePicker.start}
                  type="end"
                  setTime={setTimePicker}
                />
              </Box>
              <FormHelperText error>{error.time !== null ? error.time : " "}</FormHelperText>
            </FormControl>
          </Box>
          <Box className="analysis-filter-cell" sx={{ width: "90%" }}>
            <FormControl sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography
                  className="date-picker-title"
                  variant="h6"
                  component="span"
                  sx={{ color: "gray", fontSize: 15 }}
                >
                  請選取擷取日期(可複選)
                </Typography>
                <FormControlLabel
                  control={
                    <Switch checked={rangeSelect} onChange={handleRangeChange} color="warning" />
                  }
                  label="範圍選取"
                />
              </Box>
              <DatePickers
                dates={dates}
                setDates={setDates}
                error={error}
                setError={setError}
                rangeSelect={rangeSelect}
              />
              <FormHelperText error>{error.dates}</FormHelperText>
            </FormControl>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleSubmit}
          className="analysis-filter-submit"
          disabled={polylineInterval !== false}
          sx={{ width: "90%", mb: 3 }}
        >
          {polylineInterval !== false ? "載入中..." : "加入"}
        </Button>
      </Box>
    </>
  )
}
