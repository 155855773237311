import { useEffect, useState, useRef } from "react"
import { Box, Typography, Grid, Button, Divider, CircularProgress } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { SxProps } from "@mui/system"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
  sensorBoardState,
  gridSettingsState,
  deviceDataStates,
  motJsonDataState,
  colorScaleColorsState,
  colorScaleBoundsState,
  filterTextItems,
  selectedTextItemState,
  absoluteColorScaleState,
  allIotDeviceState,
  IotDeviceProps,
} from "@core/atoms"
import {
  noAuthGridSettingsAPI,
  noAuthMotDataAPI,
  fetchAbsoluteColorScaleAPI,
  fetchIotDevicesAPI,
} from "@core/API"
import { filterMotDataByTextItemAndTime } from "@core/selectors"
import { useQuery } from "react-query"
import { csv2KeyValue, extractColorScales, extractTextItems } from "@core/gridSettings"
import DatetimePicker from "@sensorBoard/DatetimePicker"
import TextItemSelector from "@sensorBoard/TextItemSelector"
import DeviceCell from "@sensorBoard/DeviceCell"

type SensorBoardProps = {
  sx?: SxProps
}

export default function BoardFrame(props: SensorBoardProps) {
  const [searchParams] = useSearchParams()

  const [filteredMotData, max, min] = useRecoilValue(filterMotDataByTextItemAndTime)
  const selectedTextItem = useRecoilValue(selectedTextItemState)

  const [sensorBoard, setSensorBoard] = useRecoilState(sensorBoardState)
  const [deviceDatas, setDeviceData] = useRecoilState(deviceDataStates)
  const [, setGridSettings] = useRecoilState(gridSettingsState)
  const [, setTextItems] = useRecoilState(filterTextItems)
  const [, setColorScaleColors] = useRecoilState(colorScaleColorsState)
  const [motJsonData, setMotJsonData] = useRecoilState(motJsonDataState)
  const [, setScaleBounds] = useRecoilState(colorScaleBoundsState)
  const [, setAbsoluteColorScale] = useRecoilState(absoluteColorScaleState)
  const [, setIotDevice] = useRecoilState(allIotDeviceState)

  const resetdeviceDataState = useResetRecoilState(deviceDataStates)

  let dataParams = {}
  let showAlert = false

  const csv2obj = (csv: any) => {
    let lines = csv.split("\n")
    let result = []
    let headers = lines[0].split(",")

    // 最後一行會出現undefined所以-1
    for (let i = 1; i < lines.length - 1; i++) {
      let obj: { [index: string]: string } = {}
      let currentline = lines[i].split(",")
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }
      result.push(obj)
    }
    return result
  }

  const loadMotData = (startTime: string, endTime: string) => {
    if (sensorBoard.devices.length === 0) return
    setSensorBoard({
      ...sensorBoard,
      loading: true,
    })
    resetdeviceDataState()
    dataParams = {
      start_time: startTime,
      end_time: endTime,
      devices: sensorBoard.devices.toString(),
    }
    fetchMotData()
  }

  const redirectToLogin = (error: any) => {
    console.log(error)
  }

  const checkDataLength = () => {
    let dataLength = 0
    Object.values(filteredMotData).forEach(value => {
      dataLength += value.length
    })
    if (dataLength === 0 && Object.keys(sensorBoard.switches).length)
      alert("沒有符合該選擇條件的資料...")
  }

  const { refetch: refetchGridSettings } = useQuery("no-auth-grid-settings-api", {
    queryFn: () => noAuthGridSettingsAPI(),
    onSuccess: res => {
      const gridSettings = csv2KeyValue(res.data)
      setGridSettings(gridSettings)
      setTextItems(extractTextItems(gridSettings))
      setColorScaleColors(extractColorScales(gridSettings))
    },
    onError: (error: any) => {
      redirectToLogin(error)
    },
    enabled: false,
  })

  const { refetch: fetchMotData } = useQuery("no-auth-mot-data-api", {
    queryFn: () => noAuthMotDataAPI(dataParams),
    onSuccess: (res: any) => {
      let datas: any = csv2obj(res.data)
      setMotJsonData(datas)
    },
    onError: (error: any) => {
      redirectToLogin(error)
      setSensorBoard({
        ...sensorBoard,
        loading: false,
      })
    },
    enabled: false,
  })

  useQuery("fetch-absolute-color-scale-api", {
    queryFn: () => fetchAbsoluteColorScaleAPI(),
    onSuccess: res => {
      setAbsoluteColorScale(res.data)
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  const { refetch: fetchIotDevice } = useQuery("fetch-iot-devices-api", {
    queryFn: () => fetchIotDevicesAPI(),
    onSuccess: res => {
      setIotDevice(res.data.data.filter((d: IotDeviceProps) => d.display))
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
    cacheTime: 1800000,
    staleTime: 1800000,
  })

  useEffect(() => {
    setScaleBounds({
      max: max,
      min: min,
    })
  }, [max, min])

  useEffect(() => {
    setSensorBoard({
      ...sensorBoard,
      loading: false,
    })
  }, [motJsonData])

  useEffect(() => {
    let startTime = searchParams.get("date") + " 00:00"
    let endTime = searchParams.get("date") + " 23:59"

    if (sensorBoard.devices.length) {
      loadMotData(startTime, endTime)
      fetchIotDevice()
    }
  }, [sensorBoard.devices])

  useEffect(() => {
    setDeviceData(filteredMotData)
    showAlert = true
  }, [selectedTextItem])

  useEffect(() => {
    setDeviceData(filteredMotData)
    if (showAlert) {
      checkDataLength()
      showAlert = false
    }
  }, [filteredMotData])

  const areParamsSet = () => {
    const params = {
      devices: searchParams.get("devices"),
      date: searchParams.get("date"),
    }

    const allParamsExist = Object.values(params).every(value => {
      return value !== null && value !== undefined
    })

    if (!allParamsExist) {
      console.log("Some parameters are missing or not set properly.")
      return false
    }
    return true
  }

  useEffect(() => {
    if (!areParamsSet()) return

    refetchGridSettings()

    if (searchParams.get("devices") === undefined || searchParams.get("date") === undefined) {
      console.log("Some parameters are missing or not set properly.")
      return
    }

    const devices = searchParams.get("devices")?.split(",") as string[]
    let switches: { [index: string]: boolean } = {}
    let status: { [index: string]: string } = {}
    devices.forEach(device => {
      switches[device] = true
      status[device] = "deactive"
    })

    setSensorBoard({
      displayType: "日期",
      loading: false,
      devices: devices,
      switches: switches,
      status: status,
    })
  }, [])

  const message = (msg: string, color: string) => (
    <Typography
      variant="h6"
      component="div"
      sx={{ flex: 1, color: color, textAlign: "center", mt: 1 }}
      className="message-display"
    >
      {msg}
    </Typography>
  )

  const devicePanel = () => (
    <>
      <Grid container className="col-title">
        <Grid item xs={3}>
          <Typography variant="h6" component="span">
            顯示
          </Typography>
        </Grid>
        <Grid item xs={sensorBoard.displayType === "即時" ? 7 : 9}>
          <Typography variant="h6" component="span">
            感測器編號
          </Typography>
        </Grid>
        {sensorBoard.displayType === "即時" && (
          <Grid item xs={2}>
            <Typography variant="h6" component="span">
              狀態
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box className="device-panel">
        {sensorBoard.devices.map((device: string) => (
          <DeviceCell
            device_id={device}
            key={device}
            switch={sensorBoard.switches[device]}
            status={sensorBoard.status[device]}
            displayType={sensorBoard.displayType}
          />
        ))}
      </Box>
      <Box className="display-filter-container">
        <TextItemSelector />
        {sensorBoard.displayType === "日期" && <DatetimePicker />}
      </Box>
    </>
  )

  return (
    <Box className="sensor-board">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="top-banner"
      >
        <Typography variant="h5" component="span" className="func-title">
          MOT數據監測
        </Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} className="divider" />
      <Box className="sensor-controller">
        {sensorBoard.devices.length > 0 ? (
          sensorBoard.loading ? (
            <CircularProgress sx={{ alignSelf: "center", mt: 8 }} />
          ) : (
            <>
              {Object.keys(deviceDatas).length > 0
                ? devicePanel()
                : message("該時間範圍內沒有資料", "red")}
            </>
          )
        ) : (
          message("選擇你要監測的感測器", "black")
        )}
      </Box>
    </Box>
  )
}
