import { useState, useEffect } from "react"
import { Box, Typography, Button, TextField, Radio, Autocomplete, Checkbox } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
  userAuthSate,
  countyMappingState,
  openNewProjectState,
  ProjectCellProps,
  selectedProjectState,
  projectListState,
} from "@core/atoms"
import { postMyProjectsAPI } from "@core/API"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import "@css/projects.css"

const newProjectInfoState = atom<ProjectCellProps>({
  key: "newProjectInfoState",
  default: {
    projectName: "",
    beginDate: moment().format("YYYY-MM-DD"),
    endDate: null,
    county: "",
    fleetContact: "",
    gender: "",
    contactPhone: "",
    description: "",
    keywords: [],
  },
})

const DatePickers = (props: any) => {
  const [info, setInfo] = useRecoilState(newProjectInfoState)
  const [value, setValue] = useState<Date | null>(new Date())

  const handleChange = (newValue: Date | null) => {
    setValue(newValue)
    setInfo({ ...info, [props.type]: moment(newValue).format("YYYY-MM-DD") })
  }
  return (
    <Box className={props.className} sx={props.sx}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          // label={props.label}
          inputFormat="yyyy-MM-dd"
          value={value}
          onChange={handleChange}
          disabled={props.disabled}
          renderInput={params => (
            <TextField
              {...params}
              error={props.error}
              helperText={props.error ? "開始時間為必選" : " "}
              onKeyDown={e => e.preventDefault()}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  )
}

export const NewProject = () => {
  const navigate = useNavigate()
  const countyMapping = useRecoilValue(countyMappingState)

  const [user, setUser] = useRecoilState(userAuthSate)
  const [info, setInfo] = useRecoilState(newProjectInfoState)
  const [, setOpenNewProject] = useRecoilState(openNewProjectState)
  const [, setSelectedProject] = useRecoilState(selectedProjectState)
  const [, setProjects] = useRecoilState(projectListState)

  const resetInfo = useResetRecoilState(newProjectInfoState)
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState({
    projectName: false,
    projectCounty: false,
    beginDate: false,
  })

  const handleCheck = () => {
    setChecked(!checked)
  }
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    setInfo({ ...info, [type]: e.target.value })
    setError({ ...error, [type]: false })
  }
  const handleCounty = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setError({ ...error, projectCounty: false })
    if (value === null) setInfo({ ...info, county: "" })
    else setInfo({ ...info, county: value.code })
  }
  const handleCancel = () => {
    setOpenNewProject(false)
  }
  const isValidDateFormat = (dateString: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/
    return regex.test(dateString)
  }
  const validationCheck = () => {
    setError({
      projectName: info.projectName.trim() === "",
      projectCounty: info.county.trim() === "",
      beginDate: !isValidDateFormat(info.beginDate ?? ""),
    })

    return (
      info.projectName.trim() !== "" &&
      info.county.trim() !== "" &&
      isValidDateFormat(info.beginDate ?? "")
    )
  }

  let bodyFormData: FormData
  const { refetch: postMyProject } = useQuery("post-my-project-api", {
    queryFn: () => postMyProjectsAPI(user, bodyFormData),
    onSuccess: (res: any) => {
      const newProject = { projectId: res.data.projectId, ...info }
      setProjects((prev: Array<ProjectCellProps>) => [newProject, ...prev])
      alert("新增成功！")
      setOpenNewProject(false)
      setSelectedProject(newProject)
    },
    onError: (error: any) => {
      if (error.response.statusText === "Unauthorized") {
        setUser(null)
        navigate("/")
      }
      setOpenNewProject(false)
    },
    enabled: false,
  })

  const handleSubmit = () => {
    if (!validationCheck()) return

    bodyFormData = new FormData()
    for (const [key, value] of Object.entries(info)) {
      if (value !== null && value?.toString().trim() !== "") {
        if (key === "endDate") {
          if (checked) bodyFormData.append(key, value?.toString().trim() as string)
        } else bodyFormData.append(key, value?.toString().trim() as string)
      }
    }
    postMyProject()
  }

  useEffect(() => {
    resetInfo()
  }, [])
  return (
    <Box
      className="new-project-container"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box className="new-project-form" sx={{ width: "80%", height: "75%", mt: "10%" }}>
        <Box className="project-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="project-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%" }}
          >
            專案名稱*
          </Typography>
          <TextField
            variant="outlined"
            className="project-form-content"
            sx={{ width: "80%" }}
            value={info.projectName}
            error={error.projectName}
            helperText={error.projectName ? "專案名稱不可為空白" : " "}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, "projectName")
            }
          />
        </Box>
        <Box className="project-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="project-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%" }}
          >
            指定日期
          </Typography>
          <Box className="project-form-content" sx={{ width: "80%", display: "flex" }}>
            <DatePickers
              className="project-form-datepicker"
              // label="開始日期"
              type="beginDate"
              disabled={false}
              error={error.beginDate}
              sx={{ width: "35%" }}
            />
            <Typography
              className="form-checkbox-wrapper"
              variant="h6"
              component="span"
              sx={{ width: "20%", textAlign: "center", display: "block" }}
            >
              <Checkbox
                className="project-form-checkbox"
                checked={checked}
                onChange={handleCheck}
              />
              到
            </Typography>
            <DatePickers
              className="project-form-datepicker"
              // label="結束日期"
              type="endDate"
              disabled={!checked}
              error={false}
              sx={{ width: "35%" }}
            />
          </Box>
        </Box>
        <Box className="project-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="project-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%" }}
          >
            縣市*
          </Typography>
          <Box className="project-form-content" sx={{ width: "80%", display: "flex" }}>
            <Autocomplete
              className="project-form-counties"
              disablePortal
              isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
              getOptionLabel={option => option.name}
              options={countyMapping}
              sx={{ width: "35%" }}
              onChange={handleCounty}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="縣市"
                  error={error.projectCounty}
                  helperText={error.projectCounty ? "專案縣市不可為空白" : " "}
                />
              )}
            />
          </Box>
        </Box>
        <Box className="project-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="project-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%" }}
          >
            車隊聯絡人
          </Typography>
          <Box className="project-form-content" sx={{ width: "80%", display: "flex" }}>
            <TextField
              className="project-form-name"
              variant="outlined"
              placeholder="姓名"
              sx={{ width: "35%" }}
              value={info.fleetContact}
              helperText=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, "fleetContact")
              }
            />
            <Box className="project-gender-container" sx={{ display: "flex", width: "30%" }}>
              <Box
                className="project-gender-wrapper"
                sx={{ display: "flex", width: "50%", alignItems: "center" }}
              >
                <Radio
                  className="project-gender-radio"
                  checked={info.gender === "female"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextChange(e, "gender")
                  }
                  value="female"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography
                  className="project-gender-text"
                  variant="h6"
                  component="span"
                  sx={{ width: "50%" }}
                >
                  女士
                </Typography>
              </Box>
              <Box
                className="project-gender-wrapper"
                sx={{ display: "flex", width: "50%", alignItems: "center" }}
              >
                <Radio
                  className="project-gender-radio"
                  checked={info.gender === "male"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextChange(e, "gender")
                  }
                  value="male"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography
                  className="project-gender-text"
                  variant="h6"
                  component="span"
                  sx={{ width: "50%" }}
                >
                  先生
                </Typography>
              </Box>
            </Box>
            <TextField
              className="project-form-phone"
              variant="outlined"
              placeholder="聯絡電話"
              sx={{ width: "35%" }}
              value={info.contactPhone}
              helperText=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, "contactPhone")
              }
            />
          </Box>
        </Box>
        <Box
          className="project-form-item"
          sx={{ width: "100%", display: "flex", alignItems: "top" }}
        >
          <Typography
            className="project-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%" }}
          >
            專案敘述
          </Typography>
          <TextField
            variant="outlined"
            className="project-form-content"
            sx={{ width: "80%" }}
            multiline
            rows={2}
            value={info.description}
            helperText=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, "description")
            }
          />
        </Box>
      </Box>
      <Box
        className="project-form-options"
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button className="project-form-cancel" variant="outlined" onClick={handleCancel}>
          取消
        </Button>
        <Button className="project-form-submit" variant="contained" onClick={handleSubmit}>
          送出
        </Button>
      </Box>
    </Box>
  )
}
