import { useEffect, useRef } from "react"
import { latLngBounds } from "leaflet"
import { Box } from "@mui/material"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"

type MapProps = {
  className: string
  center: L.LatLngExpression
  polylines: Array<PolylineProps>
}

type PolylineProps = {
  count: number
  county: string
  lat_0: number
  lat_1: number
  lon_0: number
  lon_1: number
  road_idx: number
  color?: string
}

// function ChangeView() {
//   const map = useMap()
//   const polylines = useRecoilValue(polylineState)
//   const [bounds, setBounds] = useState<Array<[number, number]>>([])

//   useEffect(() => {
//     let centers: Array<[number, number]> = []
//     Object.entries(polylines).forEach(([id, data]) => {
//       centers.push([data.lat_0, data.lon_0])
//       centers.push([data.lat_1, data.lon_1])
//     })
//     setBounds(centers)
//   }, [polylines])

//   let markerBounds = latLngBounds([])
//   bounds.forEach(bound => {
//     markerBounds.extend(bound)
//   })
//   if (markerBounds.isValid()) map.fitBounds(markerBounds)
//   else map.setView({ lat: 23.502, lng: 120.239 }, 8)

//   return null
// }

export const Map = (props: MapProps) => {
  let layerGroup: L.LayerGroup = L.layerGroup()
  const mapRef = useRef<L.Map>()

  const createMap = () => {
    mapRef.current = L.map("mapid", { zoomControl: false, preferCanvas: true }).setView(
      L.latLng(props.center),
      9
    )
    L.tileLayer("https://wmts.nlsc.gov.tw/wmts/EMAP5/default/GoogleMapsCompatible/{z}/{y}/{x}", {
      maxZoom: 18,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
    }).addTo(mapRef.current)
    layerGroup.addTo(mapRef.current)
  }

  const addPolylinesToMap = () => {
    props.polylines.forEach(polyline => {
      L.polyline(
        [
          [polyline.lat_0, polyline.lon_0],
          [polyline.lat_1, polyline.lon_1],
        ],
        { color: polyline.color }
      ).addTo(mapRef.current as L.Map)
    })
  }

  useEffect(() => {
    addPolylinesToMap()
  }, [props.polylines])

  useEffect(() => {
    if (mapRef.current === undefined) createMap()
  }, [])
  return <Box sx={{ height: "48%", width: "97%" }} id="mapid" className={props.className}></Box>
}
