import { selector } from "recoil"
import { latLngBounds } from "leaflet"
import {
  sensorBoardState,
  MapMarkerProps,
  datetimePickerState,
  motJsonDataState,
  colorScaleColorsState,
  selectedTextItemState,
  absoluteColorScaleState,
  colorScaleSwitchState,
  deviceListState,
  displayBarState,
  DeviceProps,
  colorScaleBoundsState,
  ScaleProps,
  gridSettingsState,
  polylineState,
  PolylineProps,
  countyMappingState,
  filterProjectState,
  filterCountyState,
  deviceStatusState,
  projectDetailState,
  deviceSelectorState,
  DeviceSelectorProps,
  ProjectProps,
} from "@core/atoms"
import { max as lodashMax, min as lodashMin } from "lodash"
import moment from "moment"

export const filteredDeviceListState = selector({
  key: "filteredDeviceListState",
  get: ({ get }) => {
    let devices = get(deviceListState)
    const settings = get(displayBarState)
    if (settings.status !== "all") devices = devices.filter(item => item.status === settings.status)

    if (settings.order === "descending")
      devices = Object.values(devices).sort(
        (a, b) => moment(b.latestDataTime).unix() - moment(a.latestDataTime).unix()
      )
    else
      devices = Object.values(devices).sort(
        (a, b) => moment(a.latestDataTime).unix() - moment(b.latestDataTime).unix()
      )

    if (settings.keyword !== "")
      devices = devices.filter(device => device.deviceId.includes(settings.keyword))

    const datas: Array<DeviceProps> = []
    devices.forEach((d: DeviceProps) => {
      let latestDataTime = d.latestDataTime
        ? d.latestDataTime.substring(0, 16).replaceAll("-", "/")
        : "無最後更新時間"
      datas.push({
        ...d,
        latestDataTime: latestDataTime,
      })
    })

    return datas
  },
})

export const filterMotDataByTextItemAndTime = selector<
  [{ [index: string]: Array<MapMarkerProps> }, number, number]
>({
  key: "filterMotDataByTextItemAndTime",
  get: ({ get }) => {
    let datas = get(motJsonDataState)
    const selectedTextItem = get(selectedTextItemState)
    const relativeColorScale = get(colorScaleColorsState)
    const pickerTime = get(datetimePickerState)
    const switches = get(sensorBoardState).switches
    const absoluteColorScale = get(absoluteColorScaleState)
    const colorScaleSwitch = get(colorScaleSwitchState)
    const filterDate = moment(pickerTime.start).format("YYYY-MM-DD")
    const filterTime = moment(pickerTime.start).format("YYYY-MM-DD HH:mm:ss")

    let sorted: { [index: string]: Array<MapMarkerProps> } = {}
    let values = datas.map(data => {
      const dataTime = data.time.split(" ")[0]
      const dataValue = parseFloat(data[selectedTextItem.queryName])
      if (dataTime === filterDate && !isNaN(dataValue) && switches[data.device_id]) return dataValue
      return 0
    })
    let min = 0
    let max = 0
    let range = 0
    if (
      colorScaleSwitch === "相對" ||
      absoluteColorScale[selectedTextItem.queryName] === undefined
    ) {
      min = lodashMin(values) as number
      max = lodashMax(values) as number
      range = max - min
      datas.forEach(data => {
        if (sorted[data.device_id] === undefined) {
          sorted[data.device_id] = []
        } else {
          if (!switches[data.device_id]) return

          const time = data.time.split(" ")[0]
          if (data.time < filterTime || time !== filterDate) return

          let value = parseFloat(data[selectedTextItem.queryName])
          let gap = Math.round((range / relativeColorScale.length) * 10) / 10
          let index = range === 0 ? 0 : Math.trunc((value - min) / gap)

          if (max !== 0 && parseFloat(data.lat) > 20.0)
            sorted[data.device_id].push({
              center: [parseFloat(data.lat), parseFloat(data.lon)],
              id: data.device_id,
              value: value,
              color: relativeColorScale[index],
              timestamp: moment(data.time).unix(),
            })
        }
      })
    } else {
      datas.forEach(data => {
        if (sorted[data.device_id] === undefined) {
          sorted[data.device_id] = []
        } else {
          if (!switches[data.device_id]) return

          const time = data.time.split(" ")[0]
          if (data.time < filterTime || time !== filterDate) return

          let index = "0"
          let value = parseFloat(data[selectedTextItem.queryName])
          for (const [k, v] of Object.entries(absoluteColorScale[selectedTextItem.queryName])) {
            if (value >= v.value) index = k
            else break
          }
          let index_to_int = parseInt(index)
          if (index_to_int === absoluteColorScale[selectedTextItem.queryName].length - 1)
            index_to_int = index_to_int - 1

          if (parseFloat(data.lat) > 20.0)
            sorted[data.device_id].push({
              center: [parseFloat(data.lat), parseFloat(data.lon)],
              id: data.device_id,
              value: value,
              color: absoluteColorScale[selectedTextItem.queryName][index_to_int].color,
              timestamp: moment(data.time).unix(),
            })
        }
      })
    }
    return [sorted, max, min]
  },
})

export const getDatetimeOfMotData = selector({
  key: "getDatetimeOfMotData",
  get: ({ get }) => {
    const datas = get(motJsonDataState)

    let dates: Array<string> = []
    let date = ""
    datas.forEach(data => {
      const currentDate = data.time.split(" ")[0]
      if (date !== currentDate) {
        dates.push(currentDate)
        date = currentDate
      }
    })
    return dates
  },
})

export const relativeColorScaleState = selector({
  key: "relativeColorScaleState",
  get: ({ get }) => {
    const colorScale = get(colorScaleColorsState)
    const scaleBounds = get(colorScaleBoundsState)
    let colors: Array<ScaleProps> = []
    const gap = (scaleBounds.max - scaleBounds.min) / colorScale.length

    colorScale.forEach((color, index) => {
      colors.push({
        color: color,
        value: Math.round((scaleBounds.min + index * gap) * 10) / 10,
      })
    })
    colors.push({
      color: "transparent",
      value: Math.round(scaleBounds.max * 10) / 10,
    })

    return colors
  },
})

export const polylinesWithGridColor = selector<[PolylineProps[], number, number]>({
  key: "polylinesWithGridColor",
  get: ({ get }) => {
    const gridSettings = get(gridSettingsState)
    const colorScaleSwitch = get(colorScaleSwitchState)
    const absoluteColorScale = get(absoluteColorScaleState)
    const selectedTextItem = get(selectedTextItemState)
    let datas = get(polylineState)

    let sorted: Array<PolylineProps> = []
    const values = datas.map(data => data.avg)
    const min = Math.min(...values)
    const max = Math.max(...values)
    if (colorScaleSwitch === "相對") {
      const range = max - min
      let colorScale: Array<string> = []
      let i = 1
      while (gridSettings["色階級距顏色" + i]) {
        colorScale.push(gridSettings["色階級距顏色" + i])
        i++
      }

      datas.forEach(data => {
        let value = data.avg
        let index = range === 0 ? 0 : Math.round(((value - min) / range) * (colorScale.length - 1))
        sorted.push({
          ...data,
          color: colorScale[index],
        })
      })
    } else {
      datas.forEach(data => {
        let index = "0"
        let value = data.avg
        for (const [k, v] of Object.entries(absoluteColorScale[selectedTextItem.queryName])) {
          if (value >= v.value) index = k
          else break
        }
        let index_to_int = parseInt(index)
        if (index_to_int === absoluteColorScale[selectedTextItem.queryName].length - 1)
          index_to_int = index_to_int - 1

        sorted.push({
          ...data,
          color: absoluteColorScale[selectedTextItem.queryName][index_to_int].color,
        })
      })
    }
    return [sorted, max, min]
  },
})

export const idsOfDeviceListState = selector({
  key: "idsOfDeviceListState",
  get: ({ get }) => {
    let devices = get(deviceListState)
    return devices.map(d => d.deviceId)
  },
})

export const countyDictMappingState = selector({
  key: "countyDictMappingState",
  get: ({ get }) => {
    const counties = get(countyMappingState)
    let mapping: { [index: string]: string } = {}
    counties.forEach(county => {
      mapping[county.code] = county.name
    })
    return mapping
  },
})

export const countyOptionState = selector({
  key: "countyOptionState",
  get: ({ get }) => {
    const projectDevices = get(projectDetailState)
    const countyName = get(countyDictMappingState)
    let projectCounties: Array<string> = []
    projectDevices.forEach((data: any) => {
      if (projectCounties.find(e => e === countyName[data.county]) === undefined)
        projectCounties.push(countyName[data.county])
    })
    return projectCounties
  },
})

export const filteredDeviceSelectorState = selector({
  key: "filteredDeviceSelectorState",
  get: ({ get }) => {
    const status = get(deviceStatusState)
    const selectedProjects = get(filterProjectState)
    const selectedCounties = get(filterCountyState)
    const countyName = get(countyDictMappingState)
    let allProjects = get(projectDetailState)
    let allDevice = get(deviceSelectorState)

    if (status !== "all" && status !== "")
      allDevice = allDevice.filter((item: DeviceSelectorProps) => item.status === status)

    let devices: Array<string> = []
    if (selectedProjects.length) {
      allProjects = allProjects.filter((p: ProjectProps) =>
        selectedProjects.map(project => JSON.parse(project).id).includes(p.projectId)
      )
    }

    if (selectedCounties.length) {
      allProjects = allProjects.filter((p: ProjectProps) =>
        selectedCounties.map(county => JSON.parse(county).id).includes(countyName[p.county])
      )
    }

    if (selectedProjects.length || selectedCounties.length) {
      allProjects.forEach(
        (item: ProjectProps) =>
          (devices = [...devices, ...item.devices.map((d: DeviceProps) => d.deviceId)])
      )
      allDevice = allDevice.filter((item: DeviceSelectorProps) => devices.includes(item.device_id))
    }
    return allDevice
  },
})

export const motDataBoundaryState = selector({
  key: "motDataBoundaryState",
  get: ({ get }) => {
    const [filteredMotData, ,] = get(filterMotDataByTextItemAndTime)
    const deviceBoundary: { [index: string]: L.LatLngBounds } = {}
    for (const [id, markers] of Object.entries(filteredMotData)) {
      let markerBounds = latLngBounds([])
      markers.forEach(marker => markerBounds.extend(marker.center))
      deviceBoundary[id] = markerBounds
    }

    return deviceBoundary
  },
})
