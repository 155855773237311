import React, { useEffect, useState, useRef } from "react"
import * as L from "leaflet"
import "leaflet-rotatedmarker"
import { useRecoilValue, useRecoilState } from "recoil"
import {
  WindDataProps,
  windDataSortByTimeState,
  progressDisplayTimeState,
  deviceDataStates,
  datetimePickerState,
  userAuthSate,
  showLoadingScreenState,
} from "@core/atoms"
import { fetchWindDataAPI } from "@core/API"
import { useQuery } from "react-query"
import moment from "moment"

type WindLayerProps = {
  mapRef: React.MutableRefObject<L.Map | undefined>
  layerControlRef: React.MutableRefObject<L.Control.Layers | null>
}

const genWindMarkerPopupContent = (marker: WindDataProps) => {
  return `
      <Box>
        <Box>
          <span>Device ID：</span>
          <span>${marker.device_id}</span>
        </Box>
        <br />
        <Box>
          <span>來源：</span>
          <span>${marker.source}</span>
        </Box>
        <br />
        <Box>
          <span>日期：</span>
          <span>${marker.time}</span>
        </Box>
        <br />
        <Box>
          <span>風速：</span>
          <span>${marker.wind_speed.toFixed(1)}</span>
        </Box>
      </Box>
    `
}

export default function WindLayer(props: WindLayerProps) {
  const { layerControlRef } = props

  const user = useRecoilValue(userAuthSate)
  const displayTimestamp = useRecoilValue(progressDisplayTimeState)
  const deviceDatas = useRecoilValue(deviceDataStates)
  const pickerTime = useRecoilValue(datetimePickerState)

  const [windDataSortByTime, setWindDataSortByTime] = useRecoilState(windDataSortByTimeState)
  const [, setLoading] = useRecoilState(showLoadingScreenState)

  const [isFetching, setIsFetching] = useState(false)

  const windLayerNationRef = useRef<L.LayerGroup>(L.layerGroup())
  const windLayerCentralRef = useRef<L.LayerGroup>(L.layerGroup())
  const isLayerAddedRef = useRef(false)
  const paramsRef = useRef({})
  const lastFetchTimeRef = useRef(9999999999999)
  const lastDisplayHourRef = useRef("")

  function get_wind_level(speed: number) {
    if (speed === null) return null
    var wind = [0, 0.3, 1.6, 3.4, 5.5, 8.0, 10.8, 13.9, 9999]
    for (var i = 0; i < wind.length - 1; i++) {
      if (speed >= wind[i] && speed < wind[i + 1]) {
        return i
      }
    }
  }

  function filterSortByTime(sortByTime: { [index: string]: WindDataProps[] }): {
    [index: string]: WindDataProps[]
  } {
    const uniqueSortByTime: { [index: string]: WindDataProps[] } = {}

    Object.keys(sortByTime).forEach(key => {
      const uniqueObjects: WindDataProps[] = []
      const deviceIds: string[] = []

      sortByTime[key].forEach(obj => {
        if (!deviceIds.includes(obj.device_id)) {
          uniqueObjects.push(obj)
          deviceIds.push(obj.device_id)
        }
      })

      uniqueSortByTime[key] = uniqueObjects
    })

    return uniqueSortByTime
  }

  const sortWindDataByTime = (datas: Array<WindDataProps>) => {
    let sortByTime: { [index: string]: WindDataProps[] } = {}
    datas.forEach((data: WindDataProps) => {
      const time = data.time
      if (sortByTime[time] === undefined) {
        sortByTime[time] = []
      }
      sortByTime[time].push(data)
    })
    return filterSortByTime(sortByTime)
  }

  const addWindMarkerToMap = (datetime: string) => {
    if (windDataSortByTime[datetime] === undefined) return
    const popupOptions: L.PopupOptions = {
      maxWidth: 200, // set max-width
      className: "npopup", // name custom popup
      closeOnClick: false,
    }

    windDataSortByTime[datetime].forEach((device: WindDataProps) => {
      if (device.wind_speed < 0) return
      const center: L.LatLngExpression = [device.lat, device.lon]
      const sourseLayer = device.source === "國家測站" ? windLayerNationRef : windLayerCentralRef

      const windSpeed = get_wind_level(device.wind_speed)
      const color: string = device.source === "國家測站" ? "#7d7d7d" : "#6ecef8"
      const options = {
        fillOpacity: 0.5,
        color: color,
        radius: 12,
        stroke: false,
      }
      const markerIcon = L.icon({
        iconUrl: `static/img/wind/WindSpeed_marker_0${windSpeed}.png`,
        iconSize: [22, 22],
      })

      L.circleMarker(center, options).addTo(sourseLayer.current)
      L.marker(center, {
        icon: markerIcon,
        rotationAngle: device.wind_direct,
        rotationOrigin: "center center",
      })
        .bindTooltip(genWindMarkerPopupContent(device), popupOptions)
        .addTo(sourseLayer.current)
    })
  }

  const addWindLayersToControl = () => {
    layerControlRef.current?.addOverlay(windLayerCentralRef.current, "氣象局測站(風標)")
    layerControlRef.current?.addOverlay(windLayerNationRef.current, "環保署測站(風標)")
  }

  const { refetch: refetchWindData } = useQuery("fetch-wind-data-api", {
    queryFn: () => fetchWindDataAPI(user, paramsRef.current),
    onSuccess: (res: any) => {
      setLoading(false)
      setWindDataSortByTime({
        ...windDataSortByTime,
        ...sortWindDataByTime(res.data.data),
      })
    },
    onError: (error: any) => {
      setLoading(false)
      console.log(error)
    },
    enabled: false,
  })

  const fetchWindData = (start_time: string, end_time: string) => {
    if (windDataSortByTime[start_time] !== undefined) return
    setIsFetching(true)
    paramsRef.current = {
      sources: "國家測站,中央氣象局",
      start_time: start_time,
      end_time: end_time,
      min_lat: -90,
      max_lat: 90,
      min_lon: -180,
      max_lon: 180,
    }
    refetchWindData()
    setLoading(true)
  }

  useEffect(() => {
    const start_time = moment(pickerTime.start).format("YYYY-MM-DD HH:mm:ss")
    const end_time = moment(pickerTime.end).format("YYYY-MM-DD HH:mm:ss")
    fetchWindData(start_time, end_time)
  }, [pickerTime])

  useEffect(() => {
    const momentDate = moment(pickerTime.end)
    const previousHour = momentDate.startOf("hour")
    const previousHourTimestamp = previousHour.unix()
    const timeGapSeconds = 60 * 10
    lastFetchTimeRef.current = previousHourTimestamp + timeGapSeconds
  }, [pickerTime.end])

  useEffect(() => {
    const updateTimeGapSeconds = 60 * 60
    const differenceInSeconds = displayTimestamp - lastFetchTimeRef.current
    if (differenceInSeconds < 0) return

    const nextTimestamp = lastFetchTimeRef.current + updateTimeGapSeconds
    const start_time = moment.unix(lastFetchTimeRef.current).format("YYYY-MM-DD HH:mm:ss")
    const end_time = moment.unix(nextTimestamp).format("YYYY-MM-DD HH:mm:ss")
    fetchWindData(start_time, end_time)
    lastFetchTimeRef.current = nextTimestamp
  }, [displayTimestamp])

  useEffect(() => {
    if (Object.keys(windDataSortByTime).length === 0) return

    const givenMoment = moment.unix(displayTimestamp)
    const nearestHour = givenMoment.startOf("hour")
    const formattedDatehour = nearestHour.format("YYYY-MM-DD HH")
    const formattedDatetime = nearestHour.format("YYYY-MM-DD HH:mm:ss")
    if (formattedDatehour === lastDisplayHourRef.current) return

    lastDisplayHourRef.current = formattedDatehour
    windLayerNationRef.current.clearLayers()
    windLayerCentralRef.current.clearLayers()
    addWindMarkerToMap(formattedDatetime)
  }, [displayTimestamp])

  useEffect(() => {
    if (Object.keys(windDataSortByTime).length === 0) return

    const givenMoment = moment.unix(displayTimestamp)
    const nearestHour = givenMoment.startOf("hour")
    const formattedDatetime = nearestHour.format("YYYY-MM-DD HH:mm:ss")

    windLayerNationRef.current.clearLayers()
    windLayerCentralRef.current.clearLayers()
    addWindMarkerToMap(formattedDatetime)
  }, [windDataSortByTime])

  useEffect(() => {
    if (Object.keys(deviceDatas).length > 0 && !isLayerAddedRef.current) {
      addWindLayersToControl()
      isLayerAddedRef.current = true
    }
  }, [deviceDatas])

  return <></>
}
