import { useState, useEffect } from "react"
import { NavBar } from "@components/NavBar"
import { Box, Typography, Button, Chip, CircularProgress } from "@mui/material"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useRecoilState, useResetRecoilState } from "recoil"
import {
  userAuthSate,
  countyMappingState,
  openNewProjectState,
  ProjectCellProps,
  selectedProjectState,
  projectListState,
} from "@core/atoms"
import { fetchMyProjectsAPI, fetchCountiesAPI } from "@core/API"
import { NewProject } from "@projects/NewProject"
import { ProjectDetail } from "@projects/ProjectDetail"
import { useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import moment from "moment"
import "@css/projects.css"

const ProjectCell = (props: { data: ProjectCellProps }) => {
  const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectState)
  const [, setOpenNewProject] = useRecoilState(openNewProjectState)
  const handleClick = () => {
    setSelectedProject(props.data)
    setOpenNewProject(false)
  }
  return (
    <Box
      sx={{
        height: "100px",
        display: "flex",
        alignItems: "center",
        bgcolor: "white",
      }}
      {...props}
      onClick={handleClick}
      className={`project-cell ${
        selectedProject?.projectId === props.data.projectId ? "active" : ""
      }`}
    >
      <Box
        className="project-indicator"
        sx={{
          width: "2%",
          height: "100%",
          bgcolor: selectedProject?.projectId === props.data.projectId ? "black" : null,
        }}
      ></Box>
      <Box className="project-intro-container" sx={{ width: "85%", pl: "5%", pt: 1, pb: 1 }}>
        <Box className="project-intro-wrapper" sx={{ display: "flex" }}>
          <Typography className="project-intro-title" variant="h6" component="span">
            {props.data.projectName}
          </Typography>
          {props.data.endDate !== null && props.data.endDate < moment().format("YYYY-MM-DD") ? (
            <Chip
              className="project-info-chip finished"
              label="已完成"
              sx={{ ml: 2, bgcolor: "orange" }}
            />
          ) : (
            <Chip className="project-info-chip" label="未完成" sx={{ ml: 2 }} />
          )}
        </Box>
        <Box
          className="project-lower-intro-wrapper"
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography className="project-intro-progress" variant="h6" component="span">
            60%
          </Typography>
          <Typography className="project-intro-owner" variant="h6" component="span">
            {props.data.ownerAccount}
          </Typography>
        </Box>
      </Box>
      <ChevronRightIcon className="project-intro-chevron" />
    </Box>
  )
}

const Empty = () => {
  return (
    <Box
      sx={{
        color: "#999",
        display: "flex",
        height: "100%",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          display: "block",
        }}
      >
        請先於左側選單 <br /> 選擇專案或新增專案
      </h2>
    </Box>
  )
}

export const Projects = () => {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userAuthSate)
  const [, setCountyMapping] = useRecoilState(countyMappingState)
  const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectState)
  const [openNewProject, setOpenNewProject] = useRecoilState(openNewProjectState)

  const [projects, setProjects] = useRecoilState(projectListState)

  const resetSelectedProject = useResetRecoilState(selectedProjectState)
  const resetOpenNewProject = useResetRecoilState(openNewProjectState)

  const resetRecoilStates = () => {
    resetSelectedProject()
    resetOpenNewProject()
  }

  const handleNewProject = () => {
    setSelectedProject(null)
    setOpenNewProject(true)
  }

  const { isLoading } = useQuery("fetch-my-projects-api", {
    queryFn: () => fetchMyProjectsAPI(user),
    onSuccess: (res: any) => {
      setProjects(res.data.reverse())
    },
    onError: (error: any) => {
      if (error.response.statusText === "Unauthorized") {
        setUser(null)
        navigate("/")
      }
    },
  })

  const { refetch: refetchCounties } = useQuery("fetch-couties-api", {
    queryFn: () => fetchCountiesAPI(user),
    onSuccess: (res: any) => {
      setCountyMapping(Object.values(res.data))
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  useEffect(() => {
    const newProjects = projects.map(project => {
      if (project.projectId === selectedProject?.projectId) return selectedProject
      else return project
    })
    setProjects(newProjects as ProjectCellProps[])
  }, [selectedProject])

  useEffect(() => {
    resetRecoilStates()
    refetchCounties()
  }, [])

  return (
    <>
      <NavBar />
      <Box
        className="project-outer-container"
        sx={{
          display: "flex",
          height: "100vh",
          bgcolor: "background.paper",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box
          className="project-inner-container"
          sx={{ height: "90%", width: "90%", display: "flex" }}
        >
          <Box
            className="project-left-container"
            sx={{
              display: "flex",
              height: "100%",
              width: "35%",
              bgcolor: "#f6f6f6",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              className="project-list-wrapper"
              sx={{
                height: "90%",
                width: "80%",
                justifyContent: "space-around",
              }}
            >
              <Box
                className="project-banner-container"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "10%",
                  alignItems: "center",
                }}
              >
                <Typography className="project-banner-title" variant="h5" component="span">
                  我的專案
                </Typography>
                <Button
                  className="project-banner-button"
                  variant="text"
                  startIcon={<AddBoxOutlinedIcon />}
                  onClick={handleNewProject}
                >
                  建立專案
                </Button>
              </Box>
              <Box
                className="project-list-container"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  height: "90%",
                }}
              >
                {isLoading ? (
                  <CircularProgress sx={{ alignSelf: "center", mt: 5 }} />
                ) : (
                  projects.map(proj => <ProjectCell key={proj.projectId} data={proj} />)
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="project-right-container"
            sx={{ height: "100%", width: "65%", bgcolor: "#fafafa" }}
          >
            {openNewProject ? (
              <NewProject />
            ) : selectedProject !== null ? (
              <ProjectDetail data={selectedProject} />
            ) : (
              <Empty />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
