import * as React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import SatelliteIcon from "@mui/icons-material/Satellite"
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import FolderIcon from "@mui/icons-material/Folder"
import AirIcon from "@mui/icons-material/Air"
import { useNavigate } from "react-router-dom"

type DrawerProps = {
  open: boolean
  onClose: Function
}

export const DrawerMenu = (props: DrawerProps) => {
  const navigate = useNavigate()
  let textToSegment: { [index: string]: string } = {
    打點版: "/",
    空間分析版: "/Analysis",
    管理專案: "/Projects",
  }
  const mode: string = process.env.REACT_APP_INDEX_COMPONENT as string
  const handleButtonClick = (text: string) => {
    props.onClose()
    navigate(textToSegment[text])
  }

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {["打點版", "空間分析版", "管理專案"].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            className={text === "管理專案" ? "btn-project-manager" : ""}
          >
            <ListItemButton onClick={() => handleButtonClick(text)}>
              <ListItemIcon>
                {index === 0 && <SatelliteIcon />}
                {index === 1 && <DirectionsCarIcon />}
                {index === 2 && <FolderIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
        {mode === "redirect" && (
          <ListItem key="返回空氣網" disablePadding>
            <ListItemButton onClick={() => window.open("https://wot.moenv.gov.tw/", "_blank")}>
              <ListItemIcon>
                <AirIcon />
              </ListItemIcon>
              <ListItemText primary="返回空氣網" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  )

  return (
    <Drawer open={props.open} onClose={() => props.onClose()}>
      {list()}
    </Drawer>
  )
}
