import { useRecoilValue } from "recoil"
import { showLoadingScreenState } from "@core/atoms"

export default function Loading() {
  const loading = useRecoilValue(showLoadingScreenState)
  return (
    <div className={loading ? "loading" : ""}>
      <span className="loader"></span>
    </div>
  )
}
