import { useEffect, useRef } from "react"
import L from "leaflet"
import { useRecoilValue } from "recoil"
import {
  datetimePickerState,
  deviceDataStates,
  progressDisplayTimeState,
  MapMarkerProps,
  sensorBoardState,
  gridSettingsState,
  selectedTextItemState,
  colorScaleSwitchState,
  MapRefProps,
} from "@core/atoms"
import moment from "moment"
import "leaflet/dist/leaflet.css"

const genMarkerPopupContent = (marker: MapMarkerProps) => {
  return `
      <Box>
        <Box>
          <span>Device ID：</span>
          <span>${marker.id}</span>
        </Box>
        <br />
        <Box>
          <span>日期：</span>
          <span>${moment.unix(marker.timestamp).format("YYYY-MM-DD HH:mm:ss")}</span>
        </Box>
        <br />
        <Box>
          <span>數值：</span>
          <span>${marker.value}</span>
        </Box>
      </Box>
    `
}

const genCarMarkerPopupContent = (id: string) => {
  return `
      <Box>
        <span>Device ID：</span>
        <span>${id}</span>
      </Box>
    `
}

const customOptions: L.PopupOptions = {
  maxWidth: 200,
  className: "npopup",
  closeOnClick: false,
}

export default function MotDeviceLayer(props: MapRefProps) {
  const { mapRef } = props

  const deviceData = useRecoilValue(deviceDataStates)
  const sensorBoard = useRecoilValue(sensorBoardState)
  const displayTimestamp = useRecoilValue(progressDisplayTimeState)
  const gridSettings = useRecoilValue(gridSettingsState)
  const selectedTextItem = useRecoilValue(selectedTextItemState)
  const timePicker = useRecoilValue(datetimePickerState)
  const colorScaleSwitch = useRecoilValue(colorScaleSwitchState)

  const redrawRef = useRef<boolean>(false)
  const prevTimestampRef = useRef<number>(0)
  const markerLayersRef = useRef<{ [index: string]: L.LayerGroup }>({})
  const carLayersRef = useRef<{ [index: string]: L.LayerGroup }>({})
  const deviceDataIndexRef = useRef<{ [index: string]: number }>({})

  const markerIcon = L.icon({
    iconUrl: `/static/img/car.svg`,
    iconSize: [26, 36],
  })

  const clearCurrnetLayersAndIndexed = () => {
    const deviceIds = Object.keys(markerLayersRef.current)
    deviceIds.forEach(deviceId => {
      markerLayersRef.current[deviceId].clearLayers()
      carLayersRef.current[deviceId].clearLayers()
      deviceDataIndexRef.current[deviceId] = 0
    })
  }

  const initializeLayersAndDataIndexes = (deviceIds: Array<string>) => {
    markerLayersRef.current = {}
    carLayersRef.current = {}
    deviceDataIndexRef.current = {}
    deviceIds.forEach(deviceId => {
      const markerLayer = L.layerGroup()
      const carLayer = L.layerGroup()
      markerLayer.addTo(mapRef.current as L.Map)
      carLayer.addTo(mapRef.current as L.Map)
      markerLayersRef.current[deviceId] = markerLayer
      carLayersRef.current[deviceId] = carLayer
      deviceDataIndexRef.current[deviceId] = 0
    })
  }

  const setCarMarker = (center: [number, number], deviceId: string) => {
    const carMarkers = carLayersRef.current[deviceId].getLayers()
    if (carMarkers.length !== 0) {
      ;(carMarkers[0] as L.Marker).setLatLng(center)
    } else {
      L.marker(center, { icon: markerIcon })
        .bindTooltip(genCarMarkerPopupContent(deviceId), customOptions)
        .addTo(carLayersRef.current[deviceId])
    }
  }

  const timeMovesForwards = () => {
    const radius: number = isNaN(parseInt(gridSettings["radius"]))
      ? 6
      : parseInt(gridSettings["radius"])

    for (const [deviceId, datas] of Object.entries(deviceData)) {
      let startIndex = deviceDataIndexRef.current[deviceId]
      for (let i = startIndex; i < datas.length; i++) {
        const data = datas[i]
        if (data.timestamp <= displayTimestamp) {
          const options = {
            fillOpacity: 1,
            color: data.color,
            radius: radius,
            timestamp: data.timestamp,
          }
          L.circleMarker(data.center, options)
            .bindTooltip(genMarkerPopupContent(data), customOptions)
            .addTo(markerLayersRef.current[deviceId])

          setCarMarker(data.center, deviceId)
          deviceDataIndexRef.current[deviceId]++
        } else {
          break
        }
      }
    }
  }

  const timeMovesBackwards = () => {
    for (const [deviceId, markerGroup] of Object.entries(markerLayersRef.current)) {
      let allMarkers = markerGroup.getLayers()
      let index = allMarkers.length - 1
      let flag = false

      let currentMarker: any
      while (index >= 0 && !flag) {
        currentMarker = allMarkers[index]

        if (currentMarker.options.timestamp > displayTimestamp) {
          markerLayersRef.current[deviceId].removeLayer(currentMarker)
          deviceDataIndexRef.current[deviceId]--
        } else flag = true
        index = index - 1
      }
      if (index < 0) {
        carLayersRef.current[deviceId].clearLayers()
      } else {
        const latlng = currentMarker.getLatLng()
        setCarMarker([latlng.lat, latlng.lng], deviceId)
      }
    }
  }

  const switchLayers = (switches: { [index: string]: boolean }) => {
    for (const [deviceId, show] of Object.entries(switches)) {
      if (markerLayersRef.current[deviceId] === undefined) return
      if (show) {
        markerLayersRef.current[deviceId].addTo(mapRef.current as L.Map)
        carLayersRef.current[deviceId].addTo(mapRef.current as L.Map)
      } else {
        markerLayersRef.current[deviceId].removeFrom(mapRef.current as L.Map)
        carLayersRef.current[deviceId].removeFrom(mapRef.current as L.Map)
      }
    }
  }

  useEffect(() => {
    if (sensorBoard.loading) {
      let deviceIds = Object.keys(sensorBoard.switches)
      clearCurrnetLayersAndIndexed()
      initializeLayersAndDataIndexes(deviceIds)
      prevTimestampRef.current = 0
    } else redrawRef.current = true
  }, [sensorBoard.loading])

  useEffect(() => {
    if (!sensorBoard.loading) {
      if (prevTimestampRef.current > displayTimestamp) timeMovesBackwards()
      else timeMovesForwards()
      prevTimestampRef.current = displayTimestamp
    }
  }, [displayTimestamp])

  useEffect(() => {
    if (!sensorBoard.loading) switchLayers(sensorBoard.switches)
  }, [sensorBoard.switches])

  useEffect(() => {
    redrawRef.current = true
  }, [selectedTextItem, timePicker, colorScaleSwitch])

  useEffect(() => {
    if (redrawRef.current && !sensorBoard.loading) {
      clearCurrnetLayersAndIndexed()
      prevTimestampRef.current = 0
      timeMovesForwards()
      redrawRef.current = false
    }
  }, [deviceData])
  return <></>
}
