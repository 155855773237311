import { useEffect, useState, useRef } from "react"
import L from "leaflet"
import { useRecoilValue } from "recoil"
import {
  gridSettingsState,
  IotDeviceProps,
  IotDeviceDataProps,
  MapRefProps,
  allIotDeviceState,
  trackingIotDeviceDataState,
  progressDisplayTimeState,
  sensorBoardState,
  colorScaleState,
  selectedTextItemState,
  colorScaleSwitchState,
  latestTrackingIotDeviceState,
  trackingIotDeviceState,
} from "@core/atoms"
import moment from "moment"

const genUnloadedDeviceMarkerPopupContent = (marker: IotDeviceProps, status: string) => {
  let content = ""
  if (status === "empty")
    content = `
    <Box>
      <span>尚未載入此裝置資料</span>
    </Box>
    `
  else if (status === "loading")
    content = `
    <Box>
      <span>裝置資料載入中...</span>
    </Box>
    `
  else if (status === "noData")
    content = `
    <Box>
      <span>該裝置無資料</span>
    </Box>
    `
  return (
    `
      <Box>
        <Box>
          <span>Device ID：</span>
          <span>${marker.id}</span>
        </Box>
        <br />
        <Box>
          <span>裝置名稱：</span>
          <span>${marker.name}</span>
        </Box>
        <br />
        ` +
    content +
    `
      </Box>
    `
  )
}

const genMarkerPopupContent = (marker: IotDeviceDataProps, name: string) => {
  return `
      <Box>
        <Box>
          <span>Device ID：</span>
          <span>${marker.device_id}</span>
        </Box>
        <br />
        <Box>
          <span>日期：</span>
          <span>${moment.unix(marker.timestamp as number).format("YYYY-MM-DD HH:mm:ss")}</span>
        </Box>
        <br />
        <Box>
          <span>測項：</span>
          <span>${name}</span>
        </Box>
        <br />
        <Box>
          <span>數值：</span>
          <span>${marker.value.toFixed(2)}</span>
        </Box>
      </Box>
    `
}

export default function IotDeviceLayer(props: MapRefProps) {
  const { mapRef } = props

  const gridSettings = useRecoilValue(gridSettingsState)
  const allIotDevices = useRecoilValue(allIotDeviceState)
  const trackingIotDeviceData = useRecoilValue(trackingIotDeviceDataState)
  const latestTrackingIotDevices = useRecoilValue(latestTrackingIotDeviceState)
  const trackingIotDevices = useRecoilValue(trackingIotDeviceState)
  const displayTimestamp = useRecoilValue(progressDisplayTimeState)
  const sensorBoard = useRecoilValue(sensorBoardState)
  const colorScale = useRecoilValue(colorScaleState)
  const selectedTextItem = useRecoilValue(selectedTextItemState)
  const colorScaleSwitch = useRecoilValue(colorScaleSwitchState)

  const [iotDataIndex, setIotDataIndex] = useState<{ [index: string]: number }>({})
  const [iotDataLayer] = useState<L.LayerGroup>(L.layerGroup())
  const [circleMarkers, setCircleMarkers] = useState<{ [index: string]: L.CircleMarker }>({})
  const [showIotStation, setShowIotStation] = useState(false)
  const [, setNextTimestamp] = useState(0)

  const iotDataLayerRef = useRef<L.LayerGroup>()
  iotDataLayerRef.current = iotDataLayer

  const radius: number = isNaN(parseInt(gridSettings["radius"]))
    ? 6
    : parseInt(gridSettings["radius"]) + 2

  function findMarkerIndex(markers: Array<IotDeviceDataProps>, timestamp: number): number {
    let left = 0
    let right = markers.length - 1

    while (left <= right) {
      const mid = Math.floor((left + right) / 2)
      const markerTimestamp = markers[mid].timestamp

      if (markerTimestamp <= timestamp) {
        if (mid + 1 >= markers.length || markers[mid + 1].timestamp > timestamp) return mid
        left = mid + 1
      } else {
        right = mid - 1
      }
    }
    return -1
  }

  const findCorrespondColor = (value: number) => {
    let color = colorScale[0].color
    for (let i = 1; i < colorScale.length; i++) {
      if (value >= colorScale[i].value) color = colorScale[i].color
      else break
    }
    if (color === colorScale[colorScale.length - 1].color)
      color = colorScale[colorScale.length - 2].color

    return color
  }

  const findAndShowCurrentIotMarker = () => {
    for (const [deviceId, markers] of Object.entries(trackingIotDeviceData)) {
      const index = findMarkerIndex(markers, displayTimestamp)
      if (index > 0) {
        const marker = markers[index]
        const options = {
          fill: true,
          fillOpacity: 1,
          stroke: true,
          weight: 2,
          color: "black",
          fillColor: findCorrespondColor(marker.value),
          radius: radius,
          timestamp: marker.timestamp,
        }
        if (mapRef.current !== undefined) {
          circleMarkers[marker.device_id]
            .setTooltipContent(genMarkerPopupContent(marker, selectedTextItem.name))
            .setStyle(options)
        }
        setIotDataIndex(prev => ({ ...prev, [deviceId]: index }))
      }
    }
  }

  const addEmptyIotDeviceMarkerToMap = () => {
    let markers: { [index: string]: L.CircleMarker } = {}
    const popupOptions: L.PopupOptions = {
      maxWidth: 200, // set max-width
      className: "npopup", // name custom popup
      closeOnClick: false,
    }
    allIotDevices.forEach(device => {
      const options = {
        fill: true,
        fillOpacity: 1,
        stroke: true,
        weight: 2,
        color: "gray",
        fillColor: "transparent",
        radius: radius,
        className: "square-marker",
      }
      markers[device.device_id] = L.circleMarker([device.lat, device.lon], options)
        .bindTooltip(genUnloadedDeviceMarkerPopupContent(device, "empty"), popupOptions)
        .addTo(iotDataLayerRef.current as L.LayerGroup)
    })
    setCircleMarkers({ ...circleMarkers, ...markers })
  }

  const changeIotDeviceMarkerToTracking = (iotDevices: Array<IotDeviceProps>) => {
    iotDevices.forEach(device => {
      const options = {
        fill: true,
        fillOpacity: 1,
        stroke: true,
        weight: 2,
        color: "orange",
        fillColor: "transparent",
        radius: radius,
      }
      circleMarkers[device.device_id]
        .setTooltipContent(genUnloadedDeviceMarkerPopupContent(device, "loading"))
        .setStyle(options)
    })
  }

  const timeMovesForwards = () => {
    for (const [deviceId, markers] of Object.entries(trackingIotDeviceData)) {
      let startIndex = iotDataIndex[deviceId] ?? 0
      for (let i = startIndex; i < markers.length; i++) {
        const marker = markers[i]
        if (marker.timestamp <= displayTimestamp) {
          if (mapRef.current !== undefined) {
            const options = {
              fill: true,
              fillOpacity: 1,
              stroke: true,
              weight: 2,
              color: "black",
              fillColor: findCorrespondColor(marker.value),
              radius: radius,
              timestamp: marker.timestamp,
            }
            circleMarkers[marker.device_id]
              .setTooltipContent(genMarkerPopupContent(marker, selectedTextItem.name))
              .setStyle(options)
          }
        } else {
          setIotDataIndex(prev => ({ ...prev, [deviceId]: i }))
          break
        }
      }
    }
  }

  const timeMovesBackwards = () => {
    for (const [deviceId, markers] of Object.entries(trackingIotDeviceData)) {
      let startIndex = iotDataIndex[deviceId] ?? 0
      for (let i = startIndex; i >= 0; i--) {
        const marker = markers[i]
        if (marker.timestamp >= displayTimestamp) {
          if (mapRef.current !== undefined) {
            const options = {
              fill: true,
              fillOpacity: 1,
              stroke: true,
              weight: 2,
              color: "black",
              fillColor: findCorrespondColor(marker.value),
              radius: radius,
              timestamp: marker.timestamp,
            }
            circleMarkers[marker.device_id]
              .setTooltipContent(genMarkerPopupContent(marker, selectedTextItem.name))
              .setStyle(options)
          }
        } else {
          setIotDataIndex(prev => ({ ...prev, [deviceId]: i }))
          break
        }
      }
    }
  }

  const addEventListener = () => {
    mapRef.current?.on("zoomend", function () {
      if ((mapRef.current as L.Map).getZoom() > (mapRef.current as L.Map).getMaxZoom() - 5) {
        setShowIotStation(true)
      } else {
        setShowIotStation(false)
      }
    })
  }

  const changeStyleOfTrackingMarkerWithNoData = () => {
    const DeviceIds = Object.keys(trackingIotDeviceData)
    const differentDeviceIds = latestTrackingIotDevices.filter(
      device => !DeviceIds.includes(device.device_id)
    )

    differentDeviceIds.forEach(device => {
      const options = {
        fill: true,
        fillOpacity: 1,
        stroke: true,
        weight: 2,
        color: "gray",
        fillColor: "gray",
        radius: radius,
      }
      circleMarkers[device.device_id]
        .setTooltipContent(genUnloadedDeviceMarkerPopupContent(device, "noData"))
        .setStyle(options)
    })
  }

  useEffect(() => {
    const name = selectedTextItem.name
    const validItem = name === "PM2.5" || name === "VOC"
    if (!validItem) return
    if (showIotStation) iotDataLayerRef.current?.addTo(mapRef.current as L.Map)
    else iotDataLayerRef.current?.removeFrom(mapRef.current as L.Map)
  }, [showIotStation])

  useEffect(() => {
    iotDataLayerRef.current?.clearLayers()
    addEmptyIotDeviceMarkerToMap()
  }, [allIotDevices])

  useEffect(() => {
    changeIotDeviceMarkerToTracking(latestTrackingIotDevices)
  }, [latestTrackingIotDevices])

  useEffect(() => {
    const name = selectedTextItem.name
    const validItem = name === "PM2.5" || name === "VOC"
    const map = mapRef.current as L.Map
    const shouldHandleLayer = map !== undefined && map.getZoom() > map.getMaxZoom() - 5
    if (validItem) {
      changeIotDeviceMarkerToTracking(trackingIotDevices)
      if (shouldHandleLayer) iotDataLayerRef.current?.addTo(mapRef.current as L.Map)
    } else {
      if (shouldHandleLayer) iotDataLayerRef.current?.removeFrom(mapRef.current as L.Map)
    }
  }, [selectedTextItem])

  useEffect(() => {
    findAndShowCurrentIotMarker()
    changeStyleOfTrackingMarkerWithNoData()
  }, [trackingIotDeviceData])

  useEffect(() => {
    if (!sensorBoard.loading) {
      setNextTimestamp(prev => {
        if (prev > displayTimestamp) timeMovesBackwards()
        else timeMovesForwards()
        return displayTimestamp
      })
    }
  }, [displayTimestamp, colorScaleSwitch])

  useEffect(() => {
    addEventListener()
    return () => {
      ;(mapRef.current as L.Map).off("zoomend")
    }
  }, [mapRef.current])
  return <></>
}
