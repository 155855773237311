import axios from "axios"
import { UserStateProps } from "@core/atoms"

const baseUrl = process.env.REACT_APP_API_URL
const google_doc_id = process.env.REACT_APP_GOOGLE_DOC_ID

const loginAPI = (bodyFormData: FormData) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/token",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })

const fetchCountiesAPI = (user: UserStateProps | null) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/counties",
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchAllDevicesAPI = (user: UserStateProps | null, params: any | null) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot/devices",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchProjectsAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot/projects",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchMyProjectsAPI = (user: UserStateProps | null) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/users/me/project",
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const postMyProjectsAPI = (user: UserStateProps | null, bodyFormData: FormData) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/users/me/project",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const deleteMyProjectsAPI = (user: UserStateProps | null, bodyFormData: FormData) =>
  axios({
    method: "delete",
    url: baseUrl + "mot_api/users/me/project",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const saveMyProjectsAPI = (user: UserStateProps | null, bodyFormData: FormData) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/users/me/project/save",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchGridSettingsAPI = (user: UserStateProps | null) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot/config",
    params: { google_doc_id: google_doc_id },
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchPolylinesAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/analysis/avg",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchWeeklyChartAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/analysis/avg/weekly",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchHourlyChartAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/analysis/avg/hourly",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const downloadMyProjectIntegrity = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/users/me/project/integrity/export",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
    responseType: "blob",
  })

const fetchMyProjectIntegrity = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/users/me/project/integrity",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchMyProjectIntegrityCount = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/users/me/project/integrity/total",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchRoadCountAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/analysis/count",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchMotDevicesAPI = (user: UserStateProps | null) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot/devices",
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchMyProjectDevicesAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/users/me/project/device",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const postMyProjectDevicesAPI = (user: UserStateProps | null, bodyFormData: FormData) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/users/me/project/device",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const deleteMyProjectDevicesAPI = (user: UserStateProps | null, bodyFormData: FormData) =>
  axios({
    method: "delete",
    url: baseUrl + "mot_api/users/me/project/device",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchMotDataAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot/data",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchMotBufferAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot/buffer",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const redirectToMotAPI = (params: any) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/wot/token/verify",
    params: params,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })

const importDevicesToProjectAPI = (user: UserStateProps | null, bodyFormData: FormData) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/users/me/project/device/import",
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchAbsoluteColorScaleAPI = () =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/color_scale",
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })

const fetchIotDevicesAPI = () =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/aiot/api/v2/iot/devices",
    params: {
      min_lat: -90,
      max_lat: 90,
      min_lon: -180,
      max_lon: 180,
    },
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  })

const fetchIotDeviceDataAPI = (user: UserStateProps | null, params: any, bodyFormData: FormData) =>
  axios({
    method: "post",
    url: baseUrl + "mot_api/aiot/api/v2/iot/rawdata",
    params: params,
    data: bodyFormData,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchWindDataAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/aiot/api/v2/epa_station/wind",
    params: params,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const fetchIotAvgAPI = (user: UserStateProps | null, params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/analysis/iot/_/avg",
    params: params,
    headers: {
      accept: "application/json",
      Authorization: `${user?.tokenType} ${user?.token}`,
    },
  })

const noAuthPolylinesAPI = (params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/_/analysis/avg",
    params: params,
    headers: {
      accept: "application/json",
    },
  })

const noAuthWeeklyChartAPI = (params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/_/analysis/avg/weekly",
    params: params,
    headers: {
      accept: "application/json",
    },
  })

const noAuthHourlyChartAPI = (params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/_/analysis/avg/hourly",
    params: params,
    headers: {
      accept: "application/json",
    },
  })

const noAuthGridSettingsAPI = () =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot_config",
    params: { google_doc_id: google_doc_id },
    headers: {
      accept: "application/json",
    },
  })

const noAuthMotDataAPI = (params: any) =>
  axios({
    method: "get",
    url: baseUrl + "mot_api/mot_data",
    params: params,
    headers: {
      accept: "application/json",
    },
  })

export {
  baseUrl,
  loginAPI,
  fetchCountiesAPI,
  fetchAllDevicesAPI,
  fetchProjectsAPI,
  fetchMyProjectsAPI,
  fetchGridSettingsAPI,
  fetchPolylinesAPI,
  fetchWeeklyChartAPI,
  fetchHourlyChartAPI,
  postMyProjectsAPI,
  deleteMyProjectsAPI,
  saveMyProjectsAPI,
  downloadMyProjectIntegrity,
  fetchMyProjectIntegrity,
  fetchMyProjectIntegrityCount,
  fetchRoadCountAPI,
  fetchMotDevicesAPI,
  fetchMyProjectDevicesAPI,
  postMyProjectDevicesAPI,
  deleteMyProjectDevicesAPI,
  fetchMotDataAPI,
  fetchMotBufferAPI,
  redirectToMotAPI,
  importDevicesToProjectAPI,
  fetchAbsoluteColorScaleAPI,
  fetchIotDevicesAPI,
  fetchIotDeviceDataAPI,
  fetchWindDataAPI,
  fetchIotAvgAPI,
  noAuthPolylinesAPI,
  noAuthWeeklyChartAPI,
  noAuthHourlyChartAPI,
  noAuthGridSettingsAPI,
  noAuthMotDataAPI,
}
