import { useEffect, useRef } from "react"
import L from "leaflet"
import { latLngBounds } from "leaflet"
import { useRecoilValue, useRecoilState } from "recoil"
import { colorScaleBoundsState, MapRefProps, PolylineProps } from "@core/atoms"
import { polylinesWithGridColor } from "@core/selectors"
import "leaflet/dist/leaflet.css"

const streetRankingString: string = process.env.REACT_APP_STREET_RANKING as string
const streetRanking: number = streetRankingString ? parseInt(streetRankingString) : 3

const genPopupContent = (item: PolylineProps) => {
  return `
    <div class="popup-rank">
      <div>
          <div class="rank">${item.ranking}</div>
          <div class="value">
              <span>數值</span>
              <span>${item.avg.toFixed(1)}</span>
          </div>
      </div>
    </div>
    `
}

type MapProps = MapRefProps & {
  changeView: boolean
  setChangeView: Function
}

export default function StreetLayer(props: MapProps) {
  const { mapRef, changeView, setChangeView } = props

  const [polylines, max, min] = useRecoilValue(polylinesWithGridColor)
  const [, setScaleBounds] = useRecoilState(colorScaleBoundsState)

  const layerRef = useRef<L.LayerGroup>(L.layerGroup())

  function changeMapView() {
    if (!changeView) return
    setChangeView(false)

    let centers: Array<[number, number]> = []
    Object.entries(polylines).forEach(([id, data]) => {
      centers.push([data.lat_0, data.lon_0])
      centers.push([data.lat_1, data.lon_1])
    })

    let markerBounds = latLngBounds([])
    centers.forEach(bound => {
      markerBounds.extend(bound)
    })

    if (markerBounds.isValid()) (mapRef.current as L.Map).fitBounds(markerBounds)
    else (mapRef.current as L.Map).setView({ lat: 23.502, lng: 120.239 }, 8)
  }

  function getRankingPosition(rank: number) {
    if (rank === 1) return "top"
    else if (rank === 2) return "left"
    else if (rank === 3) return "right"
    return "bottom"
  }

  const addPolylinesToMap = () => {
    polylines.forEach(polyline => {
      const line = L.polyline(
        [
          [polyline.lat_0, polyline.lon_0],
          [polyline.lat_1, polyline.lon_1],
        ],
        { color: polyline.color }
      )
      if (polyline.ranking <= streetRanking) {
        const lat = (polyline.lat_0 + polyline.lat_1) / 2
        const lon = (polyline.lon_0 + polyline.lon_1) / 2
        const pos = getRankingPosition(polyline.ranking)
        const tooltip = L.tooltip({ permanent: true, direction: pos, interactive: true })
          .setLatLng([lat, lon])
          .setContent(genPopupContent(polyline))
          .on("click", function () {
            window.open(`https://www.google.com/maps/dir/My+Location/${lat},${lon}/`, "_blank")
          })
          .addTo(layerRef.current)
        // line.bindTooltip(genPopupContent(polyline), { permanent: true, direction: pos })
      }
      line.addTo(layerRef.current)
    })
  }

  useEffect(() => {
    setScaleBounds({
      max: max,
      min: min,
    })
  }, [max, min])

  useEffect(() => {
    layerRef.current.clearLayers()
    addPolylinesToMap()
    changeMapView()
  }, [polylines])

  useEffect(() => {
    if (mapRef.current !== undefined) layerRef.current.addTo(mapRef.current)
  }, [mapRef])

  return null
}
