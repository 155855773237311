import { Box, Typography, Grid } from "@mui/material"
import { DeviceProps, DeviceBlockProps } from "@core/atoms"
import { filteredDeviceListState } from "@core/selectors"
import { useRecoilValue } from "recoil"
import DeleteIcon from "@mui/icons-material/Delete"

type BlockCellProps = {
  data: DeviceProps
  onDelete: Function
}

const BlockCell = ({ data, onDelete }: BlockCellProps) => {
  return (
    <Grid item xs={6} sm={4} md={2.4} className="device-block-cell">
      <Box
        sx={{
          height: "100px",
          "&:hover": {
            backgroundColor: "#f6a607",
            border: 0,
          },
        }}
        border={1}
        borderColor="GrayText"
        className="block-cell-container"
      >
        <Box className="block-cell-normal">
          <Box
            className="block-cell-upper"
            sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
          >
            <Box
              className="block-cell-status"
              sx={{
                height: "20px",
                width: "20px",
                borderRadius: 10,
                bgcolor:
                  data.status === "active"
                    ? "green"
                    : data.status === "warning"
                    ? "orange"
                    : "gray",
              }}
            />
            <Box className="block-cell-date">{data.latestDataTime.split(" ")[0]}</Box>
          </Box>
          <Typography
            className="block-cell-hourtime"
            variant="h6"
            component="span"
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {data.latestDataTime.split(" ")[1]}
          </Typography>
          <Box
            className="block-cell-id"
            borderTop={1}
            borderColor="GrayText"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "30%",
            }}
          >
            {data.deviceId}
          </Box>
        </Box>

        <Box className="block-cell-hover" sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            className="block-cell-upper"
            sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
          >
            <Box
              className="block-cell-delete"
              sx={{
                height: "20px",
                width: "20px",
                borderRadius: 10,
              }}
              onClick={() => onDelete()}
            >
              <DeleteIcon />
            </Box>
            <Box
              className="block-cell-id"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
                mb: 1,
                color: "white",
              }}
            >
              {data.deviceId}
            </Box>
          </Box>
          <Box
            className="block-cell-datetime"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "30%",
              color: "white",
            }}
          >
            {data.latestDataTime.replace(" ", " - ")}
          </Box>
          <Box
            className="block-cell-info"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              height: "30%",
            }}
          >
            <Box className="block-cell-contact" sx={{ color: "white", fontWeight: "bold" }}>
              {data.contact}
            </Box>
            <Box className="block-cell-phone" sx={{ color: "white", fontWeight: "bold" }}>
              {data.contactPhone}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export const DeviceBlock = (props: DeviceBlockProps) => {
  const devices = useRecoilValue(filteredDeviceListState)
  return (
    <Grid
      container
      className="device-block-container"
      sx={{ maxHeight: "80%", overflowY: "auto", overflowX: "hidden" }}
      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {devices.map((device: DeviceProps) => (
        <BlockCell
          data={device}
          key={device.deviceId + "-block"}
          onDelete={() => {
            props.setSelectedDeviceId(device.deviceId)
            props.setOpenDialog(true)
          }}
        />
      ))}
    </Grid>
  )
}
