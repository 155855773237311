import { useEffect, useState } from "react"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Box, FormControl, InputLabel, MenuItem, TextField } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import moment from "moment"
import { useRecoilState, useRecoilValue } from "recoil"
import { datetimePickerState } from "@core/atoms"

import { getDatetimeOfMotData } from "@core/selectors"

export default function DatetimePicker() {
  const datetimeObj = useRecoilValue(getDatetimeOfMotData)
  const [timePicker, setTimePicker] = useRecoilState(datetimePickerState)
  const [selectedDate, setSelectedDate] = useState(datetimeObj[0])

  useEffect(() => {
    setTimePicker({
      start: moment(selectedDate).startOf("day").toDate(),
      end: moment(selectedDate).endOf("day").toDate(),
    })
  }, [selectedDate])

  const handleChangeTime = (value: Date | null, type: string) => {
    setTimePicker({ ...timePicker, [type]: value })
  }

  const handleChangeDate = (event: SelectChangeEvent) => {
    setSelectedDate(event.target.value)
    setTimePicker({
      start: moment(event.target.value).startOf("day").toDate(),
      end: moment(event.target.value).endOf("day").toDate(),
    })
  }
  return (
    <Box className="display-filter-item">
      <FormControl className="filter-date-selector">
        <InputLabel>日期</InputLabel>
        <Select value={selectedDate ? selectedDate : ""} label="日期" onChange={handleChangeDate}>
          {datetimeObj.map(date => (
            <MenuItem value={date} key={date}>
              {date}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns} className="filter-time-range">
        <TimePicker
          className="filter-start-time"
          key="start-time-picker"
          label="開始時間"
          value={timePicker.start}
          onChange={(v: Date | null) => handleChangeTime(v, "start")}
          renderInput={params => <TextField {...params} />}
        />
        <TimePicker
          className="filter-end-time"
          key="end-time-picker"
          label="結束時間"
          value={timePicker.end}
          onChange={(v: Date | null) => handleChangeTime(v, "end")}
          renderInput={params => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  )
}
