import { Box, Typography } from "@mui/material"
import { DeviceProps, DeviceBlockProps } from "@core/atoms"
import { filteredDeviceListState } from "@core/selectors"
import { useRecoilValue } from "recoil"
import DeleteIcon from "@mui/icons-material/Delete"

export const DeviceList = (props: DeviceBlockProps) => {
  const devices = useRecoilValue(filteredDeviceListState)

  return (
    <Box
      className="device-list-container"
      sx={{
        height: "75%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box
        className="device-info-title"
        borderBottom={1}
        borderColor="gray"
        sx={{
          display: "flex",
          width: "100%",
          position: "sticky",
          top: "0",
          bgcolor: "#fafafa",
          pb: 2,
        }}
      >
        <Typography
          className="device-info-sensor"
          variant="h6"
          component="span"
          sx={{ width: "30%", color: "gray" }}
        >
          感測器
        </Typography>
        <Typography
          className="device-info-update"
          variant="h6"
          component="span"
          sx={{ width: "30%", color: "gray" }}
        >
          最後更新
        </Typography>
        <Typography
          className="device-info-contact"
          variant="h6"
          component="span"
          sx={{ width: "20%", color: "gray" }}
        >
          聯絡人
        </Typography>
        <Typography
          className="device-info-phone"
          variant="h6"
          component="span"
          sx={{ width: "20%", color: "gray" }}
        >
          聯絡電話
        </Typography>
        <Typography
          className="device-info-delete"
          variant="h6"
          component="span"
          sx={{ width: "10%", color: "gray" }}
        >
          刪除
        </Typography>
      </Box>
      <Box
        className="device-list-wrapper"
        sx={{ display: "flex", width: "100%", flexDirection: "column" }}
      >
        {devices.map((device: DeviceProps) => (
          <Box
            className="device-list-item"
            sx={{ display: "flex", width: "100%", alignItems: "center", height: "60px" }}
            borderBottom={1}
            borderColor="gray"
            key={device.deviceId + "-list"}
          >
            <Box sx={{ width: "30%", color: "gray", display: "flex" }}>
              <Box
                sx={{
                  height: "20px",
                  width: "20px",
                  borderRadius: 10,
                  mr: 1,
                  bgcolor:
                    device.status === "active"
                      ? "green"
                      : device.status === "warning"
                      ? "orange"
                      : "gray",
                }}
              />
              {device.deviceId}
            </Box>
            <Typography
              className="device-list-update"
              variant="h6"
              component="span"
              sx={{ width: "30%" }}
            >
              {device.latestDataTime}
            </Typography>
            <Typography
              className="device-list-contact"
              variant="h6"
              component="span"
              sx={{ width: "20%" }}
            >
              {device.contact}
            </Typography>
            <Typography
              className="device-list-phone"
              variant="h6"
              component="span"
              sx={{ width: "20%" }}
            >
              {device.contactPhone}
            </Typography>
            <Box
              className="device-list-delete"
              sx={{
                height: "10%",
                width: "10%",
                borderRadius: 10,
              }}
              onClick={() => {
                props.setSelectedDeviceId(device.deviceId)
                props.setOpenDialog(true)
              }}
            >
              <DeleteIcon color="disabled" />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
