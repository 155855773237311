import ReactDOM from "react-dom/client"
import App from "./App"
import { HashRouter, BrowserRouter } from "react-router-dom"
import { RecoilRoot } from "recoil"

const Router = process.env.REACT_APP_ROUTER_TYPE === "hash" ? HashRouter : BrowserRouter

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Router>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Router>
)
