import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import AccountCircle from "@mui/icons-material/AccountCircle"
import { DrawerMenu } from "@components/DrawerMenu"
import { userAuthSate } from "@core/atoms"
import { useRecoilState } from "recoil"
import { useNavigate } from "react-router-dom"

const packageJson = require("../../package.json")

const version = packageJson.version

export const NavBar = () => {
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [user, setUser] = useRecoilState(userAuthSate)

  const mode: string = process.env.REACT_APP_INDEX_COMPONENT as string

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenDrawer(true)
  }
  const handleLogout = () => {
    setUser(null)
    navigate("/")
  }

  return (
    <>
      <AppBar position="static" className="app-bar">
        <Toolbar>
          {user !== null && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ mr: 2 }}
              className="btn-drawer"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="mot-name">
            移動感測網路(MOT)展示平台 v{version}
          </Typography>
          {user !== null && mode === "login" && (
            <>
              <Typography variant="h6" component="div" sx={{ mr: 2 }} className="user-name">
                Hello, {user?.name}
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                sx={{ mr: 2 }}
                className="btn-portrait"
                onClick={handleLogout}
              >
                <AccountCircle />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <DrawerMenu open={openDrawer} onClose={() => setOpenDrawer(false)} />
    </>
  )
}
