import { useState, useEffect, useCallback } from "react"

const useKonamiCode = () => {
  const keySequence = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "KeyB",
    "KeyA",
  ]
  const initialState = Array(keySequence.length).fill(null)
  const [, setKeyPresses] = useState<string[]>(initialState)
  const [konamiCodeEntered, setKonamiCodeEntered] = useState(false)

  const resetKonamiCode = useCallback(() => setKonamiCodeEntered(false), [])

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      const { code } = e

      setKeyPresses(prevKeys => {
        const newKeys = [...prevKeys.slice(1), code]
        if (JSON.stringify(newKeys) === JSON.stringify(keySequence)) {
          setKonamiCodeEntered(true)
          return initialState
        }
        return newKeys
      })
    }

    window.addEventListener("keydown", keyHandler)

    return () => window.removeEventListener("keydown", keyHandler)
  }, [])

  return [konamiCodeEntered, resetKonamiCode] as const
}

export default useKonamiCode
