import React, { useRef, MouseEvent, useEffect } from "react"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { useNavigate } from "react-router-dom"
import { SxProps } from "@mui/system"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
  sensorBoardState,
  filterDateState,
  openFilterState,
  userAuthSate,
  countyMappingState,
  filterProjectState,
  filterCountyState,
  deviceStatusState,
  filterGroupButtonState,
  projectDetailState,
  deviceSelectorState,
  selectedDeviceState,
  SelectedDeviceProps,
  DeviceSelectorProps,
} from "@core/atoms"
import { filteredDeviceSelectorState, countyOptionState } from "@core/selectors"
import { fetchCountiesAPI, fetchAllDevicesAPI, fetchProjectsAPI } from "@core/API"
import moment from "moment"
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  Chip,
  Button,
  Grid,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material"
import { useQuery } from "react-query"

type MultiSelectorProps = {
  options: Array<{ name: string; id: string }>
  label: string
  value: Array<string>
  setvalue: Function
  className?: string
  sx?: SxProps
}

type FilterProps = {
  open: boolean
  sx?: SxProps
}

type DeviceItemProps = {
  device_id: string
  latestDataTime: string
  status: string
}

const MultiSelector = (props: MultiSelectorProps) => {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 15
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
  }

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const {
      target: { value },
    } = event

    props.setvalue(typeof value === "string" ? value.split(",") : value)
  }

  return (
    <FormControl {...props}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        multiple
        value={props.value || undefined}
        defaultValue={undefined}
        onChange={handleChange}
        input={<OutlinedInput label={props.label} />}
        renderValue={selected => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={JSON.parse(value).id} label={JSON.parse(value).name} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {props.options.map(option => (
          <MenuItem key={option.id} value={JSON.stringify(option)}>
            <Checkbox checked={props.value.indexOf(JSON.stringify(option)) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const SensorStatus = (props: any) => {
  const [status, setStatus] = useRecoilState(deviceStatusState)

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value)
  }
  return (
    <FormControl sx={{ m: 1, width: "90%" }} {...props}>
      <InputLabel>裝置狀態</InputLabel>
      <Select value={status} label="裝置狀態" onChange={handleChange}>
        <MenuItem value="all">所有狀態</MenuItem>
        <MenuItem value="active">正常運作</MenuItem>
        <MenuItem value="warning">待機中</MenuItem>
        <MenuItem value="deactivate">關機中</MenuItem>
      </Select>
    </FormControl>
  )
}

const DatePickers = (props: { [index: string]: string }) => {
  const [date, setDate] = useRecoilState(filterDateState)

  const handleChange = (newValue: Date | null) => {
    setDate({ ...date, [props.type]: moment(newValue).format("YYYY-MM-DD") })
  }
  return (
    <Box className={props.className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={props.label}
          inputFormat="yyyy-MM-dd"
          value={moment(date[props.type as "startDate" | "endDate"], "YYYY-MM-DD")}
          onChange={handleChange}
          renderInput={params => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  )
}

const GroupButtons = (props: any) => {
  const [display, setDisplay] = useRecoilState(filterGroupButtonState)
  const resetStatus = useResetRecoilState(deviceStatusState)
  const handleChange = (event: MouseEvent<HTMLElement>, newAlignment: string) => {
    setDisplay(newAlignment)
    resetStatus()
  }
  return (
    <ToggleButtonGroup
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
      color="primary"
      value={display}
      onChange={handleChange}
      exclusive
      {...props}
    >
      <ToggleButton value="即時" sx={{ width: "40%" }} className="btn-group">
        即時
      </ToggleButton>
      <ToggleButton value="日期" sx={{ width: "40%" }} className="btn-group">
        日期
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const DeviceSelectorCell = React.memo((props: DeviceSelectorProps) => {
  const [selectedDevices, setSelectedDevices] = useRecoilState(selectedDeviceState)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!selectedDevices.hasOwnProperty(props.device_id))
      setSelectedDevices(prev => ({
        ...prev,
        [props.device_id]: {
          device_id: props.device_id,
          status: props.status,
        },
      }))
    else
      setSelectedDevices(prev =>
        Object.fromEntries(Object.entries(prev).filter(([key]) => !key.includes(props.device_id)))
      )
  }

  return (
    <Grid container className="result-item" onClick={handleClick}>
      <Grid item xs={2}>
        <IconButton aria-label="delete" className="btn-checker">
          {selectedDevices.hasOwnProperty(props.device_id) ? (
            <CheckCircleIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </IconButton>
      </Grid>
      {props.display === "即時" && (
        <Grid item xs={2} className="text-status">
          {props.status === "active" ? (
            <Typography variant="h6" component="span" sx={{ color: "green" }}>
              正常
            </Typography>
          ) : props.status === "warning" ? (
            <Typography variant="h6" component="span" sx={{ color: "orange" }}>
              待機
            </Typography>
          ) : (
            <Typography variant="h6" component="span" sx={{ color: "gray" }}>
              關機
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={8} className="text-device-id">
        <Typography variant="h6" component="span">
          {props.device_id}
        </Typography>
      </Grid>
    </Grid>
  )
})

const DeviceSelector = () => {
  const [selectedDevice, setSelectedDevice] = useRecoilState(selectedDeviceState)
  const [, setSensorBoardDevices] = useRecoilState(sensorBoardState)
  const [, setOpenFilter] = useRecoilState(openFilterState)

  const filteredDevices = useRecoilValue(filteredDeviceSelectorState)
  const display = useRecoilValue(filterGroupButtonState)

  const handleClick = () => {
    if (Object.keys(selectedDevice).length === 0) {
      alert("沒有選擇任何裝置…")
      return
    }
    setOpenFilter(false)
    let switches: { [index: string]: boolean } = {}
    let status: { [index: string]: string } = {}
    Object.values(selectedDevice).forEach(device => {
      switches[device.device_id] = true
      status[device.device_id] = device.status
    })

    setSensorBoardDevices({
      displayType: display,
      loading: false,
      devices: Object.keys(selectedDevice),
      switches: switches,
      status: status,
    })
  }

  const handleSelectAll = (event: React.MouseEvent<HTMLElement>) => {
    if (Object.keys(selectedDevice).length !== filteredDevices.length) {
      let selectedDevices: {
        [index: string]: SelectedDeviceProps
      } = {}
      filteredDevices.forEach((device: DeviceSelectorProps) => {
        selectedDevices[device.device_id] = {
          device_id: device.device_id,
          status: device.status,
        }
      })
      setSelectedDevice(selectedDevices)
    } else setSelectedDevice({})
  }

  useEffect(() => {
    setSelectedDevice({})
  }, [filteredDevices])

  return (
    <Box className="device-selector">
      <Box sx={{ display: "flex", justifyContent: "space-between" }} className="result-banner">
        <Typography variant="h6" component="span" className="result-title">
          找到共{filteredDevices.length}個感測器，選取
          {Object.keys(selectedDevice).length}顆
        </Typography>
        <Button onClick={handleClick} className="btn-add">
          選擇
        </Button>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} className="divider" />
      {filteredDevices.length > 0 ? (
        <Box sx={{ height: "700px", overflowY: "auto" }} className="result-container">
          <Grid container className="col-title">
            <Grid item xs={2} className="col-select">
              <IconButton aria-label="delete" onClick={handleSelectAll} className="btn-select-all">
                {Object.keys(selectedDevice).length === filteredDevices.length ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </IconButton>
            </Grid>
            {display === "即時" && (
              <Grid item xs={2} className="col-status">
                狀態
              </Grid>
            )}
            <Grid item xs={8} className="col-device">
              感測器
            </Grid>
          </Grid>
          {filteredDevices.map((sensor: DeviceSelectorProps) => (
            <DeviceSelectorCell
              status={sensor.status}
              device_id={sensor.device_id}
              display={display}
              key={sensor.device_id}
            />
          ))}
        </Box>
      ) : (
        <Typography variant="h6" component="span" className="message-display">
          沒有找到符合的裝置
        </Typography>
      )}
    </Box>
  )
}

export const Filter = (props: FilterProps) => {
  const navigate = useNavigate()

  const [user, setUser] = useRecoilState(userAuthSate)
  const [project, setProject] = useRecoilState(filterProjectState)
  const [county, setCounty] = useRecoilState(filterCountyState)
  const [, setDevice] = useRecoilState(deviceSelectorState)
  const [, setCountyMapping] = useRecoilState(countyMappingState)
  const [projectDevices, setProjectDevices] = useRecoilState(projectDetailState)

  const display = useRecoilValue(filterGroupButtonState)
  const dates = useRecoilValue(filterDateState)
  const counties = useRecoilValue(countyOptionState)

  const { refetch: refetchCounties } = useQuery("fetch-couties-api", {
    queryFn: () => fetchCountiesAPI(user),
    onSuccess: (res: any) => {
      setCountyMapping(Object.values(res.data))
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  const { isLoading: isLoadingDevices } = useQuery("fetch-all-devices-api", {
    queryFn: () => fetchAllDevicesAPI(user, null),
    onSuccess: (res: any) => {
      setDevice(
        res.data.sort(
          (a: DeviceItemProps, b: DeviceItemProps) => parseInt(a.device_id) - parseInt(b.device_id)
        )
      )
    },
    onError: (error: any) => {
      if (error.response.statusText === "Unauthorized") {
        setUser(null)
        navigate("/")
      }
    },
  })

  let params = useRef({})
  const { refetch: refetchProjects } = useQuery("fetch-projects-api", {
    queryFn: () => fetchProjectsAPI(user, params.current),
    onSuccess: (res: any) => {
      setProjectDevices(res.data)
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  useEffect(() => {
    if (display === "即時") params.current = { only_open: true }
    else params.current = { date_range_begin: dates.startDate, date_range_end: dates.endDate }
    refetchProjects()
  }, [display])

  useEffect(() => {
    if (display === "即時") return
    params.current = { date_range_begin: dates.startDate, date_range_end: dates.endDate }
    refetchProjects()
  }, [dates])

  useEffect(() => {
    refetchCounties()
  }, [])

  return props.open ? (
    <Grid
      container
      sx={{
        bgcolor: "background.paper",
        borderRadius: "12px",
      }}
      className="search-container"
    >
      <Grid
        item
        xs={5}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          borderRadius: "12px 0 0 12px",
          padding: 2,
        }}
        className="search-filter"
      >
        <GroupButtons className="filter-item" />
        {display === "日期" && (
          <>
            <DatePickers className="filter-item" label="開始日期" type="startDate" />
            <DatePickers className="filter-item" label="結束日期" type="endDate" />
          </>
        )}
        <MultiSelector
          sx={{ m: 1, width: "90%" }}
          options={projectDevices.map((item: any) => ({
            name: item.projectName,
            id: item.projectId,
          }))}
          value={project}
          setvalue={setProject}
          label="選擇專案"
          className="filter-item"
        />
        <MultiSelector
          sx={{ m: 1, width: "90%" }}
          options={counties.map((county: any) => ({ name: county, id: county }))}
          value={county}
          setvalue={setCounty}
          label="選擇縣市"
          className="filter-item"
        />
        {display === "即時" && <SensorStatus className="filter-item" />}
      </Grid>
      <Grid
        item
        xs={7}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          padding: 2,
          borderRadius: "12px",
        }}
        className={`search-result ${display === "日期" ? "for-date" : ""}`}
      >
        {isLoadingDevices ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress sx={{ alignSelf: "center", mt: 15 }} />
          </Box>
        ) : (
          <DeviceSelector />
        )}
      </Grid>
    </Grid>
  ) : null
}
