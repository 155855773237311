import Plot from "react-plotly.js"
import { SxProps } from "@mui/system"
import { useRecoilValue } from "recoil"
import { Box, Typography } from "@mui/material"
import { weeklyChartState, hourlyChartState } from "@core/atoms"

type ChartsProps = {
  sx?: SxProps
  className?: string
  showCharts: boolean
  setShowCharts: Function
}

export const Charts = (props: ChartsProps) => {
  const { sx, className, showCharts, setShowCharts } = props
  const weeklyChart = useRecoilValue(weeklyChartState)
  const hourlyChart = useRecoilValue(hourlyChartState)

  const handleClose = () => {
    setShowCharts(false)
  }

  return Object.keys(weeklyChart).length && Object.keys(hourlyChart).length ? (
    <Box sx={sx} className={showCharts ? `show ${className}` : `${className}`}>
      <button className="button-close-search" onClick={handleClose}></button>
      <Box className="chart-cell" sx={{ width: "90%", alignItems: "center", m: 2, mb: 0 }}>
        <Typography className="chart-title" variant="h6" component="div" sx={{ width: "100%" }}>
          週間分析
        </Typography>
        <Plot
          className="chart-content"
          data={[
            {
              x: Object.keys(weeklyChart),
              y: Object.values(weeklyChart),
              type: "bar",
            },
          ]}
          config={{ displayModeBar: false }}
          layout={{
            showlegend: false,
            height: 250,
            margin: {
              l: 30,
              r: 30,
              b: 20,
              t: 0,
            },
          }}
          style={{ width: "100%" }}
        />
      </Box>
      <Box className="chart-cell" sx={{ width: "90%", alignItems: "center", m: 2 }}>
        <Typography className="chart-title" variant="h6" component="div" sx={{ width: "100%" }}>
          時段分析
        </Typography>
        <Plot
          className="chart-content"
          data={[
            {
              x: Object.keys(hourlyChart),
              y: Object.values(hourlyChart),
              type: "bar",
            },
          ]}
          config={{ displayModeBar: false }}
          layout={{
            showlegend: false,
            xaxis: {
              nticks: 24,
              fixedrange: true,
            },
            yaxis: {
              fixedrange: true,
            },
            height: 250,
            margin: {
              l: 30,
              r: 30,
              b: 20,
              t: 0,
            },
          }}
          style={{ width: "100%" }}
        />
      </Box>
    </Box>
  ) : null
}
