import { useState, useEffect } from "react"
import { Box, Typography, Switch } from "@mui/material"
import { useRecoilValue, useRecoilState } from "recoil"
import {
  ScaleProps,
  colorScaleSwitchState,
  selectedTextItemState,
  absoluteColorScaleState,
  colorScaleState,
} from "@core/atoms"
import { relativeColorScaleState } from "@core/selectors"
import { fetchAbsoluteColorScaleAPI } from "@core/API"
import { SxProps } from "@mui/system"
import { useQuery } from "react-query"

type LevelBarProps = {
  sx?: SxProps
  className?: string
}

export const LevelBar = (props: LevelBarProps) => {
  const relativeColorScale = useRecoilValue(relativeColorScaleState)
  const selectedTextItem = useRecoilValue(selectedTextItemState)

  const [colorScaleSwitch, setColorScaleSwitch] = useRecoilState(colorScaleSwitchState)
  const [absoluteColorScale, setAbsoluteColorScale] = useRecoilState(absoluteColorScaleState)
  const [colorScale, setColorScale] = useRecoilState(colorScaleState)

  const [checked, setChecked] = useState(true)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setColorScale(relativeColorScale)
    else {
      if (absoluteColorScale[selectedTextItem.queryName] === undefined) {
        alert(`沒有測項${selectedTextItem.name}的絕對色階`)
        event.preventDefault()
        return
      } else setColorScale(absoluteColorScale[selectedTextItem.queryName])
    }
    setChecked(event.target.checked)
    setColorScaleSwitch(event.target.checked ? "相對" : "絕對")
  }

  const { refetch: refetchAbsoluteColorScale } = useQuery("fetch-absolute-color-scale-api", {
    queryFn: () => fetchAbsoluteColorScaleAPI(),
    onSuccess: res => {
      setAbsoluteColorScale(res.data)
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
    // cacheTime: 1800000,
    // staleTime: 1800000,
  })

  useEffect(() => {
    if (colorScaleSwitch === "相對") setColorScale(relativeColorScale)
  }, [relativeColorScale])

  useEffect(() => {
    setChecked(true)
  }, [selectedTextItem])

  useEffect(() => {
    refetchAbsoluteColorScale()
  }, [])

  return (
    <Box className={props.className}>
      <Box
        sx={{
          width: "10px",
          display: "flex",
          flexDirection: "column-reverse",
          height: "240px",
        }}
      >
        {colorScale.map((color, index) => (
          <Box
            className="levle-cell"
            key={`color-bar-${index}`}
            sx={{ display: "flex", flex: "1", bgcolor: color.color, height: "20px" }}
          >
            <Typography
              className="levle-value"
              variant="h6"
              component="span"
              sx={{ position: "relative", left: 15, bottom: -20, fontSize: 14 }}
            >
              {color.value}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{ mt: 2, display: "flex", flexDirection: "column" }}
        className="level-bar-switch-container"
      >
        <Switch checked={checked} onChange={handleChange} className="level-bar-switch" />
        <Typography variant="h6" component="span" sx={{ fontSize: 14 }} className="level-bar-label">
          {checked ? "相對" : "絕對"}
        </Typography>
      </Box>
    </Box>
  )
}
