import { useState, useEffect, useRef } from "react"
import { Box } from "@mui/material"
import { useRecoilState, useRecoilValue } from "recoil"
import Draggable, { DraggableEvent, DraggableData } from "react-draggable"
import {
  datetimePickerState,
  progressDisplayTimeState,
  sensorBoardState,
  motBufferState,
  motJsonDataState,
  streamingState,
  selectedTextItemState,
  colorScaleSwitchState,
} from "@core/atoms"
import "@css/timeline.css"
import moment from "moment"

export const Timeline = () => {
  const sensorBoard = useRecoilValue(sensorBoardState)
  const pickerTime = useRecoilValue(datetimePickerState)
  const selectedTextItem = useRecoilValue(selectedTextItemState)

  const [streaming, setStreaming] = useRecoilState(streamingState)
  const [bufferData, setBufferData] = useRecoilState(motBufferState)
  const [displayTimestamp, setDisplayTimestamp] = useRecoilState(progressDisplayTimeState)
  const [motJsonData, setMotJsonData] = useRecoilState(motJsonDataState)
  const [colorScaleSwitch, setColorScaleSwitch] = useRecoilState(colorScaleSwitchState)

  const [isPlay, setIsPlay] = useState(false)
  const [pointPosition, setPointPosition] = useState(streaming ? 100 : 0)
  const [barPosition, setBarPosition] = useState(streaming ? 100 : 0)
  const [timestampGap, setTimestampGap] = useState(0)
  const [parentWidth, setParentWidth] = useState(0)
  const [progressTime, setProgressTime] = useState({
    start: pickerTime.start,
    end: pickerTime.end,
  })

  const isPlayRef = useRef(isPlay)
  const barPositionRef = useRef(barPosition)
  const pointPositionRef = useRef(pointPosition)
  const bufferDataRef = useRef(bufferData)
  const motJsonDataRef = useRef(motJsonData)
  const displayTimestampRef = useRef(displayTimestamp)
  const parentRef = useRef<HTMLElement>(null)
  const parentMargin = 12
  const oneTick = 0.25
  isPlayRef.current = isPlay
  barPositionRef.current = barPosition
  pointPositionRef.current = pointPosition
  bufferDataRef.current = bufferData
  motJsonDataRef.current = motJsonData
  displayTimestampRef.current = displayTimestamp

  const handleStream = () => {
    setIsPlay(false)
    if (!streaming) {
      setPointPosition(100)
      setBarPosition(100)
    }
    setStreaming(!streaming)
  }

  const setPlayProgress = () => {
    setBarPosition(() => {
      if (barPositionRef.current + oneTick >= 100) {
        setIsPlay(false)
        return 100
      }
      return barPositionRef.current + oneTick
    })
    setPointPosition(() => {
      if (barPositionRef.current + oneTick >= 100) {
        setIsPlay(false)
        return 100
      }
      return barPositionRef.current + oneTick
    })
  }

  const play = () => {
    if (isPlayRef.current) {
      if (barPositionRef.current >= 100) return
      setPlayProgress()
      setTimeout(() => {
        play()
      }, 35)
    }
  }

  const handleClick = () => {
    if (barPositionRef.current >= 100) {
      setPointPosition(0)
      setBarPosition(0)
    }
    setIsPlay(!isPlay)
    setStreaming(false)
  }

  const updateProgressTime = () => {
    setProgressTime({
      ...progressTime,
      end: moment().toDate(),
    })
    setTimestampGap(Math.round((moment().unix() - moment(progressTime.start).unix()) / 200))
  }

  useEffect(() => {
    if (!streaming) return
    let showTimeInterval = setInterval(() => {
      // console.log("timestamp interval")
      setDisplayTimestamp(moment().unix())
      updateProgressTime()
    }, 1000)
    let bufferInterval = setInterval(() => {
      // console.log("buffer interval")
      setMotJsonData([...motJsonDataRef.current, ...bufferDataRef.current])
      setBufferData([])
    }, 50000)

    return () => {
      clearInterval(showTimeInterval)
      clearInterval(bufferInterval)
    }
  }, [streaming])

  useEffect(() => {
    play()
  }, [isPlay])

  useEffect(() => {
    setIsPlay(false)
    setColorScaleSwitch("相對")
  }, [selectedTextItem])

  useEffect(() => {
    setProgressTime({
      start: pickerTime.start,
      end: pickerTime.end,
    })
    setTimestampGap(
      Math.round((moment(pickerTime.end).unix() - moment(pickerTime.start).unix()) / 200)
    )
    setDisplayTimestamp(
      sensorBoard.displayType === "即時"
        ? moment(pickerTime.end).unix()
        : moment(pickerTime.start).unix()
    )
    if (sensorBoard.displayType !== "即時") {
      setBarPosition(0)
      setPointPosition(0)
    }
  }, [pickerTime])

  useEffect(() => {
    if (barPosition <= 0) setDisplayTimestamp(moment(progressTime.start).unix())
    else if (barPosition >= 100) setDisplayTimestamp(moment(progressTime.end).unix())
    else setDisplayTimestamp(moment(progressTime.start).unix() + timestampGap * barPosition * 2)
  }, [barPosition])

  useEffect(() => {
    setPointPosition(sensorBoard.displayType === "即時" ? 100 : 0)
    setBarPosition(sensorBoard.displayType === "即時" ? 100 : 0)
    setStreaming(sensorBoard.displayType === "即時")
  }, [])

  useEffect(() => {
    const handleResize = () =>
      setParentWidth((parentRef.current as HTMLElement).offsetWidth - parentMargin)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    setParentWidth((parentRef.current as HTMLElement).offsetWidth - parentMargin)
  }, [])

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    let nextPosition = Math.round((data.x / parentWidth) * 100)

    if (nextPosition < 0) nextPosition = 0
    else if (nextPosition > 100) nextPosition = 100
    barPositionRef.current = nextPosition
    setBarPosition(nextPosition)
  }
  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    let nextPosition = Math.round((data.x / parentWidth) * 100)

    if (nextPosition < 0) nextPosition = 0
    else if (nextPosition > 100) nextPosition = 100
    pointPositionRef.current = nextPosition
    barPositionRef.current = nextPosition
    setPointPosition(nextPosition)
    setBarPosition(nextPosition)
  }

  return (
    <Box className={`timeline-container ${streaming ? "live" : ""}`}>
      {sensorBoard.displayType === "即時" && (
        <div className="vswitch " onClick={handleStream}>
          <span>時段</span>
          <span>直播</span>
        </div>
      )}
      <Box className="left" onClick={handleClick}>
        <Box className={isPlay ? "btn-tl pause" : "btn-tl play"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="play"
            width="15.062"
            height="21.707"
            viewBox="0 0 15.062 21.707"
          >
            <path
              id="Polygon_23"
              data-name="Polygon 23"
              d="M10.853,0,21.707,15.062H0Z"
              transform="translate(15.062) rotate(90)"
              fill="#0066ec"
            ></path>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="pause"
            width="15"
            height="17"
            viewBox="0 0 15 17"
          >
            <path
              id="Union_13"
              data-name="Union 13"
              d="M10,17V0h5V17ZM0,17V0H5V17Z"
              fill="#0066ec"
            ></path>
          </svg>
        </Box>
      </Box>
      <Box className="right">
        <Box className="tl-bar" ref={parentRef}>
          <Box className="drag-bar" sx={{ width: `${barPosition}%` }}></Box>
          <Draggable
            axis="x"
            bounds="parent"
            disabled={isPlay}
            onStart={() => setStreaming(false)}
            onDrag={handleDrag}
            onStop={handleStop}
            defaultPosition={{ x: -5, y: -5 }}
            position={{ x: (pointPosition * parentWidth) / 100, y: -5 }}
          >
            <Box className="keypoint">
              <Box className="time">
                <span>{moment.unix(displayTimestampRef.current).format("YYYY-MM-DD")}</span>
                <span className="bold">
                  {moment.unix(displayTimestampRef.current).format("HH:mm")}
                </span>
              </Box>
            </Box>
          </Draggable>
        </Box>
        <Box className="tl-info">
          <Box className="start">{moment(progressTime.start).format("HH:mm")}</Box>
          <Box className="end">{moment(progressTime.end).format("HH:mm")}</Box>
        </Box>
      </Box>
    </Box>
  )
}
