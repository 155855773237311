import React, { useState, useEffect, useRef } from "react"
import {
  Box,
  Typography,
  Chip,
  Button,
  Tabs,
  Tab,
  Backdrop,
  TextField,
  Radio,
  MenuItem,
  FormControl,
  IconButton,
  Autocomplete,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { ProjectCellProps, displayBarState, deviceListState, userAuthSate } from "@core/atoms"
import {
  fetchMotDevicesAPI,
  fetchMyProjectDevicesAPI,
  postMyProjectDevicesAPI,
  importDevicesToProjectAPI,
  deleteMyProjectDevicesAPI,
} from "@core/API"
import { idsOfDeviceListState } from "@core/selectors"
import { useQuery } from "react-query"
import UploadIcon from "@mui/icons-material/Upload"
import GridViewIcon from "@mui/icons-material/GridView"
import MenuIcon from "@mui/icons-material/Menu"
import SearchIcon from "@mui/icons-material/Search"
import { atom, useRecoilState, useRecoilValue } from "recoil"
import { DeviceBlock } from "@projects/DeviceBlock"
import { DeviceList } from "@projects/DeviceList"
import { ProjectStatistic } from "@projects/ProjectStatistic"
import { ProjectProfile } from "@projects/ProjectProfile"
import AlertDialog from "@components/AlertDialog"
import moment from "moment"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

type deviceInfoPorps = {
  project_id: string
  device_id: string
  contact: string
  gender: string
  contact_phone: string
}

type DeviceStatusProps = {
  device_id: string
  status: string
}

const backDropState = atom({
  key: "backDropState",
  default: false,
})

const DisplayBar = () => {
  const ids = useRecoilValue(idsOfDeviceListState)
  const [display, setDisplay] = useRecoilState(displayBarState)
  const handleChange = (
    e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setDisplay({ ...display, [type]: e.target.value })
  }
  const handleKeyword = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value === null) setDisplay({ ...display, ["keyword"]: "" })
    else setDisplay({ ...display, ["keyword"]: value })
  }
  const handleClick = (e: React.MouseEvent, type: string) => {
    setDisplay({ ...display, ["type"]: type })
  }
  return (
    <Box
      className="device-display-bar"
      sx={{ display: "flex", justifyContent: "space-between", mt: 3, mb: 3 }}
    >
      <Box
        className="device-sorting-options"
        sx={{ width: "85%", display: "flex", alignItems: "center" }}
      >
        <FormControl className="sorting-option-item" sx={{ width: "31%", mr: 2 }}>
          {/* <InputLabel>裝置狀態</InputLabel> */}
          <Select
            sx={{ borderRadius: "40px", pl: 2 }}
            // label="裝置狀態"
            value={display.status}
            onChange={(e: SelectChangeEvent) => handleChange(e, "status")}
          >
            <MenuItem value="all">所有狀態</MenuItem>
            <MenuItem value="active">正常運作</MenuItem>
            <MenuItem value="warning">待機中</MenuItem>
            <MenuItem value="deactivate">已關機</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="sorting-option-item" sx={{ width: "31%", mr: 2 }}>
          {/* <InputLabel>排序</InputLabel> */}
          <Select
            sx={{ borderRadius: "40px", pl: 2 }}
            // label="排序"
            value={display.order}
            onChange={(e: SelectChangeEvent) => handleChange(e, "order")}
          >
            <MenuItem value="descending">更新時間新 &gt; 舊</MenuItem>
            <MenuItem value="ascending">更新時間舊 &gt; 新</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          freeSolo
          disablePortal
          options={ids}
          className="sorting-option-item"
          sx={{ width: "31%" }}
          disableClearable
          onChange={handleKeyword}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="輸入感測器ID"
              variant="outlined"
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "keyword")}
              InputProps={{
                ...params.InputProps,
                endAdornment: <SearchIcon sx={{ color: "primary.main", mr: "5px" }} />,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",

                  legend: {
                    marginLeft: "30px",
                  },
                },
                "& .MuiAutocomplete-inputRoot": {
                  paddingLeft: "20px !important",
                  borderRadius: "50px",
                },
                "& .MuiInputLabel-outlined": {
                  paddingLeft: "20px",
                },
                "& .MuiInputLabel-shrink": {
                  marginLeft: "20px",
                  paddingLeft: "10px",
                  paddingRight: 0,
                  background: "white",
                },
              }}
            />
          )}
        />
      </Box>
      <Box className="device-display-options" sx={{ display: "flex", width: "10%" }}>
        <IconButton
          className="display-option-item"
          color={display.type === "grid" ? "primary" : "default"}
          component="label"
          onClick={(e: React.MouseEvent) => handleClick(e, "grid")}
        >
          <GridViewIcon />
        </IconButton>
        <IconButton
          className="display-option-item"
          color={display.type === "list" ? "primary" : "default"}
          component="label"
          onClick={(e: React.MouseEvent) => handleClick(e, "list")}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const AddNewDevice = (props: any) => {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userAuthSate)
  const [deviceList, setDeviceLst] = useRecoilState(deviceListState)
  const [openBackdrop, setOpenBackdrop] = useRecoilState(backDropState)
  const [allDevices, setAllDevices] = useState<Array<DeviceStatusProps>>([])
  const [deviceInfo, setDeviceInfo] = useState<deviceInfoPorps>({
    project_id: props.projectId,
    device_id: "",
    contact: "",
    gender: "",
    contact_phone: "",
  })
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [error, setError] = useState({
    device_id: false,
  })
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    setDeviceInfo({ ...deviceInfo, [type]: e.target.value })
    setError({ ...error, [type]: false })
  }
  const handleDeviceId = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    setAutocompleteValue(value)
    if (value === null) setDeviceInfo({ ...deviceInfo, ["device_id"]: "" })
    else setDeviceInfo({ ...deviceInfo, ["device_id"]: value.device_id })
    setError({ ...error, ["device_id"]: false })
  }
  const handleCancel = () => {
    setAutocompleteValue(null)
    setDeviceInfo({
      project_id: props.projectId,
      device_id: "",
      contact: "",
      gender: "",
      contact_phone: "",
    })
    setOpenBackdrop(false)
  }

  let bodyFormData: FormData
  const { refetch: postMyProjectDevices } = useQuery("post-my-project-devices-api", {
    queryFn: () => postMyProjectDevicesAPI(user, bodyFormData),
    onSuccess: (res: any) => {
      console.log(res)
      if (res.data.isSuccess) {
        const selected = allDevices.filter((d: any) => d.device_id === deviceInfo.device_id)[0]
        setDeviceLst([
          {
            status: selected.status,
            latestDataTime: res.data.result.latestDataTime,
            deviceId: deviceInfo.device_id as string,
            contact: deviceInfo.contact,
            contactPhone: deviceInfo.contact_phone,
            gender: deviceInfo.gender,
            createDate: res.data.result.createDate,
          },
          ...deviceList,
        ])
        alert("新增成功！")
        handleCancel()
      }
    },
    onError: (error: any) => {
      if (error.response.statusText === "Unauthorized") {
        setUser(null)
        navigate("/")
      }
    },
    enabled: false,
  })

  const handleSubmit = () => {
    if (deviceInfo.device_id?.trim() === "") {
      setError({ ...error, device_id: true })
      return
    }

    bodyFormData = new FormData()
    for (const [key, value] of Object.entries(deviceInfo))
      bodyFormData.append(key, (value as string).trim())

    postMyProjectDevices()
  }

  const { refetch: refetchMotDevices } = useQuery("fetch-mot-devices-api", {
    queryFn: () => fetchMotDevicesAPI(user),
    onSuccess: (res: any) => {
      setAllDevices(res.data)
    },
    onError: (error: any) => {
      if (error.response.statusText === "Unauthorized") {
        setUser(null)
        navigate("/")
      }
    },
    enabled: false,
  })

  useEffect(() => {
    refetchMotDevices()
  }, [])

  return (
    <Box
      className="add-device-wrapper"
      sx={{
        width: "50%",
        height: "70%",
        bgcolor: "#f6f6f6",
        borderRadius: "7px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      onClick={e => e.stopPropagation()}
    >
      <Box className="add-device-form" sx={{ width: "80%", height: "75%", mt: "10%" }}>
        <Box className="device-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="device-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%", color: "#000000" }}
          >
            感測器編號
          </Typography>
          <Autocomplete
            className="device-form-content"
            value={autocompleteValue}
            disablePortal
            options={allDevices}
            isOptionEqualToValue={(option: DeviceStatusProps, value: DeviceStatusProps) =>
              option.device_id === value.device_id
            }
            getOptionLabel={(option: DeviceStatusProps) => option.device_id}
            getOptionDisabled={option =>
              deviceList.find(d => d.deviceId === option.device_id) !== undefined
            }
            sx={{ width: "80%" }}
            onChange={handleDeviceId}
            renderInput={params => (
              <TextField
                {...params}
                error={error.device_id}
                helperText={error.device_id ? "感測器編號不可為空白" : " "}
              />
            )}
          />
        </Box>
        <Box className="device-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="device-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%", color: "#000000" }}
          >
            聯絡人
          </Typography>
          <Box className="device-form-content" sx={{ width: "80%", display: "flex" }}>
            <TextField
              className="device-form-name"
              variant="outlined"
              sx={{ width: "35%" }}
              value={deviceInfo.contact}
              helperText=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "contact")}
            />
            <Box className="device-gender-container" sx={{ display: "flex", width: "30%" }}>
              <Box
                className="device-gender-warpper"
                sx={{ display: "flex", width: "50%", alignItems: "center" }}
              >
                <Radio
                  className="device-gender-radio"
                  checked={deviceInfo.gender === "female"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextChange(e, "gender")
                  }
                  value="female"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography
                  className="device-gender-text"
                  variant="h6"
                  component="span"
                  sx={{ width: "50%", color: "#000000" }}
                >
                  女士
                </Typography>
              </Box>
              <Box
                className="device-gender-warpper"
                sx={{ display: "flex", width: "50%", alignItems: "center" }}
              >
                <Radio
                  className="device-gender-radio"
                  checked={deviceInfo.gender === "male"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextChange(e, "gender")
                  }
                  value="male"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography
                  className="device-gender-text"
                  variant="h6"
                  component="span"
                  sx={{ width: "50%", color: "#000000" }}
                >
                  先生
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="device-form-item" sx={{ width: "100%", display: "flex" }}>
          <Typography
            className="device-form-title"
            variant="h6"
            component="span"
            sx={{ width: "20%", color: "#000000" }}
          >
            聯絡電話
          </Typography>
          <TextField
            className="device-form-content"
            variant="outlined"
            sx={{ width: "80%" }}
            value={deviceInfo.contact_phone}
            helperText=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, "contact_phone")
            }
          />
        </Box>
      </Box>
      <Box
        className="add-device-options"
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button className="add-device-button" variant="outlined" onClick={handleCancel}>
          取消
        </Button>
        <Button className="add-device-button" variant="contained" onClick={handleSubmit}>
          送出
        </Button>
      </Box>
    </Box>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      className="info-tab-panel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ height: "100%" }}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const ProjectDetail = (props: { data: ProjectCellProps }) => {
  const formDataRef = useRef<FormData>(new FormData())

  const navigate = useNavigate()
  const display = useRecoilValue(displayBarState)
  const [user, setUser] = useRecoilState(userAuthSate)
  const [openBackdrop, setOpenBackdrop] = useRecoilState(backDropState)
  const [deviceList, setDeviceLst] = useRecoilState(deviceListState)
  const [tabValue, setTabValue] = React.useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState("")

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      formDataRef.current = new FormData()
      formDataRef.current.append("project_id", props.data.projectId as string)
      formDataRef.current.append("file", event.target.files[0])
      importDevices()
      event.target.value = ""
    }
  }

  const redirectToLogin = (error: any) => {
    if (error.response.statusText === "Unauthorized") {
      setUser(null)
      navigate("/")
    }
  }

  const { refetch: importDevices } = useQuery("import-devices-to-project-api", {
    queryFn: () => importDevicesToProjectAPI(user, formDataRef.current),
    onSuccess: (res: any) => {
      if (res.data.isSuccess) {
        let msg = ""
        const fails =
          res.data.result.duplicated + res.data.result.missing_id + res.data.result.not_exists
        if (res.data.result.success > 0) msg += `已成功新增${res.data.result.success}顆感測器！\n`
        if (fails > 0) msg += `\n並發生以下${fails}件失敗：\n`
        if (res.data.result.duplicated > 0)
          msg += `．${res.data.result.duplicated}顆感測器ID重複。\n`
        if (res.data.result.missing_id > 0)
          msg += `．${res.data.result.missing_id}顆感測器缺少ID。\n`
        if (res.data.result.not_exists > 0)
          msg += `．${res.data.result.not_exists}顆感測器不存在。\n`

        refetchDeviceList()
        alert(msg)
      } else alert(res.data.result)
    },
    onError: (error: any) => {
      alert(error.response)
      redirectToLogin(error)
    },
    enabled: false,
  })
  const { refetch: deleteProjectDevice } = useQuery("delete-my-project-devices-api", {
    queryFn: () => deleteMyProjectDevicesAPI(user, formDataRef.current),
    onSuccess: (res: any) => {
      alert("刪除成功！")
      setDeviceLst(prev => prev.filter(d => d.deviceId !== selectedDeviceId))
    },
    onError: (error: any) => {
      alert(error.response)
      redirectToLogin(error)
    },
    enabled: false,
  })

  const handleDeleteDevice = () => {
    formDataRef.current = new FormData()
    formDataRef.current.append("projectId", props.data.projectId as string)
    formDataRef.current.append("deviceId", selectedDeviceId as string)
    deleteProjectDevice()
  }

  const { refetch: refetchDeviceList } = useQuery("fetch-my-project-devices-api", {
    queryFn: () =>
      fetchMyProjectDevicesAPI(user, {
        project_id: props.data.projectId,
      }),
    onSuccess: (res: any) => {
      setDeviceLst(res.data)
    },
    onError: (error: any) => {
      redirectToLogin(error)
    },
    enabled: false,
  })

  useEffect(() => {
    setTabValue(0)
    refetchDeviceList()
  }, [props.data.projectId])

  return (
    <Box
      className="project-info-container"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        className="project-info-banner"
        sx={{
          width: "90%",
          height: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="project-status-container" sx={{ display: "flex" }}>
          <Typography className="project-info-title" variant="h6" component="span">
            {props.data.projectName}
          </Typography>
          {props.data.endDate !== null && props.data.endDate < moment().format("YYYY-MM-DD") ? (
            <Chip
              className="project-info-chip finished"
              label="已完成"
              sx={{ ml: 2, bgcolor: "orange" }}
            />
          ) : (
            <Chip className="project-info-chip" label="未完成" sx={{ ml: 2 }} />
          )}
        </Box>
        <Box className="project-info-options">
          <Button
            className="project-info-button"
            variant="contained"
            onClick={() => setOpenBackdrop(true)}
          >
            加入單一感測器
          </Button>
          <Button
            className="project-info-button"
            variant="contained"
            sx={{ ml: 2 }}
            component="label"
            startIcon={<UploadIcon />}
          >
            感測器批次匯入
            <input hidden accept=".csv" type="file" onChange={onFileChange} />
          </Button>
        </Box>
      </Box>
      <Box className="info-tab-container" sx={{ width: "90%", height: "80%" }}>
        <Tabs
          className="info-tab-list"
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab className="info-tab-item" label="感測器監測" {...a11yProps(0)} />
          <Tab className="info-tab-item" label="已收集資料" {...a11yProps(1)} />
          <Tab className="info-tab-item" label="資訊" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <DisplayBar />
          {display.type === "grid" ? (
            <DeviceBlock setOpenDialog={setOpenDialog} setSelectedDeviceId={setSelectedDeviceId} />
          ) : (
            <DeviceList setOpenDialog={setOpenDialog} setSelectedDeviceId={setSelectedDeviceId} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProjectStatistic data={props.data} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ProjectProfile data={props.data} />
        </TabPanel>
      </Box>
      <Backdrop
        className="add-device-container"
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={() => setOpenBackdrop(false)}
      >
        <AddNewDevice projectId={props.data.projectId} />
      </Backdrop>
      <AlertDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={`刪除裝置`}
        content={`確定要刪除裝置"${selectedDeviceId}"嗎？`}
        onConfirm={handleDeleteDevice}
      />
    </Box>
  )
}
