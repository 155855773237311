import { useState, useRef } from "react"
import { NavBar } from "@components/NavBar"
import { NoAuth } from "@static/analysis/NoAuth"
import { Charts } from "@analysis/Charts"
import IotDeviceLayer from "@analysis/IotDeviceLayer"
import StreetLayer from "@static/analysis/StreetLayer"
import { MapRef } from "@analysis/MapRef"
import { LevelBar } from "@components/LevelBar"
import { useRecoilValue } from "recoil"
import { polylineState } from "@core/atoms"
import { IotDataProps } from "@core/atoms"
import Loading from "@components/Loading"

export default function Analysis() {
  const polylines = useRecoilValue(polylineState)
  const [showPanel, setShowPanel] = useState(true)
  const [showCharts, setShowCharts] = useState(false)
  const [changeView, setChangeView] = useState(false)
  const [iotData, setIotData] = useState<Array<IotDataProps>>([])

  const mapRef = useRef<L.Map>()
  const layerControlRef = useRef<L.Control.Layers | null>(null)

  return (
    <>
      <Loading />
      <div className={showPanel ? "" : "hide-panel"}>
        <NavBar />
        <MapRef mapRef={mapRef} layerControlRef={layerControlRef}>
          <IotDeviceLayer mapRef={mapRef} layerControlRef={layerControlRef} iotData={iotData} />
          <StreetLayer mapRef={mapRef} changeView={changeView} setChangeView={setChangeView} />
        </MapRef>
        <NoAuth
          setShowPanel={setShowPanel}
          setShowCharts={setShowCharts}
          setChangeView={setChangeView}
          setIotData={setIotData}
        />
        {polylines.length > 0 && <LevelBar className="level-bar" />}
        <Charts
          className="analysis-charts"
          showCharts={showCharts}
          setShowCharts={setShowCharts}
          sx={{
            height: "90vh",
            minWidth: "400px",
            width: "20vw",
            m: 2,
            right: 0,
            position: "absolute",
            bgcolor: "#ffffff",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
          }}
        />
      </div>
    </>
  )
}
