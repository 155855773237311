const csv2KeyValue = (csv: any) => {
  let lines = csv.split("\n")
  let obj: any = {}
  for (let i = 1; i < lines.length; i++) {
    let currentline = lines[i].split(",")
    obj[currentline[0]] = currentline[1]
  }
  return obj
}

const extractAnalyseItems = (gridSettings: { [index: string]: string }) => {
  let i = 1
  let items = []
  while (gridSettings["ANALYSE_ITEM00" + i]) {
    items.push({
      name: gridSettings["ANALYSE_ITEM00" + i],
      queryName: gridSettings["ANALYSE_ITEM00" + i + "_SENSOR_FIELD"],
      unit: gridSettings["ANALYSE_ITEM00" + i + "_UNIT"],
    })
    i++
  }
  return items
}

const extractColorScales = (gridSettings: { [index: string]: string }) => {
  let i = 1
  let items = []
  while (gridSettings["色階級距顏色" + i]) {
    items.push(gridSettings["色階級距顏色" + i])
    i++
  }
  return items
}

const extractTextItems = (gridSettings: { [index: string]: string }) => {
  let i = 1
  let items = []
  while (gridSettings["TEXT_ITEM00" + i]) {
    items.push({
      name: gridSettings["TEXT_ITEM00" + i],
      queryName: gridSettings["TEXT_ITEM00" + i + "_SENSOR_FIELD"],
      unit: gridSettings["TEXT_ITEM00" + i + "_UNIT"],
    })
    i++
  }
  return items
}

export { csv2KeyValue, extractAnalyseItems, extractColorScales, extractTextItems }
