import { Routes, Route, useSearchParams } from "react-router-dom"
import { Analysis } from "@analysis/Frame"
import NoAuthAnalysis from "@static/analysis/Frame"
import NoAuthTrace from "@static/trace/Frame"
import { Trace } from "@trace/Frame"
import { Login } from "@components/Login"
import { Projects } from "@projects/Frame"
import { useRecoilValue } from "recoil"
import { userAuthSate } from "@core/atoms"
import { QueryClientProvider, QueryClient } from "react-query"
import { Redirect } from "@components/Redirect"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  const [searchParams] = useSearchParams()
  const user = useRecoilValue(userAuthSate)
  const index: string = process.env.REACT_APP_INDEX_COMPONENT as string
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        {user !== null && searchParams.get("token") === null ? (
          <>
            <Route path="/" element={<Trace />}></Route>
            <Route path="Analysis" element={<Analysis />}></Route>
            <Route path="Projects" element={<Projects />}></Route>
            <Route path="s/trace" element={<NoAuthTrace />}></Route>
            <Route path="s/analysis" element={<NoAuthAnalysis />}></Route>
          </>
        ) : index === "login" ? (
          <>
            <Route path="/" element={<Login />} />
            <Route path="s/trace" element={<NoAuthTrace />}></Route>
            <Route path="s/analysis" element={<NoAuthAnalysis />}></Route>
          </>
        ) : (
          <Route path="/" element={<Redirect />} />
        )}
      </Routes>
    </QueryClientProvider>
  )
}

export default App
