import { useState } from "react"
import { Typography, Grid, Switch, Box } from "@mui/material"
import { useRecoilState } from "recoil"
import { sensorBoardState } from "@core/atoms"

type DeviceCellProps = {
  device_id: string
  key: string
  switch: boolean
  status: string
  displayType: string
}

export default function DeviceCell(props: DeviceCellProps) {
  const [sensorBoard, setSensorBoard] = useRecoilState(sensorBoardState)

  const [checked, setChecked] = useState(props.switch)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    let switches = Object.assign({}, sensorBoard.switches)
    setSensorBoard({
      ...sensorBoard,
      switches: {
        ...switches,
        [props.device_id]: event.target.checked,
      },
    })
  }

  return (
    <Grid container className="device-item">
      <Grid item xs={3} className="device-switch">
        <Switch checked={checked} onChange={handleChange} />
      </Grid>
      <Grid item xs={props.displayType === "即時" ? 7 : 9} className="display-device-id">
        <Typography variant="h6" component="span">
          {props.device_id}
        </Typography>
      </Grid>
      {props.displayType === "即時" && (
        <Grid item xs={2} className="display-device-status">
          <Box
            sx={{
              height: "20px",
              width: "20px",
              borderRadius: "40px",
              bgcolor:
                props.status === "active"
                  ? "green"
                  : props.status === "deactivate"
                  ? "gray"
                  : "orange",
            }}
            className={`${props.status}`}
          ></Box>
        </Grid>
      )}
    </Grid>
  )
}
