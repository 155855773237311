import { useState, useEffect } from "react"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import {
  Box,
  CircularProgress,
  Button,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  FormControl,
  InputLabel,
  MenuItem,
  Skeleton,
} from "@mui/material"
import { csv2KeyValue, extractTextItems } from "@core/gridSettings"
import {
  userAuthSate,
  countyMappingState,
  ProjectCellProps,
  countyInfoProps,
  filterTextItems,
  selectedTextItemState,
} from "@core/atoms"
import {
  baseUrl,
  downloadMyProjectIntegrity,
  fetchMyProjectIntegrity,
  fetchRoadCountAPI,
  fetchGridSettingsAPI,
  fetchMyProjectIntegrityCount,
} from "@core/API"
import { Map } from "@projects/Map"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { useResetRecoilState, useRecoilState, useRecoilValue } from "recoil"
import DownloadIcon from "@mui/icons-material/Download"
import { LatLngExpression } from "leaflet"
import moment from "moment"

type InfoCellProps = {
  label: string
  value: string | null | number
  loading: boolean
}

type IntegrityProps = {
  deviceId: string
  integrity: { [index: string]: string }
}

type ProjectInfosProps = {
  numberOfdatas: null | string
  days: null | number
  numberOfsensors: null | number
  startDate: null | string
  endDate: null | string
}

type PolylineProps = {
  count: number
  county: string
  lat_0: number
  lat_1: number
  lon_0: number
  lon_1: number
  road_idx: number
  color?: string
}

const TextItemSelector = () => {
  const textItems = useRecoilValue(filterTextItems)
  const [selectedTextItem, setSelectedTextItem] = useRecoilState(selectedTextItemState)

  const handleChange = (event: SelectChangeEvent) => {
    let current = textItems.filter(item => item.name === event.target.value)
    setSelectedTextItem(current[0])
  }
  return (
    <FormControl sx={{ width: "150px" }} className="integrity-type">
      <InputLabel>測項</InputLabel>
      <Select
        value={selectedTextItem.name ? selectedTextItem.name : ""}
        label="測項"
        onChange={handleChange}
      >
        {textItems.map(item => (
          <MenuItem value={item.name} key={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const InfoCell = ({ label, value, loading }: InfoCellProps) => {
  return (
    <Box
      className="statistic-infos-cell"
      sx={{
        width: "120px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {label}
      {loading ? (
        <Skeleton sx={{ width: "100%", fontSize: 20 }} />
      ) : (
        <Typography
          className="statistic-infos-value"
          variant="h5"
          component="span"
          sx={{
            width: "100%",
            color: "#000000",
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 20,
          }}
        >
          {value === null ? "-" : value}
        </Typography>
      )}
    </Box>
  )
}

export const ProjectStatistic = (props: { data: ProjectCellProps }) => {
  const navigate = useNavigate()

  const countyMapping = useRecoilValue(countyMappingState)

  const [user, setUser] = useRecoilState(userAuthSate)
  const [textItems, setTextItems] = useRecoilState(filterTextItems)
  const [selectedTextItem, setSelectedTextItem] = useRecoilState(selectedTextItemState)

  const resetSelectedTextItem = useResetRecoilState(selectedTextItemState)

  let selected: countyInfoProps = countyMapping.filter(
    county => county.code === props.data.county
  )[0]
  if (selected === undefined)
    selected = {
      lon: 120.239,
      lat: 22.992,
      name: "台灣",
      nameEng: "Taiwan",
      code: "SS",
    }
  const [center, setCenter] = useState<LatLngExpression>([selected.lat, selected.lon])
  const [deviceIntegrity, setDeviceIntegrity] = useState<Array<IntegrityProps>>([])
  const [polylines, setPolylines] = useState<Array<PolylineProps>>([])
  const endDate = props.data.endDate !== null ? props.data.endDate : moment().format("YYYY-MM-DD")
  const diffDays = moment(endDate, "YYYY-MM-DD").diff(
    moment(props.data.beginDate, "YYYY-MM-DD"),
    "days"
  )
  const [projectInfos, setProjectInfos] = useState<ProjectInfosProps>({
    numberOfdatas: null,
    days: diffDays,
    numberOfsensors: null,
    startDate: props.data.beginDate as string,
    endDate: endDate as string,
  })

  const redirectToLogin = (error: any) => {
    console.log(error)
    if (error.response.statusText === "Unauthorized") {
      setUser(null)
      navigate("/")
    }
  }

  const downloadFile = (data: Blob) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(data)

    // create "a" HTLM element with href to file & click
    const link = document.createElement("a")
    link.href = href
    link.setAttribute(
      "download",
      `project-${props.data.projectName}-integrity-${selectedTextItem.name}.csv`
    )
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(baseUrl + "mot_api/users/me/project/integrity/export")
  }

  const { refetch: downloadIntegrity, isFetching: isDownloading } = useQuery(
    "download-my-project-integrity-api",
    {
      queryFn: () =>
        downloadMyProjectIntegrity(user, {
          projectId: props.data.projectId,
          sensorId: selectedTextItem.queryName,
        }),
      onSuccess: (res: any) => {
        downloadFile(res.data)
      },
      onError: (error: any) => {
        redirectToLogin(error)
      },
      enabled: false,
    }
  )

  let startFromDate: string
  const { refetch: fetchIntegrity, isFetching: fetchingIntegrityTable } = useQuery(
    "fetch-my-project-integrity-api",
    {
      queryFn: () =>
        fetchMyProjectIntegrity(user, {
          projectId: props.data.projectId,
          sensorId: selectedTextItem.queryName,
          date: startFromDate,
        }),
      onSuccess: (res: any) => {
        setDeviceIntegrity(res.data.integrityResult)

        setProjectInfos({
          ...projectInfos,
          ...{
            numberOfsensors: res.data.integrityResult.length,
          },
        })
      },
      onError: (error: any) => {
        redirectToLogin(error)
      },
      enabled: false,
    }
  )

  const { refetch: fetchIntegrityCount, isFetching: fetchingIntegrityCount } = useQuery(
    "fetch-project-integrity-count-api",
    {
      queryFn: () =>
        fetchMyProjectIntegrityCount(user, {
          projectId: props.data.projectId,
          sensorId: selectedTextItem.queryName,
        }),
      onSuccess: (res: any) => {
        console.log(res)
        setProjectInfos({
          ...projectInfos,
          numberOfdatas: `${res.data.totalCount.toLocaleString("en-US")}筆`,
        })
      },
      onError: (error: any) => {
        redirectToLogin(error)
      },
      enabled: false,
    }
  )

  const { refetch: fetchRoadCount, isFetching: isFetchingRoadCount } = useQuery(
    "fetch-road-count-api",
    {
      queryFn: () =>
        fetchRoadCountAPI(user, {
          projectId: props.data.projectId,
          sensorId: selectedTextItem.queryName,
        }),
      onSuccess: (res: any) => {
        setPolylineColor(res.data)
      },
      onError: (error: any) => {
        redirectToLogin(error)
      },
      enabled: false,
    }
  )

  const { refetch: refetchGridSettings } = useQuery("fetch-grid-settings-api", {
    queryFn: () => fetchGridSettingsAPI(user),
    onSuccess: res => {
      const gridSettings = csv2KeyValue(res.data)
      setTextItems(extractTextItems(gridSettings))
    },
    onError: (error: any) => {
      redirectToLogin(error)
    },
    enabled: false,
    // cacheTime: 1800000,
    // staleTime: 1800000,
  })

  const Banner = () => {
    return (
      <Box
        className="integrity-banner"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          className="integrity-title"
          variant="h6"
          component="span"
          sx={{
            color: "#000000",
            fontWeight: "bold",
            textAlign: "center",
            mr: 2,
          }}
        >
          專案感測器資料數
        </Typography>
        <Box className="integrity-options">
          <TextItemSelector />
          <Button
            className="integrity-button"
            sx={{ ml: 3, width: "150px" }}
            disabled={isDownloading}
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={() => downloadIntegrity()}
          >
            {!isDownloading ? "下載完整資料" : "下載中…"}
          </Button>
        </Box>
      </Box>
    )
  }

  const BannerSkeleton = () => {
    return (
      <Box
        className="integrity-banner"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Skeleton>
          <Typography
            className="integrity-title"
            variant="h6"
            component="span"
            sx={{
              color: "#000000",
              fontWeight: "bold",
              textAlign: "center",
              mr: 2,
            }}
          >
            專案感測器資料數
          </Typography>
        </Skeleton>
        <Box className="integrity-options" sx={{ display: "flex", flexDirection: "row" }}>
          <Skeleton sx={{ height: "60px", width: "150px" }} />
          <Skeleton sx={{ height: "60px", width: "150px", ml: 3 }} />
        </Box>
      </Box>
    )
  }

  const ProjectIntegrity = () => {
    if (deviceIntegrity.length === 0) return <Box sx={{ mt: 5 }}>沒有資料數統計...</Box>
    return (
      <TableContainer sx={{ maxHeight: 440, mt: 2 }} className="integrity-table-container">
        <Table stickyHeader aria-label="sticky table" className="integrity-table">
          <TableHead className="integrity-table-head">
            <TableRow className="integrity-table-row">
              <TableCell
                className="integrity-table-cell"
                key="td-device-id"
                style={{
                  minWidth: "100px",
                  position: "sticky",
                  left: "0",
                  top: "0",
                  zIndex: 100,
                  backgroundColor: "#fafafa",
                }}
              >
                感測器編號
              </TableCell>
              {Object.keys(deviceIntegrity[0].integrity).map(date => (
                <TableCell
                  className="integrity-table-cell"
                  key={"td-date-" + date}
                  align="right"
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                >
                  {date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="integrity-table-body">
            {deviceIntegrity.map(device => (
              <TableRow key={device.deviceId + "-row"} className="integrity-table-row">
                <TableCell
                  className="integrity-table-cell"
                  key={device.deviceId + "-title"}
                  style={{
                    minWidth: "100px",
                    position: "sticky",
                    left: "0",
                    zIndex: 10,
                    backgroundColor: "#fafafa",
                  }}
                >
                  {device.deviceId}
                </TableCell>
                {Object.entries(device.integrity).map(([date, integrity]) => (
                  <TableCell
                    className="integrity-table-cell"
                    key={device.deviceId + date}
                    align="right"
                    style={{ minWidth: "80px" }}
                  >
                    {integrity}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const setPolylineColor = (lines: Array<PolylineProps>) => {
    const colors = [
      "hsl(292, 100%, 20%)",
      "hsl(292, 100%, 25%)",
      "hsl(292, 100%, 30%)",
      "hsl(292, 100%, 25%)",
      "hsl(292, 100%, 40%)",
      "hsl(292, 100%, 45%)",
      "hsl(292, 100%, 50%)",
      "hsl(292, 100%, 55%)",
      "hsl(292, 100%, 60%)",
      "hsl(292, 100%, 65%)",
      "hsl(292, 100%, 70%)",
    ]
    const colorLength = colors.length - 1

    let results: Array<PolylineProps> = []
    lines.forEach(line => {
      results.push({
        color:
          line.count / 5 >= colorLength ? colors[colorLength] : colors[Math.trunc(line.count / 5)],
        ...line,
      })
    })
    setPolylines(results)
  }

  useEffect(() => {
    fetchRoadCount()
    fetchIntegrityCount()
    startFromDate = moment().format("YYYY-MM-DD")
    fetchIntegrity()
  }, [selectedTextItem])

  useEffect(() => {
    resetSelectedTextItem()
    refetchGridSettings()
  }, [])

  return (
    <Box
      className="statistic-container"
      sx={{
        height: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isFetchingRoadCount && (
        <Box className="map-loading-indicator" sx={{ position: "fixed", zIndex: 1000 }}>
          地圖資料載入中...
        </Box>
      )}
      <Map className="statistic-map" polylines={polylines} center={center} />
      <Box
        className="statistic-wrapper"
        sx={{
          height: "48%",
          width: "97%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="statistic-infos"
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            height: "22%",
            alignItems: "center",
          }}
        >
          <InfoCell
            label="目前資料數"
            value={projectInfos.numberOfdatas}
            loading={fetchingIntegrityCount}
          />
          <InfoCell label="蒐集天數" value={projectInfos.days} loading={fetchingIntegrityCount} />
          <InfoCell
            label="感測器數量"
            value={projectInfos.numberOfsensors}
            loading={fetchingIntegrityCount}
          />
          <InfoCell
            label="資料起始日期"
            value={projectInfos.startDate}
            loading={fetchingIntegrityCount}
          />
          <InfoCell
            label="最後更新日期"
            value={projectInfos.endDate}
            loading={fetchingIntegrityCount}
          />
        </Box>
        <Box
          className="statistic-integrity"
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            flexDirection: "column",
            height: "78%",
            alignItems: "center",
          }}
        >
          {fetchingIntegrityCount ? <BannerSkeleton /> : <Banner />}
          {fetchingIntegrityCount ? (
            <Skeleton sx={{ width: "100%", height: "80%" }} />
          ) : (
            <ProjectIntegrity />
          )}
        </Box>
      </Box>
    </Box>
  )
}
