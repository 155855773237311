import { useEffect, useState, useRef } from "react"
import { Box, Button, Tooltip } from "@mui/material"
import {
  trackingIotDeviceState,
  trackingIotDeviceDataState,
  MapRefProps,
  IotDeviceProps,
  allIotDeviceState,
  datetimePickerState,
  userAuthSate,
  IotDeviceDataProps,
  selectedTextItemState,
  latestTrackingIotDeviceState,
  sensorBoardState,
} from "@core/atoms"
import { fetchIotDeviceDataAPI } from "@core/API"
import { useRecoilValue, useRecoilState } from "recoil"
import moment from "moment"
import { useQuery } from "react-query"

export const LoadIot = (props: MapRefProps) => {
  const { mapRef } = props

  const user = useRecoilValue(userAuthSate)
  const allIotDevice = useRecoilValue(allIotDeviceState)
  const pickerTime = useRecoilValue(datetimePickerState)
  const selectedTextItem = useRecoilValue(selectedTextItemState)
  const sensorBoard = useRecoilValue(sensorBoardState)

  const [iotDevices, setIotDevices] = useRecoilState(trackingIotDeviceState)
  const [, setlatestIotDevices] = useRecoilState(latestTrackingIotDeviceState)
  const [, setIotDeviceData] = useRecoilState(trackingIotDeviceDataState)
  const [isFetching, setIsFetching] = useState(false)
  const [showIotStation, setShowIotStation] = useState(false)
  const [startAutoFetch, setStartAutoFetch] = useState(false)
  const [lastFetchTime, setLastFetchTime] = useState(pickerTime.end)
  const [allowedTextItem, setAllowedTextItem] = useState(true)

  let params = {}
  const paramsRef = useRef(params)
  paramsRef.current = params
  let bodyFormData: FormData = new FormData()
  const formDataRef = useRef(bodyFormData)
  formDataRef.current = bodyFormData
  const fetchTimeRef = useRef(lastFetchTime)
  fetchTimeRef.current = lastFetchTime

  const sortIotDataById = (rawdata: Array<IotDeviceDataProps>) => {
    let rawdataSortById: { [index: string]: Array<IotDeviceDataProps> } = {}

    rawdata.forEach(device => {
      const deviceId = device.device_id
      if (!rawdataSortById[deviceId]) {
        rawdataSortById[deviceId] = []
      }
      rawdataSortById[deviceId].push({
        ...device,
        timestamp: moment(device.time, "YYYY-MM-DD HH:mm:ss").unix(),
      })
    })
    return rawdataSortById
  }

  const { refetch: refetchIotDeviceData } = useQuery("fetch-iot-device-data-api", {
    queryFn: () => fetchIotDeviceDataAPI(user, paramsRef.current, formDataRef.current),
    onSuccess: (res: any) => {
      const rawdataSortById = sortIotDataById(res.data.data)
      setIotDeviceData(prevState => ({
        ...prevState,
        ...Object.fromEntries(
          Object.entries(rawdataSortById).map(([key, value]) => [
            key,
            [...(prevState[key] ?? []), ...value],
          ])
        ),
      }))
      setStartAutoFetch(true)
      setIsFetching(false)
    },
    onError: (error: any) => {
      setIsFetching(false)
      console.log(error)
    },
    enabled: false,
  })

  const fetchIotDeviceData = (deviceIds: string) => {
    if (deviceIds === "") return
    setIsFetching(true)
    paramsRef.current = {
      start_time: moment(pickerTime.start).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(pickerTime.end).format("YYYY-MM-DD HH:mm:ss"),
      filed: selectedTextItem.queryName,
    }
    formDataRef.current = new FormData()
    formDataRef.current.append("device_id", deviceIds)
    refetchIotDeviceData()
  }

  const autoFetchIotData = () => {
    paramsRef.current = {
      start_time: moment(fetchTimeRef.current).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      filed: selectedTextItem.queryName,
    }
    let deviceIds = iotDevices.map(obj => obj.device_id).toString()
    formDataRef.current = new FormData()
    formDataRef.current.append("device_id", deviceIds)
    refetchIotDeviceData()

    setLastFetchTime(new Date())
  }

  const handleClick = () => {
    const boundary = mapRef.current?.getBounds()
    let inRangeIotDevice: Array<IotDeviceProps> = []
    allIotDevice.forEach(device => {
      if (boundary?.contains([device.lat, device.lon])) inRangeIotDevice.push(device)
    })
    const filteredDevices = inRangeIotDevice.filter(device => {
      return !iotDevices.some(trackingDevice => trackingDevice.id === device.id)
    })
    setIotDevices([...iotDevices, ...filteredDevices])
    setlatestIotDevices(filteredDevices)
    const deviceIds = filteredDevices.map(device => device.device_id)

    fetchIotDeviceData(deviceIds.toString())
  }

  useEffect(() => {
    if (mapRef.current !== undefined) {
      mapRef.current.on("zoomend", function () {
        if ((mapRef.current as L.Map).getZoom() > (mapRef.current as L.Map).getMaxZoom() - 5)
          setShowIotStation(true)
        else setShowIotStation(false)
      })
    }
    return () => {
      ;(mapRef.current as L.Map).off("zoomend")
    }
  }, [mapRef.current])

  useEffect(() => {
    setLastFetchTime(pickerTime.end)
  }, [pickerTime.end])

  useEffect(() => {
    if (sensorBoard.displayType !== "即時") return

    let fetchDataInterval = setInterval(() => {
      if (iotDevices.length === 0) return
      autoFetchIotData()
    }, 180000)

    return () => {
      clearInterval(fetchDataInterval)
    }
  }, [startAutoFetch])

  useEffect(() => {
    const name = selectedTextItem.name
    const validItem = name === "PM2.5" || name === "VOC"
    if (validItem) {
      const deviceIds = iotDevices.map(device => device.device_id)
      fetchIotDeviceData(deviceIds.toString())
    }
    setAllowedTextItem(validItem)
  }, [selectedTextItem])

  return (
    <Box
      sx={{
        justifyContent: "center",
        zIndex: 100,
      }}
      className="button-load-iot"
    >
      {!allowedTextItem ? (
        <Tooltip title="該測項無法使用此功能" arrow>
          <span>
            <Button variant="contained" disabled={true}>
              Iot無該測項
            </Button>
          </span>
        </Tooltip>
      ) : !showIotStation ? (
        <Tooltip title="必須拉近才能使用此功能" arrow>
          <span>
            <Button variant="contained" disabled={true}>
              追蹤此區域的IOT
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button variant="contained" onClick={handleClick} disabled={isFetching}>
          {isFetching ? "載入Iot資料中..." : "載入此區域的IOT"}
        </Button>
      )}
    </Box>
  )
}
