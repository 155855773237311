import { useEffect, useRef } from "react"
import L from "leaflet"
import { MapRefProps } from "@core/atoms"
import IotDeviceLayer from "@trace/IotDeviceLayer"
import WindLayer from "@trace/WindLayer"
import { Box } from "@mui/material"
import MotDeviceLayer from "@trace/MotDeviceLayer"

export const MapRef = (props: MapRefProps) => {
  const { mapRef } = props
  const layerControlRef = useRef<L.Control.Layers | null>(null)

  const createMap = () => {
    mapRef.current = L.map("mapid", { zoomControl: false, preferCanvas: true }).setView(
      L.latLng([23.502, 120.239]),
      8
    )

    const streetLayer = L.tileLayer(
      "https://wmts.nlsc.gov.tw/wmts/EMAP5/default/GoogleMapsCompatible/{z}/{y}/{x}",
      {
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    ).addTo(mapRef.current)

    const satelliteLayer = L.tileLayer(
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      {
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
      }
    )

    let baseLayers = {
      街道地圖: streetLayer,
      衛星影像: satelliteLayer,
    }

    L.control
      .scale({
        position: "bottomright",
        maxWidth: 150,
        imperial: false,
        updateWhenIdle: true,
      })
      .addTo(mapRef.current)

    layerControlRef.current = L.control
      .layers(baseLayers, undefined, { position: "topright" })
      .addTo(mapRef.current)
  }

  // const changeView = () => {
  //   let markerBounds = latLngBounds([])
  //   for (const [deviceId, markers] of Object.entries(deviceData)) {
  //     markers.forEach(marker => markerBounds.extend(marker.center))
  //   }

  //   if (markerBounds.isValid()) mapRef.current?.fitBounds(markerBounds)
  //   else mapRef.current?.setView({ lat: 23.502, lng: 120.239 }, 8)
  // }

  useEffect(() => {
    if (mapRef.current === undefined) createMap()
    return () => {
      ;(mapRef.current as L.Map).off("zoomend")
    }
  }, [])

  return (
    <Box sx={{ height: "100vh", width: "100vw", position: "fixed" }} id="mapid">
      {mapRef.current !== undefined && (
        <>
          <MotDeviceLayer mapRef={mapRef} />
          <WindLayer mapRef={mapRef} layerControlRef={layerControlRef} />
          <IotDeviceLayer mapRef={mapRef} />
        </>
      )}
    </Box>
  )
}
