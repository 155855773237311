import { useEffect, useState, useRef } from "react"
import L from "leaflet"
import { useRecoilValue, useRecoilState } from "recoil"
import {
  gridSettingsState,
  IotDeviceProps,
  MapRefProps,
  allIotDeviceState,
  colorScaleState,
  IotDataProps,
} from "@core/atoms"
import { fetchIotDevicesAPI } from "@core/API"
import { useQuery } from "react-query"
import useKonamiCode from "@hooks/useKonamiCode"

const genUnloadedDeviceMarkerPopupContent = (marker: IotDeviceProps, status: string) => {
  return `    
      <Box>
        <Box>
          <span>Device ID：</span>
          <span>${marker.id}</span>
        </Box>
        <br />
        <Box>
          <span>裝置名稱：</span>
          <span>${marker.name}</span>
        </Box>
        <br />
          <Box>
            <span>尚未載入此裝置資料</span>
          </Box>
      </Box>
    `
}

const genMarkerPopupContent = (marker: IotDataProps, name: string) => {
  return `
      <Box>
        <Box>
          <span>Device ID：</span>
          <span>${marker.device_id}</span>
        </Box>
        <br />
        <Box>
          <span>測項：</span>
          <span>${name}</span>
        </Box>
        <br />
        <Box>
          <span>數值：</span>
          <span>${marker.value.toFixed(2)}</span>
        </Box>
      </Box>
    `
}

type MapProps = MapRefProps & {
  layerControlRef: React.MutableRefObject<L.Control.Layers | null>
  iotData: Array<IotDataProps>
}

export default function IotDeviceLayer(props: MapProps) {
  const { mapRef, layerControlRef, iotData } = props

  const [konamiCodeEntered, resetKonamiCode] = useKonamiCode()

  const gridSettings = useRecoilValue(gridSettingsState)
  const colorScale = useRecoilValue(colorScaleState)
  const [iotDevice, setIotDevice] = useRecoilState(allIotDeviceState)

  const [showHiddenLayer, setShowHiddenLayer] = useState(false)

  const layerRef = useRef<L.LayerGroup>(L.layerGroup())
  const emptyLayerRef = useRef<L.LayerGroup>(L.layerGroup())

  const radius: number = isNaN(parseInt(gridSettings["radius"]))
    ? 5
    : parseInt(gridSettings["radius"])

  const findCorrespondColor = (value: number) => {
    let color = colorScale[0].color
    for (let i = 1; i < colorScale.length; i++) {
      if (value >= colorScale[i].value) color = colorScale[i].color
      else break
    }
    if (color === colorScale[colorScale.length - 1].color)
      color = colorScale[colorScale.length - 2].color

    return color
  }

  const addIotDeviceMarkerToMap = () => {
    let markers: { [index: string]: L.CircleMarker } = {}
    const popupOptions: L.PopupOptions = {
      maxWidth: 200, // set max-width
      className: "npopup", // name custom popup
      closeOnClick: false,
    }

    iotDevice.forEach(device => {
      const options = {
        fill: true,
        fillOpacity: 1,
        stroke: true,
        weight: 2,
        color: "gray",
        fillColor: "transparent",
        radius: radius,
        className: "square-marker",
      }

      markers[device.device_id] = L.circleMarker([device.lat, device.lon], options)
        .bindTooltip(genUnloadedDeviceMarkerPopupContent(device, "empty"), popupOptions)
        .addTo(emptyLayerRef.current as L.LayerGroup)
    })

    iotData.forEach(data => {
      const options = {
        fill: true,
        fillOpacity: 1,
        stroke: true,
        weight: 2,
        color: "#666",
        fillColor: findCorrespondColor(data.value),
        radius: radius,
      }
      if (markers[data.device_id] === undefined) return

      emptyLayerRef.current.removeLayer(markers[data.device_id])
      markers[data.device_id]
        .setTooltipContent(genMarkerPopupContent(data, "pm2_5"))
        .setStyle(options)
        .addTo(layerRef.current as L.LayerGroup)
    })
  }

  useQuery("fetch-iot-devices-api", {
    queryFn: () => fetchIotDevicesAPI(),
    onSuccess: res => {
      setIotDevice(res.data.data.filter((d: IotDeviceProps) => d.display))
    },
    onError: (error: any) => {
      console.log(error)
    },
    cacheTime: 1800000,
    staleTime: 1800000,
  })

  useEffect(() => {
    if (showHiddenLayer) emptyLayerRef.current.addTo(mapRef.current as L.Map)
    else emptyLayerRef.current.removeFrom(mapRef.current as L.Map)
  }, [showHiddenLayer])

  useEffect(() => {
    if (konamiCodeEntered) {
      setShowHiddenLayer(prev => !prev)
      resetKonamiCode()
    }
  }, [konamiCodeEntered])

  useEffect(() => {
    if (iotDevice.length === 0 || iotData.length === 0) return
    layerRef.current?.clearLayers()
    addIotDeviceMarkerToMap()
  }, [iotDevice, iotData, colorScale])

  useEffect(() => {
    layerControlRef.current?.addOverlay(layerRef.current, "IoT點位")
  }, [mapRef.current])

  useEffect(() => {
    return () => {
      ;(mapRef.current as L.Map).off("overlayadd")
    }
  }, [mapRef.current])

  return null
}
