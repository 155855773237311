import { useEffect, useState, useRef } from "react"
import { Box, Typography, Grid, Button, Divider, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { SxProps } from "@mui/system"
import TuneIcon from "@mui/icons-material/Tune"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
  sensorBoardState,
  gridSettingsState,
  deviceDataStates,
  filterDateState,
  openFilterState,
  datetimePickerState,
  motBufferState,
  motJsonDataState,
  userAuthSate,
  colorScaleColorsState,
  colorScaleBoundsState,
  filterTextItems,
  selectedTextItemState,
  absoluteColorScaleState,
  allIotDeviceState,
  IotDeviceProps,
} from "@core/atoms"
import {
  fetchGridSettingsAPI,
  fetchMotDataAPI,
  fetchMotBufferAPI,
  fetchAbsoluteColorScaleAPI,
  fetchIotDevicesAPI,
  fetchAllDevicesAPI,
} from "@core/API"
import { filterMotDataByTextItemAndTime } from "@core/selectors"
import { useQuery } from "react-query"
import { csv2KeyValue, extractColorScales, extractTextItems } from "@core/gridSettings"
import DatetimePicker from "@sensorBoard/DatetimePicker"
import TextItemSelector from "@sensorBoard/TextItemSelector"
import DeviceCell from "@sensorBoard/DeviceCell"

type SensorBoardProps = {
  sx?: SxProps
}

export const BoardFrame = (props: SensorBoardProps) => {
  const navigate = useNavigate()

  const filterDate = useRecoilValue(filterDateState)
  const [filteredMotData, max, min] = useRecoilValue(filterMotDataByTextItemAndTime)
  const selectedTextItem = useRecoilValue(selectedTextItemState)

  const [user, setUser] = useRecoilState(userAuthSate)
  const [, setTimePicker] = useRecoilState(datetimePickerState)
  const [sensorBoard, setSensorBoard] = useRecoilState(sensorBoardState)
  const [deviceDatas, setDeviceData] = useRecoilState(deviceDataStates)
  const [, setGridSettings] = useRecoilState(gridSettingsState)
  const [, setTextItems] = useRecoilState(filterTextItems)
  const [, setColorScaleColors] = useRecoilState(colorScaleColorsState)
  const [, setOpenFilter] = useRecoilState(openFilterState)
  const [motJsonData, setMotJsonData] = useRecoilState(motJsonDataState)
  const [bufferData, setBufferData] = useRecoilState(motBufferState)
  const [, setScaleBounds] = useRecoilState(colorScaleBoundsState)
  const [, setAbsoluteColorScale] = useRecoilState(absoluteColorScaleState)
  const [, setIotDevice] = useRecoilState(allIotDeviceState)

  const resetMotBufferState = useResetRecoilState(motBufferState)
  const resetdeviceDataState = useResetRecoilState(deviceDataStates)

  const [bufferInterval, setBufferInterval] = useState<number | false>(false)
  const [statusInterval, setStatusInterval] = useState<number | false>(false)

  const bufferDataRef = useRef(bufferData)
  bufferDataRef.current = bufferData
  // const selectedDevicesRef = useRef(selectedDevices)
  // selectedDevicesRef.current = selectedDevices

  let dataParams = {}
  let bufferParams = {}
  let showAlert = false

  const csv2obj = (csv: any) => {
    let lines = csv.split("\n")
    let result = []
    let headers = lines[0].split(",")

    // 最後一行會出現undefined所以-1
    for (let i = 1; i < lines.length - 1; i++) {
      let obj: { [index: string]: string } = {}
      let currentline = lines[i].split(",")
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }
      result.push(obj)
    }
    return result
  }

  const loadMotData = (startTime: string, endTime: string) => {
    if (sensorBoard.devices.length === 0) return
    setSensorBoard({
      ...sensorBoard,
      loading: true,
    })
    resetdeviceDataState()
    dataParams = {
      start_time: startTime,
      end_time: endTime,
      devices: sensorBoard.devices.toString(),
    }
    fetchMotData()
  }

  const loadMotBuffer = () => {
    if (sensorBoard.devices.length > 0) {
      bufferParams = {
        sensor_id: selectedTextItem.queryName,
      }
      setBufferInterval(60000)
    }
  }

  const redirectToLogin = (error: any) => {
    console.log(error)
    if (error.response.statusText === "Unauthorized") {
      setUser(null)
      navigate("/")
    }
  }

  const checkDataLength = () => {
    let dataLength = 0
    Object.values(filteredMotData).forEach(value => {
      dataLength += value.length
    })
    if (dataLength === 0 && Object.keys(sensorBoard.switches).length)
      alert("沒有符合該選擇條件的資料...")
  }

  const { refetch: refetchGridSettings } = useQuery("fetch-grid-settings-api", {
    queryFn: () => fetchGridSettingsAPI(user),
    onSuccess: res => {
      const gridSettings = csv2KeyValue(res.data)
      setGridSettings(gridSettings)
      setTextItems(extractTextItems(gridSettings))
      setColorScaleColors(extractColorScales(gridSettings))
    },
    onError: (error: any) => {
      redirectToLogin(error)
    },
    enabled: false,
    // cacheTime: 1800000,
    // staleTime: 1800000,
  })

  const { refetch: fetchMotData } = useQuery("fetch-mot-data-api", {
    queryFn: () => fetchMotDataAPI(user, dataParams),
    onSuccess: (res: any) => {
      let datas: any = csv2obj(res.data)
      setMotJsonData(datas)
    },
    onError: (error: any) => {
      redirectToLogin(error)
      setSensorBoard({
        ...sensorBoard,
        loading: false,
      })
    },
    enabled: false,
  })

  useQuery("fetch-mot-buffer-api", {
    queryFn: () =>
      fetchMotBufferAPI(user, {
        sensor_id: selectedTextItem.queryName,
      }),
    onSuccess: (res: any) => {
      let datas: any = csv2obj(res.data)
      datas = datas.filter((item: any) => sensorBoard.devices.includes(item.device_id))
      datas = [...bufferDataRef.current, ...datas]
      setBufferData(datas)
    },
    onError: (error: any) => {
      redirectToLogin(error)
    },
    refetchInterval: bufferInterval,
    refetchIntervalInBackground: true,
    enabled: bufferInterval !== false,
  })

  useQuery("fetch-absolute-color-scale-api", {
    queryFn: () => fetchAbsoluteColorScaleAPI(),
    onSuccess: res => {
      setAbsoluteColorScale(res.data)
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
  })

  useQuery("fetch-devices-status-api", {
    queryFn: () =>
      fetchAllDevicesAPI(user, {
        devices: sensorBoard.devices.toString(),
      }),
    onSuccess: (res: any) => {
      let selectedDevice: { [index: string]: string } = {}
      res.data.forEach((device: any) => {
        selectedDevice[device.device_id] = device.status
      })
      setSensorBoard({
        ...sensorBoard,
        status: selectedDevice,
      })
    },
    onError: (error: any) => {
      if (error.response.statusText === "Unauthorized") {
        setUser(null)
        navigate("/")
      }
    },
    refetchInterval: statusInterval,
    refetchIntervalInBackground: false,
    enabled: statusInterval !== false,
  })

  const { refetch: fetchIotDevice } = useQuery("fetch-iot-devices-api", {
    queryFn: () => fetchIotDevicesAPI(),
    onSuccess: res => {
      setIotDevice(res.data.data.filter((d: IotDeviceProps) => d.display))
    },
    onError: (error: any) => {
      console.log(error)
    },
    enabled: false,
    cacheTime: 1800000,
    staleTime: 1800000,
  })

  useEffect(() => {
    setScaleBounds({
      max: max,
      min: min,
    })
  }, [max, min])

  useEffect(() => {
    setSensorBoard({
      ...sensorBoard,
      loading: false,
    })
  }, [motJsonData])

  // useEffect(() => {
  //   selectedDevicesRef.current = selectedDevices
  //   console.log("device changed", selectedDevices)
  // }, [sensorBoard.devices])

  useEffect(() => {
    let startTime = filterDate.startDate + " 00:00"
    let endTime = filterDate.endDate + " 23:59"

    if (sensorBoard.devices.length) {
      if (sensorBoard.displayType === "即時") {
        startTime = moment().startOf("day").format("YYYY-MM-DD HH:mm")
        // startTime = moment()
        //   .subtract(gridSettings.CONFIG_RAWDATA_HOURS_BEFORE, "hours")
        //   .format("YYYY-MM-DD HH:mm")
        endTime = moment().format("YYYY-MM-DD HH:mm")
        setTimePicker({
          start: moment().startOf("day").toDate(),
          end: moment().toDate(),
        })
        resetMotBufferState()
        loadMotBuffer()
        setStatusInterval(60000)
      } else {
        setBufferInterval(false)
        setStatusInterval(false)
      }
      loadMotData(startTime, endTime)
      fetchIotDevice()
    }
  }, [sensorBoard.devices])

  useEffect(() => {
    if (sensorBoard.displayType === "即時") {
      let startTime = moment().startOf("day").format("YYYY-MM-DD HH:mm")
      // let startTime = moment().startOf("day").format("YYYY-MM-DD HH:mm")
      //   .subtract(gridSettings.CONFIG_RAWDATA_HOURS_BEFORE, "hours")
      //   .format("YYYY-MM-DD HH:mm")
      let endTime = moment().format("YYYY-MM-DD HH:mm")
      loadMotData(startTime, endTime)
    } else {
      setDeviceData(filteredMotData)
    }
    showAlert = true
  }, [selectedTextItem])

  useEffect(() => {
    setDeviceData(filteredMotData)
    if (showAlert) {
      checkDataLength()
      showAlert = false
    }
  }, [filteredMotData])

  useEffect(() => {
    refetchGridSettings()
    return () => {
      resetMotBufferState()
    }
  }, [])

  const message = (msg: string, color: string) => (
    <Typography
      variant="h6"
      component="div"
      sx={{ flex: 1, color: color, textAlign: "center", mt: 1 }}
      className="message-display"
    >
      {msg}
    </Typography>
  )

  const devicePanel = () => (
    <>
      <Grid container className="col-title">
        <Grid item xs={3}>
          <Typography variant="h6" component="span">
            顯示
          </Typography>
        </Grid>
        <Grid item xs={sensorBoard.displayType === "即時" ? 7 : 9}>
          <Typography variant="h6" component="span">
            感測器編號
          </Typography>
        </Grid>
        {sensorBoard.displayType === "即時" && (
          <Grid item xs={2}>
            <Typography variant="h6" component="span">
              狀態
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box className="device-panel">
        {sensorBoard.devices.map((device: string) => (
          <DeviceCell
            device_id={device}
            key={device}
            switch={sensorBoard.switches[device]}
            status={sensorBoard.status[device]}
            displayType={sensorBoard.displayType}
          />
        ))}
      </Box>
      <Box className="display-filter-container">
        <TextItemSelector />
        {sensorBoard.displayType === "日期" && <DatetimePicker />}
      </Box>
    </>
  )

  return (
    <Box className="sensor-board">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="top-banner"
      >
        <Typography variant="h5" component="span" className="func-title">
          MOT數據監測
        </Typography>
        <Button
          variant="outlined"
          startIcon={<TuneIcon />}
          onClick={() => setOpenFilter(prevState => !prevState)}
          className="btn-toggler"
        >
          選擇感測器
        </Button>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} className="divider" />
      <Box className="sensor-controller">
        {sensorBoard.devices.length > 0 ? (
          sensorBoard.loading ? (
            <CircularProgress sx={{ alignSelf: "center", mt: 8 }} />
          ) : (
            <>
              {Object.keys(deviceDatas).length > 0
                ? devicePanel()
                : message("該時間範圍內沒有資料", "red")}
            </>
          )
        ) : (
          message("選擇你要監測的感測器", "black")
        )}
      </Box>
    </Box>
  )
}
